<template>
  <div :class="classes">
    <router-link :to="homePath" class="back-button typeface-nav"
      ><Icon icon="home-alt" /> Back to menu</router-link
    >
    <div v-if="station.name" class="station-name">
      {{ station.name }} Station
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "ProgressNav",
  props: ["station"],
  data() {
    return {};
  },
  computed: {
    classes() {
      const classes = ["progress-nav"];
      if (!this.$route.meta.showNav) {
        classes.push("off-screen");
      }
      return classes;
    },
    homePath() {
      let returnPath = "/progress";
      if (
        localStorage.returnpath &&
        this.$route.name !== "New Upgraded Stations"
      ) {
        returnPath = localStorage.returnpath;
      }
      return returnPath;
    }
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.progress-nav {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
  z-index: 10;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: $nav-height;

  a {
    color: $white;
    font-size: 32px;

    display: flex;
    align-content: center;
    img {
      margin-right: 1rem;
      width: 24px;
      vertical-align: middle;
    }
  }

  .back-button {
    position: relative;
    height: $nav-height;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    z-index: 2;
    background: linear-gradient(90deg, $orange 50%, rgba($orange, 0) 51%);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      transform: skew(-15deg);
      z-index: -1;
      background: $orange;
    }

    .icon {
      fill: $white;
      height: 24px;
      width: 24px;
      margin-top: -6px;
      margin-right: 12px;
    }
  }

  &.off-screen {
    bottom: -#{$nav-height};
  }

  .typeface-nav {
    @include typeface-header;
    color: $white;
    text-align: center;
    line-height: 1;
    text-transform: uppercase;
  }

  .station-name {
    @include typeface-header;
    font-size: 48px;
    color: $white;
    margin-left: 48px;
  }
}
</style>
