<template>
  <div>
    <transition mode="out-in" appear>
      <div class="sidebar">
        <RenderRichText
          class="introduction"
          :content="pageData.sidebar.introHTML"
        />

        <div
          class="tabs"
          @mousedown="onDragStart"
          @touchstart="onDragStart"
          @mousemove="onDrag"
          @touchmove="onDrag"
          @mouseleave="onDragEnd"
          @mouseup="onDragEnd"
          @touchcancel="onDragEnd"
        >
          <div
            v-for="(tab, i) in pageData.sidebar.tabs"
            :class="['tab', { active: activeTab === i }]"
            :key="'tab-' + i"
            v-on:click="activeTab = i"
          >
            {{ tab.title }}
          </div>
        </div>

        <div class="sidebar-content" v-on:scroll="onContentScroll">
          <RenderRichText
            :key="this.activeTab"
            :content="this.activeTabContent"
          />
        </div>
      </div>
    </transition>

    <div class="content-container">
      <div class="background-container">
        <BgImage :image="pageData.heroImage" :width="1340" />
        <div class="background-container gradient">
          <div class="the-content">
            <h2>{{ pageData.title }}</h2>
            <RenderTextArea class="intro" :text="pageData.overview" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BgImage from "@/components/BgImage";
import RenderRichText from "@/components/RichText/RenderRichText";
import RenderTextArea from "@/components/RenderTextArea";

export default {
  data() {
    return {
      activeTab: 0,
      dragData: {
        dragging: false,
        dragged: false,
        el: null,
        xstart: 0,
        xoffsetstart: 0,
        paneWidth: 0
      }
    };
  },
  methods: {
    onContentScroll: function(e) {
      var sidebar = document.querySelector(".sidebar");
      if (e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight) {
        sidebar.classList.remove("at-bottom");
      } else {
        sidebar.classList.add("at-bottom");
      }
    },
    scrollContent: function() {
      var el = document.querySelector(".sidebar-content");
      if (el.scrollTop < el.scrollHeight - el.clientHeight) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
      } else {
        el.scrollTop = 0;
      }
    },

    // DRAG
    onDragStart: function(event) {
      let paneWidth = isNaN(parseInt(event.currentTarget.style.width))
        ? 0
        : parseInt(event.currentTarget.style.width);
      let pageX = event.touches ? event.touches[0].pageX : event.pageX;
      if (paneWidth < window.innerWidth) {
        return;
      }
      this.dragData.dragging = true;
      this.dragData.dragged = false;
      this.dragData.el = event.currentTarget;
      this.dragData.xstart = pageX;
      this.dragData.xoffsetstart = isNaN(
        parseInt(event.currentTarget.style.left)
      )
        ? 0
        : parseInt(event.currentTarget.style.left);
      this.dragData.paneWidth = paneWidth;
    },
    onDrag: function(event) {
      if (this.dragData.dragging) {
        let pageX = event.touches ? event.touches[0].pageX : event.pageX;
        let newLeft = Math.min(
          0,
          this.dragData.xoffsetstart + pageX - this.dragData.xstart
        );
        newLeft = Math.max(
          newLeft,
          window.innerWidth - this.dragData.paneWidth
        );
        if (Math.abs(pageX - this.dragData.xstart) > 10) {
          this.dragData.dragged = true;
        }
        this.dragData.el.style.left = newLeft + "px";
      }
    },
    onDragEnd: function() {
      this.dragData.dragging = false;
    }
  },
  components: {
    BgImage,
    RenderRichText,
    RenderTextArea
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("stations-architecture");
    },
    activeTabContent() {
      return this.pageData.sidebar.tabs[this.activeTab].contentHTML;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
@import "~scss/includes/tunnel";

::v-deep .sidebar {
  h2 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: $blue;
  }

  h3 {
    font-size: 24px;
    line-height: (32 / 24);
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  img {
    margin-top: 1em;
  }

  .bold {
    font-weight: bold;
  }

  .introduction {
    background: #fff;
    margin: -60px 0 0 0;
    padding: 60px 0 0;
    h2 {
      background: #fff;
      font-size: 20px;
      margin-bottom: 0;
      color: $grey;
    }

    .interaction-tip {
      position: static;
    }
  }

  .sidebar-content {
    height: calc(100% - 80px);
    overflow-x: visible;
    scroll-behavior: smooth;
    &,
    * {
      touch-action: manipulation;
    }
    padding-top: 40px;
    padding-bottom: 40px;
    p {
      color: $grey;
      margin-bottom: 0.5em;
    }
    ul {
      margin: 1rem 0 1rem 2rem;
      li {
        font-size: 18px;
        list-style: disc;
        color: $grey;
        margin-top: 1em;
        line-height: 1.5;
      }
    }
  }

  .arrow-container {
    padding-top: 30px;
  }

  .tabs {
    background: #fff;
    display: flex;
    z-index: 3;
    margin: 0;
    padding: 40px 40px 0 40px;
    overflow: auto;
    scroll-behavior: smooth;
    &,
    * {
      touch-action: manipulation;
    }
    .tab {
      display: inline-block;
      background: #fff;
      font-size: 20px;
      line-height: 49px;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      padding: 0 24px;
      transition: 0.3s;
      text-wrap: nowrap;
      &.active {
        background: $offwhite;
        color: $blue;
      }
    }
  }
}

.content-container {
  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - #{$tunnel-sidebarwidth});
    bottom: 0;
    height: 100vh;

    ::v-deep img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .gradient {
    background: linear-gradient(to right, $black -15%, transparent 60%);
  }

  .the-content {
    position: relative;
    h2 {
      color: $white;
    }
  }

  ::v-deep p {
    color: $white;
  }

  .the-content .intro {
    border-color: $white;
  }
}
</style>
