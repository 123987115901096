import Index from "@/pages/benefits/Index";
import Home from "@/pages/benefits/Home";
import Select from "@/pages/benefits/Select";
import Slides from "@/pages/benefits/Slides";
import store from "@/store";

export default {
  path: "/benefits",
  component: Index,
  meta: {
    label: "Future Benefits",
    group: "Benefits"
  },
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "benefits").then(next);
  },
  children: [
    {
      path: "",
      name: "Benefits Home",
      component: Home,
      meta: {
        label: "Home",
        hide: true
      }
    },
    {
      path: "select",
      name: "Benefits Select",
      component: Select,
      meta: {
        child: true
      }
    },
    {
      path: "slides",
      name: "Benefits Slides",
      component: Slides,
      meta: {
        child: true
      }
    }
  ]
};
