var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"brisbane"},[_c('div',{staticClass:"bg-image"},[_c('img',{attrs:{"src":_vm.mapimg}})]),_c('div',{staticClass:"content"},[_c('h1',[_vm._v("Brisbane")]),_vm._m(0),_vm._m(1),_c('div',{staticClass:"back-button"},[_c('router-link',{staticClass:"btn btn-orange small-icon",attrs:{"to":"/progress"}},[_c('Icon',{staticClass:"icon",attrs:{"icon":"close"}}),_vm._v(" Back to Menu ")],1)],1)]),_c('img',{staticClass:"rail-line",attrs:{"src":_vm.raillineimg}}),_c('div',{staticClass:"stations"},_vm._l((_vm.combinedBrisbaneStations),function(station){return _c('div',{key:station.name,class:[
          'station',
          { above: station.type === 'above' },
          { 'label-right': station.label === 'right' }
        ],style:(`left: ${station.map.x}px; top: ${station.map.y}px;`)},[(!station.unlinked)?_c('router-link',{staticClass:"station-name",attrs:{"to":`/progress/station/${station.slug}`}},[_vm._v(_vm._s(station.name))]):_c('div',{staticClass:"station-name-unlinked"},[_vm._v(_vm._s(station.name))]),(station.heroImage.url)?_c('div',{staticClass:"station-image"},[_c('CMSImage',{attrs:{"image":station.heroImage,"width":63,"quality":95}})],1):_c('div',{staticClass:"station-dot"})],1)}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("4 NEW underground stations"),_c('br'),_vm._v("and 8 station REBUILDS")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[_c('div',{staticClass:"key"},[_c('div',{staticClass:"dot green"}),_vm._v(" Underground ")]),_c('div',{staticClass:"key"},[_c('div',{staticClass:"dot orange"}),_vm._v(" Above Ground ")])])
}]

export { render, staticRenderFns }