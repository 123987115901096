<template>
  <Three60Environment
    :panos="pageData.future.panoramas"
    :heading="pageData.future.heading"
    :copy="pageData.future.overview"
  />
</template>

<script>
import Three60Environment from "@/components/Three60Environment";

const slug = "explore-albert-street";

export default {
  computed: {
    pageData() {
      return this.$store.getters.pageData(slug);
    }
  },
  components: {
    Three60Environment
  }
};
</script>

<style lang="scss" scoped></style>
