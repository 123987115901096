<template>
  <div>
    <BgImage :image="pageData.introduction.heroImage" />
    <transition>
      <router-view :key="$route.fullPath" class="router-view"></router-view>
    </transition>
    <Sidebar>
      <template v-slot:title>
        <transition>
          <h1 v-if="!onChildRoute">
            <span class="station-name h1"
              ><span class="text">Boggo Road</span></span
            >
            <span class="station h1"><span class="text">Station</span></span>
          </h1>
        </transition>
      </template>
      <template v-slot:content>
        <transition>
          <StationExploreHomeIntroduction
            v-if="!onChildRoute"
            :introduction="pageData.introduction"
          />
        </transition>
      </template>
    </Sidebar>
    <ScreenNavigation base="/boggo" :routes="localRoutes" />
    <ScreenTitleTab label="Boggo Road" />
    <StationHomeLink />
  </div>
</template>

<script>
import StationHomeLink from "@/components/StationHomeLink";
import ScreenTitleTab from "@/components/ScreenTitleTab";
import ScreenNavigation from "@/components/ScreenNavigation";
import Sidebar from "@/components/Sidebar";
import StationExploreHomeIntroduction from "@/components/stationExplore/HomeIntroduction";
import BgImage from "@/components/BgImage";

import mixins from "@/mixins";

const slug = "explore-boggo-road";

export default {
  data() {
    return {};
  },
  components: {
    StationHomeLink,
    ScreenTitleTab,
    ScreenNavigation,
    Sidebar,
    StationExploreHomeIntroduction,
    BgImage
  },
  computed: {
    localRoutes: function() {
      return this.childRoutes.filter(function(route) {
        return route.meta.hide === undefined || route.meta.hide !== true;
      });
    },
    pageData() {
      return this.$store.getters.pageData(slug);
    }
  },
  mounted() {
    this.$root.setRootPath("/boggo");
  },
  mixins: [mixins]
};
</script>

<style lang="scss" scoped>
h1,
.h1 {
  font-size: 110px;
}
</style>
