<template>
  <div class="content-card">
    <slot></slot>
  </div>
</template>

<script>
export default { name: "Three60InfoCard" };
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.content-card {
  position: static;
  max-width: 500px;
  height: auto;
  background: white;
  text-align: center;
  border-bottom: 6px solid $green;
  padding: 2rem;
  transform: translateX(10000px);
}
::v-deep h3 {
  color: $grey;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0.5em;
}
::v-deep p {
  font-size: 20px;
  line-height: 1.3;
  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
}
</style>
