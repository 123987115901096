<template>
  <div class="bgcolor">
    <div :class="['three60environment', { transitioning, loading: loaded }]">
      <canvas ref="renderCanvas" class="renderCanvas"></canvas>

      <transition mode="out-in">
        <ElevationTab v-if="elevation && exploring" v-bind="elevation" />
      </transition>

      <transition>
        <div v-if="exploring" class="interaction-prompt">
          <Icon icon="interactive" /><span>Drag to look around</span>
        </div>
      </transition>

      <InfoCard v-for="hotspot in allHotspots" :key="hotspot.id" ref="cards">
        <!-- RX Updated this part to fit the new data structure -->
        <h3>{{ hotspot.title }}</h3>
        <p>{{ hotspot.copy }}</p>
        <!-- <component
          :is="content.tag || 'p'"
          v-for="(content, i) in hotspot.content"
          :key="i"
          >{{ content.text }}</component
        > -->
      </InfoCard>

      <transition>
        <div
          v-if="!exploring && loaded && !transitioning"
          class="intro-overlay"
        >
          <div class="intro-card">
            <h3>{{ heading }}</h3>
            <!-- RX Updated this part -->
            <RenderTextArea :text="copy" />
            <Button @clicked="exploring = true" label="Start exploring" />
          </div>
        </div>
      </transition>

      <transition>
        <div
          v-if="exploring && hasMultiple"
          class="change-scene prev"
          @click.prevent="prev"
        >
          <span>Prev</span>
        </div>
      </transition>
      <transition>
        <div
          v-if="exploring && hasMultiple"
          class="change-scene next"
          @click.prevent="next"
        >
          <span>Next</span>
        </div>
      </transition>

      <div v-if="debugCameraRotation" class="debug-camera-rotation">
        x: {{ debugCameraRotation.x }}, y: {{ debugCameraRotation.y }}, z:
        {{ debugCameraRotation.z }}
      </div>
      <div v-if="debugCameraRotation" class="debug-crosshairs"></div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";

import {
  Animation,
  TransformNode,
  Engine,
  Scene,
  Vector3,
  EasingFunction,
  SineEase,
  Observable,
  FreeCamera
} from "babylonjs";

import {
  AdvancedDynamicTexture,
  Control,
  Image,
  Rectangle,
  Line
} from "babylonjs-gui";

import InfoCard from "@/components/Three60InfoCard";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import ElevationTab from "@/components/ElevationTab";

import { addVideoDome } from "@/assets/babylon/utils";
import {
  PanoType,
  closePlaneSize,
  hotspotConnectorWidth,
  hotspotConnectorHeight,
  hotspotSize,
  domeEdge,
  green
} from "@/assets/babylon/settings";

import ImgHotspot from "@/assets/images/hotspot.png";
import ImgClose from "@/assets/images/close.png";
import RenderTextArea from "@/components/RenderTextArea";
import { Elevation } from "@/assets/babylon/settings";

const mod = (x, n) => ((x % n) + n) % n;
const step = (n, dir, max) => mod(n + dir, max);

// Animation easings
const EASE_OUT_SINE = new SineEase();
EASE_OUT_SINE.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
const EASE_IN_OUT_SINE = new SineEase();
EASE_IN_OUT_SINE.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

// Debug camera rotation precision
const PRECISION = 3;

// Hotspot class
class Hotspot {
  constructor(data, card, { scene, gui }) {
    this.scene = scene;
    this.gui = gui;
    this.data = data;
    this.isOpen = false;

    const gid = s => data.id + "_" + s;
    this.id = gid("hotspot");

    // Hotspot point
    this.node = new TransformNode(gid("node"), scene);
    this.node.parent = this.pivot;
    this.node.position.copyFrom(domeEdge);

    // Rotate the hotspot point
    const { x, y } = data.orbit;
    this.node.setPivotPoint(domeEdge.negate());
    this.node.rotation.x = x;
    this.node.rotation.y = y;

    // Create hotspot dot
    this.dot = new Image(gid("dot"), ImgHotspot);
    this.dot.widthInPixels = hotspotSize;
    this.dot.heightInPixels = hotspotSize;
    this.dot.isPointerBlocker = true;
    this.dot.hoverCursor = "pointer";

    // Create callout card
    this.callout = new Rectangle();
    const wTotal = card.width + closePlaneSize;
    const hTotal = card.height + hotspotConnectorHeight;
    this.callout.widthInPixels = wTotal;
    this.callout.heightInPixels = hTotal;
    this.callout.thickness = 0;
    this.callout.alpha = 0;
    this.callout.isVisible = false;
    this.callout.notRenderable = true;
    this.callout.clipChildren = false;

    // Draw close button
    this.closeBtn = new Image(gid("callout-close"), ImgClose);
    this.closeBtn.widthInPixels = closePlaneSize;
    this.closeBtn.heightInPixels = closePlaneSize;
    this.closeBtn.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.closeBtn.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.closeBtn.hoverCursor = "pointer";
    this.closeBtn.isPointerBlocker = true;

    // Draw HTML image
    const cImageCard = new Image(gid("callout-card"), card.image);
    cImageCard.widthInPixels = card.width;
    cImageCard.heightInPixels = card.height;
    cImageCard.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    cImageCard.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    // Draw connector
    const cConnector = new Line(gid("callout-line"));
    cConnector.x1 = card.width / 2;
    cConnector.y1 = hTotal - hotspotConnectorHeight;
    cConnector.x2 = cConnector.x1;
    cConnector.y2 = hTotal;
    cConnector.lineWidth = hotspotConnectorWidth;
    cConnector.color = green.toHexString();
    cConnector.isPointerBlocker = false;

    // Add components to callout
    this.callout.addControl(cConnector);
    this.callout.addControl(cImageCard);
    this.callout.addControl(this.closeBtn);

    // Add custom observable and callbacks
    this.onOpenObservable = new Observable();
    this.onCloseObservable = new Observable();
    this.dot.onPointerClickObservable.add(this.click.bind(this));
    this.closeBtn.onPointerClickObservable.add(this.click.bind(this));

    // Animate the hotspot image
    this.dot.animations = Hotspot.ANIM_PULSE;
    scene.beginAnimation(this.dot, 0, Hotspot.ANIM_SPD, true);

    // Add hotspot to GUI and link to TransformNode
    gui.addControl(this.callout);
    gui.addControl(this.dot);
    this.link();
  }

  click() {
    if (this.isOpen) this.close();
    else this.open();
  }

  open() {
    if (!this.isOpen) {
      this.isOpen = true;
      this.onOpenObservable.notifyObservers(this.data);
      // Fade in and bring to front
      Hotspot.fade(this.scene, this.callout, 1);
      this.setLayer(1);
    }
  }

  close(reset = true) {
    if (this.isOpen) {
      this.isOpen = false;
      if (reset) this.onCloseObservable.notifyObservers();
      // Fade out
      Hotspot.fade(this.scene, this.callout, 0);
    }
  }

  setLayer(zIndex) {
    this.callout.zIndex = zIndex * 2;
    this.dot.zIndex = zIndex * 2 + 1;
    this.link();
  }

  link() {
    this.callout.linkWithMesh(this.node);
    this.callout.linkOffsetXInPixels = closePlaneSize / 2;
    this.callout.linkOffsetYInPixels = -this.callout.heightInPixels / 2;
    this.dot.linkWithMesh(this.node);
  }

  dispose() {
    this.gui.removeControl(this.dot);
    this.gui.removeControl(this.callout);
    this.node.dispose();
  }

  // Animation statics
  static fade(scene, target, to = 1, speed = 10) {
    // Create animation
    const fps = 30;
    const from = 1 - to;
    const type = Animation.ANIMATIONTYPE_FLOAT;
    const mode = Animation.ANIMATIONLOOPMODE_CONSTANT;
    const anim = new Animation("fade", "alpha", fps, type, mode);
    anim.setEasingFunction(EASE_IN_OUT_SINE);
    anim.setKeys([
      { frame: 0, value: from },
      { frame: speed, value: to }
    ]);

    // Run animation
    target.animations = [anim];
    if (to) target.isVisible = true;
    return scene.beginAnimation(target, 0, speed, false, 1, () => {
      if (!to) target.isVisible = false;
    });
  }

  static get ANIM_SPD() {
    return 60;
  }

  static get ANIM_PULSE() {
    // Animation params
    const frames = Hotspot.ANIM_SPD;
    const scaleMin = 1;
    const scaleMax = 1.2;
    const type = Animation.ANIMATIONTYPE_FLOAT;
    const loop = Animation.ANIMATIONLOOPMODE_CYCLE;

    // Create animations
    const sx = new Animation("hotspot_sx", "scaleX", 30, type, loop);
    const sy = new Animation("hotspot_sy", "scaleY", 30, type, loop);
    const keys = [
      { frame: 0, value: scaleMin },
      { frame: frames / 2, value: scaleMax },
      { frame: frames, value: scaleMin }
    ];

    // Set keys and easing
    sx.setEasingFunction(EASE_IN_OUT_SINE);
    sy.setEasingFunction(EASE_IN_OUT_SINE);
    sx.setKeys(keys);
    sy.setKeys(keys);

    // Return anims
    return [sx, sy];
  }
}

export default {
  name: "three-60-environment",
  props: ["panos", "heading", "copy"],
  data() {
    return {
      scene: null,
      gui: null,
      activePanoIndex: 0,
      canvas: null,
      engine: null,
      camera: null,
      exploring: false,
      meshes: [],
      hotspots: [],
      transitioning: false,
      loaded: false,
      transitionTimeout: null,
      debugCameraRotation: false
    };
  },
  computed: {
    allHotspots() {
      return this.panos.reduce((acc, cur) => [...acc, ...cur.hotspots], []);
    },
    numPanos() {
      return this.panos.length;
    },
    nextPano() {
      return step(this.activePanoIndex, 1, this.numPanos);
    },
    prevPano() {
      return step(this.activePanoIndex, -1, this.numPanos);
    },
    hasMultiple() {
      return this.numPanos > 1;
    },
    elevation() {
      const level = this.panos[this.activePanoIndex].elevation;
      const elevation = Elevation[level];
      return elevation ? { ...elevation, key: level } : null;
    }
  },
  methods: {
    resetCamera(rotation = { x: 0, y: 0 }) {
      this.camera.rotation.x = rotation.x;
      this.camera.rotation.y = rotation.y;
    },

    rotateCameraTo(rotation, speed = 15) {
      const fps = 30;
      const type = Animation.ANIMATIONTYPE_VECTOR3;
      const target = new Vector3(rotation.x, rotation.y, 0);

      // Pre-rotate camera if needed
      for (const axis of ["x", "y"]) {
        const dr = target[axis] - this.camera.rotation[axis];
        if (Math.abs(dr) > Math.PI) {
          this.camera.rotation[axis] += Math.PI * 2 * Math.sign(dr);
        }
      }

      // Create animation
      const anim = new Animation("rotate_camera", "rotation", fps, type);
      anim.setEasingFunction(EASE_OUT_SINE);
      anim.setKeys([
        { frame: 0, value: this.camera.rotation },
        { frame: speed, value: target }
      ]);

      // Run animation
      this.camera.animations = [anim];
      return this.scene.beginAnimation(this.camera, 0, speed, false);
    },

    createEnvironment(to) {
      // Create dome
      const { asset } = this.panos[to];
      const url = asset.url;
      const type = asset.mimeType.startsWith("video/")
        ? PanoType.VIDEO
        : PanoType.IMAGE;
      const dome = addVideoDome(type, url, this.scene);
      this.meshes = [dome];

      // Create hotspots
      this.createHotspots(to);
    },

    async createHotspot(data, cardEl) {
      // Convert card HTML to image, width and height
      const { width, height } = cardEl.getBoundingClientRect();
      const render = await html2canvas(cardEl, { backgroundColor: null });
      const image = render.toDataURL();
      const card = { width, height, image };

      // Return new hotspot
      return new Hotspot(data, card, this);
    },

    async createHotspots(to) {
      // Check that hotspot cards exist
      if (this.$refs.cards) {
        // Create hotspot instances
        const createAll = this.panos[to].hotspots.map(data => {
          const index = this.allHotspots.findIndex(n => n.id === data.id);
          const card = this.$refs.cards[index].$el;
          return this.createHotspot(data, card);
        });

        this.hotspots = await Promise.all(createAll);

        // Set up hotspot events
        this.hotspots.forEach(h => {
          // Handle hotspot open
          h.onOpenObservable.add(() => {
            // Close and move all other hotspots to back
            this.hotspots.forEach(other => {
              if (other.id !== h.id) {
                other.close(false);
                other.setLayer(0);
              }
            });

            // Pan camera to hotspot
            this.rotateCameraTo(h.data.orbit);
          });
        });
      } else {
        // Else create no hotspots
        this.hotspots = [];
      }
    },

    onResize() {
      // Resize canvas on window resize
      if (this.engine) this.engine.resize();
    },

    // Navigation controls
    next() {
      return this.switchEnvironment(this.nextPano);
    },
    prev() {
      return this.switchEnvironment(this.prevPano);
    },

    wait(ms) {
      return new Promise(resolve => {
        this.transitionTimeout = window.setTimeout(resolve, ms);
      });
    },

    async switchEnvironment(to) {
      if (to >= 0 && to < this.numPanos && !this.transitioning) {
        // Mark transition start
        this.transitioning = true;

        // Debounce navigation
        await this.wait(300);

        // Dispose current env, then create new one
        this.disposeCurrentEnvironment();
        await this.createEnvironment(to);
        this.resetCamera(this.panos[to].defaultCameraRotation);
        this.activePanoIndex = to;
        this.transitioning = false;
      }
    },
    disposeCurrentEnvironment() {
      for (const i of [...this.meshes, ...this.hotspots]) {
        i.dispose();
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      // Set up canvas
      this.canvas = this.$refs.renderCanvas;
      const canvas = this.canvas;

      // St up engine
      this.engine = new Engine(canvas, true, {
        preserveDrawingBuffer: true,
        stencil: true
      });
      const engine = this.engine;

      // Set up scene
      this.scene = new Scene(engine);
      this.scene.clearColor = new BABYLON.Color3(0, 0, 0);
      const scene = this.scene;

      // Set up GUI
      this.gui = new AdvancedDynamicTexture.CreateFullscreenUI("hotspots_ui");

      // Set up camera
      this.camera = new FreeCamera("Camera", Vector3.Zero(), scene);
      this.camera.attachControl(canvas, true);
      this.camera.fov = 1.1;

      // Modify camera inputs
      this.camera.inputs.attached.keyboard.detachControl();
      this.camera.inputs.attached.mouse.angularSensibility = -5000;
      this.resetCamera();

      // Create materials
      await this.switchEnvironment(0);
      this.loaded = true;

      // Start render loop
      engine.runRenderLoop(() => {
        if (scene) {
          // Show debug info in dev mode
          if (process.env.NODE_ENV !== "production") {
            this.debugCameraRotation = {
              x: this.camera.rotation.x.toFixed(PRECISION),
              y: mod(this.camera.rotation.y, 2 * Math.PI).toFixed(PRECISION),
              z: this.camera.rotation.z.toFixed(PRECISION)
            };
          }

          // Render scene
          scene.render();
        }
      });
    });
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    // Cleanup
    this.scene.dispose();
    this.engine.dispose();
    window.clearTimeout(this.transitionTimeout);
    window.removeEventListener("resize", this.onResize);
  },
  components: {
    InfoCard,
    Icon,
    Button,
    ElevationTab,
    RenderTextArea
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
.bgcolor {
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.debug-camera-rotation {
  position: fixed;
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px black;
  right: 10px;
  bottom: 10px;
  font-variant-numeric: tabular-nums;
}
.debug-camera-rotation::selection {
  background: rgba(255, 255, 255, 0.2);
}
.three60environment {
  transition: all 0.3s;
  opacity: 0;
  &:not(.transitioning),
  &:not(.loading) {
    opacity: 1;
    transition-delay: 0.5s;
  }
}
.renderCanvas {
  width: 100%;
  height: 100%;
  touch-action: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.interaction-prompt {
  position: fixed;
  top: 4rem;
  right: 4rem;
  background-color: transparentize($charcoal, 0.35);
  border-radius: 7px;
  color: white;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 900;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem 0 1rem;
  svg {
    margin-right: 0.25em;
    font-size: 1.5em;
    fill: white;
    display: inline-block;
  }
}
.intro-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-card {
  box-shadow: 0 2px 1.5rem rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 20px solid $green;
  border-radius: 10px 10px 0 0;
  padding: 3.5rem 2.5rem 2rem;
  width: 960px;
  text-align: center;
  h3 {
    font-weight: bold;
    color: $charcoal;
    font-size: 2.25rem;
  }
  ::v-deep p {
    line-height: 1.35;
    margin: 1em 0;
  }
}

.debug-crosshairs {
  pointer-events: none;
  &::before,
  &::after {
    position: fixed;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);
    content: "";
  }
  &::before {
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
  }
  &::after {
    top: 50%;
    width: 100%;
    left: 0;
    right: 0;
    height: 1px;
  }
}

.change-scene {
  position: fixed;
  bottom: 3.5rem;
  height: 70px;
  width: 150px;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-image: url("../assets/images/change-scene.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  span {
    position: absolute;
    color: white;
    font-weight: 700;
    top: calc(100% + 0.5em);
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
  }
  $offset: 80px;
  &.prev {
    left: calc(50% - #{$offset});
    transform: translateX(-50%);
    &::before {
      transform: rotate(180deg);
    }
  }
  &.next {
    left: calc(50% + #{$offset});
    transform: translateX(-50%);
  }
}

.intro-overlay,
.control-indicator-arrow,
.interaction-prompt,
.change-scene {
  transition: opacity 1s;
}
.v-enter,
.v-leave-active,
.v-leave-to {
  opacity: 0;
}
.v-enter-active,
.v-enter-to,
.v-leave {
  opacity: 1;
}
</style>
