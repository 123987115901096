import Index from "@/pages/tunnel/Index";

import Home from "@/pages/tunnel/Home";
import TheProject from "@/pages/tunnel/TheProject";
import EarlyWorks from "@/pages/tunnel/EarlyWorks";
import Construction from "@/pages/tunnel/Construction";
import store from "@/store";

export default {
  path: "/tunnel",
  component: Index,
  meta: {
    label: "Tunnel",
    group: "Progress"
  },
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "tunnel-home").then(next);
  },
  children: [
    {
      path: "",
      name: "Tunnel Home",
      component: Home,
      meta: {
        label: "Home",
        showSidebar: true
      }
    },
    {
      path: "alignment",
      name: "Alignment",
      component: TheProject,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "tunnel-alignment").then(next);
      }
    },
    {
      path: "geotech",
      name: "Geotech",
      component: EarlyWorks,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "tunnel-geotech").then(next);
      }
    },
    {
      path: "tbm",
      name: "TBM",
      component: Construction,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "tunnel-tbm").then(next);
      }
    }
  ]
};
