<template>
  <div class="the-content">
    <h2>{{ introduction.subtitle }}</h2>
    <p>
      {{ introduction.overview }}
    </p>
    <span class="interaction-tip"
      ><Icon icon="interactive" /><span class="label"
        >Select an option below to explore</span
      ></span
    >
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "StationExploreHomeIntroduction",
  props: ["introduction"],
  components: { Icon }
};
</script>

<style lang="scss" scoped>
.the-content {
  h2 {
    font-size: 46px;
    margin-bottom: 18px;
  }
  p {
    font-size: 20px;
    line-height: 1.4;
  }
  .interaction-tip {
    bottom: 230px;
  }
}
</style>
