<template>
  <div>
    <transition mode="out-in" appear>
      <div class="sidebar">
        <RenderRichText
          class="introduction"
          :content="pageData.sidebar.introHTML"
        />
        <div class="tabs">
          <div
            v-for="(tab, i) in pageData.sidebar.tabs"
            :class="['tab', { active: activeTab === i }]"
            :key="'tab-' + i"
            v-on:click="activeTab = i"
          >
            {{ tab.title }}
          </div>
        </div>
        <div class="sidebar-content" v-on:scroll="onContentScroll">
          <RenderRichText
            :key="this.activeTab"
            :content="this.activeTabContent"
          />
        </div>
      </div>
    </transition>

    <div class="content-container">
      <div
        class="the-content mode-explore"
        :style="`background-image:url(${borerimg});`"
      >
        <h2>{{ pageData.title }}</h2>
        <div class="intro">
          <RenderTextArea :text="pageData.overview" />
        </div>

        <div
          v-for="(hotspot, i) in pageData.hotspots"
          :class="['location-marker', 'new', { active: activeHotspot === i }]"
          :style="`top:${hotspot.top}%; left:${hotspot.left}%;`"
          :key="'hotspot-' + i"
          v-on:click="onHotspot(i)"
        ></div>

        <transition mode="out-in">
          <div
            class="hotspot-content"
            v-if="activeHotspot !== null"
            :style="`top:${hotspot.top}%; left:${hotspot.left}%;`"
            :key="'hotspot-content-' + activeHotspot"
          >
            <h3>{{ hotspot.title }}</h3>
            <p>{{ hotspot.content }}</p>
            <span @click="resetHotspot" class="close"
              ><Icon icon="close"
            /></span>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import RenderRichText from "@/components/RichText/RenderRichText";
import RenderTextArea from "@/components/RenderTextArea";
import borerimg from "@/assets/images/tunnel/excavation.jpg";
import roadheadersimg from "@/assets/images/tunnel/roadheaders.jpg";

export default {
  data() {
    return {
      roadheadersimg,
      borerimg,
      hotspot: null,
      activeHotspot: null,
      activeTab: 0
    };
  },
  methods: {
    onHotspot(i) {
      if (this.activeHotspot === i) {
        this.activeHotspot = null;
        this.hotspot = null;
      } else {
        this.activeHotspot = i;
        this.hotspot = this.hotspots[i];
      }
    },
    resetHotspot() {
      this.activeHotspot = null;
      this.hotspot = null;
    },
    onContentScroll(e) {
      var sidebar = document.querySelector(".sidebar");
      if (e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight) {
        sidebar.classList.remove("at-bottom");
      } else {
        sidebar.classList.add("at-bottom");
      }
    },
    scrollContent() {
      var el = document.querySelector(".sidebar-content");
      if (el.scrollTop < el.scrollHeight - el.clientHeight) {
        el.scrollTop = el.scrollHeight - el.clientHeight;
      } else {
        el.scrollTop = 0;
      }
    }
  },
  components: {
    Icon,
    RenderRichText,
    RenderTextArea
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("tunnel-tbm");
    },
    hotspots() {
      return this.pageData.hotspots;
    },
    activeTabContent() {
      return this.pageData.sidebar.tabs[this.activeTab].contentHTML;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
@import "~scss/includes/tunnel";

::v-deep .sidebar {
  h2 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: $blue;
  }

  h3 {
    font-size: 24px;
    line-height: (32 / 24);
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 18px;
    line-height: 1.5;
  }

  img {
    margin-top: 1em;
  }

  .introduction {
    background: #fff;
    margin: -60px -40px 0 -40px;
    padding: 60px 40px 0;
    h2 {
      margin-bottom: 0;
    }

    .interaction-tip {
      position: static;
    }
  }

  .sidebar-content {
    height: calc(100% - 80px);
    overflow: auto;
    scroll-behavior: smooth;
    &,
    * {
      touch-action: manipulation;
    }
    padding-top: 40px;
    padding-bottom: 40px;
    p {
      color: $grey;
      margin-bottom: 0.5em;
    }
    ul {
      margin: 1rem 0 1rem 2rem;
      li {
        font-size: 18px;
        list-style: disc;
        color: $grey;
        margin-top: 1em;
        line-height: 1.5;
      }
    }
  }

  .control {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    .icon {
      width: 32px;
      height: 19px;
      fill: $green;
      transition: 0.3s;
    }
  }

  &.at-bottom .control .icon {
    transform: rotate(180deg);
  }

  .tabs {
    background: #fff;
    margin: 0 -40px;
    padding: 40px 0 0 40px;
    .tab {
      display: inline-block;
      background: #fff;
      font-size: 20px;
      line-height: 49px;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      padding: 0 24px;
      transition: 0.3s;
      &.active {
        background: $offwhite;
        color: $blue;
      }
    }
  }
}

.content-container {
  .the-content {
    background: center 140px no-repeat;
    background-size: contain;
    h2 {
      width: 390px;
    }
  }

  .the-content.white .intro {
    border-color: $teal;
  }

  .hotspot-content {
    position: absolute;
    max-width: 300px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 9px solid $orange;
    padding: 20px;

    &::before {
      content: "";
      position: absolute;
      top: -30px;
      left: 0;
      display: block;
      width: 2px;
      height: 30px;
      background: white;
      transition: 0.3s;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
    }

    .close {
      position: absolute;
      right: 0;
      bottom: -2.75rem;
      width: 2.75rem;
      height: 2.75rem;
      background: $orange;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: white;
        font-size: 1.25rem;
      }
    }
  }
}
.hotspot-content {
  opacity: 0;
  transform-origin: left top -30px;
  transform: translate(15px, 20px) scale(0.8);
  filter: blur(3px);
  transition: 0.3s;
  &::before {
    top: 30px;
  }

  @include enter {
    opacity: 1;
    transform: translate(15px, 60px) scale(1);
    filter: blur(0);
    &::before {
      top: -30px;
    }
  }
  @include leave {
    opacity: 0;
    transform: translate(15px, 20px) scale(0.8);
    filter: blur(3px);
    &::before {
      top: 30px;
    }
  }
}
</style>
