<template>
  <div class="taking-shape-wrapper">
    <transition name="fade">
      <router-view class="router-view"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Taking Shape"
    };
  },
  mounted() {
    this.$root.setRootPath("/taking-shape");
  }
};
</script>

<style lang="scss" scoped>
.taking-shape-wrapper {
  background-color: #081d29;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
