<template>
  <div class="carousel">
    <div v-if="showSwipeControls" class="controls">
      <div class="control control-left">Swipe</div>
      <div class="control control-right">Swipe</div>
    </div>

    <div class="carousel-wrapper">
      <div
        class="carousel-pane"
        :style="{ width }"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
        @mousemove="onDrag"
        @touchmove="onDrag"
        @mouseleave="onDragEnd"
        @mouseup="onDragEnd"
        @touchcancel="onDragEnd"
      >
        <div class="station" v-for="station in slides" :key="station.slug">
          <router-link
            :to="`/progress/station/${station.slug}`"
            class="station-tile"
          >
            <div class="station-image">
              <CMSImage :image="station.heroImage" :width="420" />
            </div>
            <div class="station-content">
              <div class="buttons">
                <div v-if="station.timeHop.panorama" class="button">
                  Time Hop
                </div>
                <div v-if="station.view360.panorama" class="button button-360">
                  360&deg;
                </div>
              </div>
              <div class="station-name">
                <b>{{ station.name }}</b> Station
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CMSImage from "@/components/CMSImage";

export default {
  name: "AllStationsCarousel",
  props: ["slides"],
  computed: {
    width() {
      return this.slides.length * 402 + "px";
    },
    showSwipeControls() {
      return this.slides.length > 4;
    }
  },
  data() {
    return {
      dragData: {
        dragging: false,
        dragged: false,
        el: null,
        xstart: 0,
        xoffsetstart: 0,
        paneWidth: 0
      }
    };
  },
  methods: {
    onDragStart: function(event) {
      let paneWidth = isNaN(parseInt(event.currentTarget.style.width))
        ? 0
        : parseInt(event.currentTarget.style.width);
      let pageX = event.touches ? event.touches[0].pageX : event.pageX;
      if (paneWidth < window.innerWidth) {
        return;
      }
      this.dragData.dragging = true;
      this.dragData.dragged = false;
      this.dragData.el = event.currentTarget;
      this.dragData.xstart = pageX;
      this.dragData.xoffsetstart = isNaN(
        parseInt(event.currentTarget.style.left)
      )
        ? 0
        : parseInt(event.currentTarget.style.left);
      this.dragData.paneWidth = paneWidth;
    },
    onDrag: function(event) {
      if (this.dragData.dragging) {
        let pageX = event.touches ? event.touches[0].pageX : event.pageX;
        let newLeft = Math.min(
          0,
          this.dragData.xoffsetstart + pageX - this.dragData.xstart
        );
        newLeft = Math.max(
          newLeft,
          window.innerWidth - this.dragData.paneWidth
        );
        if (Math.abs(pageX - this.dragData.xstart) > 10) {
          this.dragData.dragged = true;
        }
        this.dragData.el.style.left = newLeft + "px";
      }
    },
    onDragEnd: function() {
      this.dragData.dragging = false;
    }
  },
  components: {
    CMSImage
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";
.carousel {
  position: relative;
}

.controls {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  .control {
    position: absolute;
    width: 80px;
    top: 80px;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    padding-top: 100px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 40px;
      width: 60px;
      height: 2px;
      background: $white;
    }
    &::before {
      transform: rotate(-50deg);
    }
    &::after {
      transform: rotate(50deg);
    }
  }
  .control-left {
    left: 0;
    &::before,
    &::after {
      left: 20px;
      transform-origin: 1px center;
    }
  }
  .control-right {
    right: 0;
    &::before,
    &::after {
      right: 20px;
      transform-origin: right center;
    }
  }
}

// Slide-wrapper
.carousel-wrapper {
  height: 236px;
  width: 100%;
  overflow: hidden;

  mask-image: linear-gradient(
    90deg,
    rgba($black, 0) 0%,
    rgba($black, 1) 5%,
    rgba($black, 1) 95%,
    rgba($black, 0) 100%
  );
}
.carousel-pane {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding: 0 110px;
  z-index: 1;
}

.station-tile {
  display: inline-block;
  position: relative;
  width: 420px;
  height: 236px;

  clip-path: polygon(62px 0, 420px 0, 358px 236px, 0 236px);
  margin: 0 -31px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      45deg,
      rgba($black, 0.5) 0%,
      rgba($black, 0) 100%
    );
  }

  .station-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .station-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .station-name {
    position: absolute;
    left: 40px;
    bottom: 20px;
    color: $white;
    font-size: 24px;
    b {
      font-weight: 900;
    }
  }

  .buttons {
    position: relative;
    left: 58px;
    .button {
      display: inline-block;
      position: relative;
      color: $white;
      padding: 0 16px;
      font-size: 14px;
      font-style: italic;
      font-weight: 900;
      text-transform: uppercase;
      line-height: 30px;
      height: 30px;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skew(-15deg);
        background: $green;
        z-index: -1;
      }
      &.button-360::before {
        background: $orange;
      }
    }
  }
}
</style>
