<template>
  <div class="mapbox">
    <div ref="map" :class="['map', 'precinct-' + precinct]"></div>
    <transition>
      <div class="map-ctrls" v-if="interactive && mode !== 'postcode'">
        <div class="move-icon">
          <icon icon="pan" />
        </div>
        <div class="map-ctrl-btns">
          <button
            class="map-ctrl-btn"
            @click="zoomIn"
            :class="{ disabled: atMaxZoom }"
          >
            <icon icon="zoom-in" />
          </button>
          <hr />
          <button
            class="map-ctrl-btn"
            @click="zoomOut"
            :class="{ disabled: atMinZoom }"
          >
            <icon icon="zoom-out" />
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";

import MapPopup from "@/components/MapPopup";
import Icon from "@/components/Icon";

import Loader from "@/lib/LoadData";
import StationData from "@/lib/StationData";

const mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
mapboxgl.accessToken =
  "pk.eyJ1Ijoicm9tZW9kaWdpdGFsIiwiYSI6ImNqNmJoaW92cTFmZ3UzM2xtb2ZyYjd6Y3EifQ.yiHwBAasqKWKW0SCm9559w";

export default {
  props: ["station", "line", "mode", "precinct", "stations"],
  name: "MyFirstMap",
  components: { Icon },
  data() {
    return {
      popup: null,
      popupContent: Vue.extend(MapPopup),
      atCenter: true,
      defaultZoom: 14,
      defaultCenter: [153.032, -27.465],
      map: null,
      flying: false,
      stationMarkers: [],
      interactive: false,
      maxZoom: 16,
      minZoom: 8.8,
      zoomStep: 0.5,
      animation_step: 0.05,
      StationData
    };
  },
  computed: {
    atMaxZoom() {
      return this.map.getZoom() >= this.maxZoom;
    },
    atMinZoom() {
      return this.map.getZoom() <= this.minZoom;
    }
  },
  watch: {
    mode(next, prev) {
      this.setMode(next, prev);
      this.updateLineFilter();
    },
    station(next, prev) {
      if (next && this.mode === "postcode") {
        this.showStations();
      }
      if (next && next !== prev) {
        this.removePopup();
        this.flyToStation(next);
        this.showStationPopup(next);
      }
      this.onStationChange(next);
    },
    line(next) {
      this.updateLineFilter(next ? next + " line" : false);
    }
  },
  methods: {
    resetMap() {
      this.removePopup();
      this.hideStations();
      this.setInteractivity(true);
    },
    setMode(next, prev) {
      this.resetMap();
      switch (next ? next : this.mode) {
        case "intro":
          this.setInteractivity(false);
          this.map.easeTo({
            zoom: this.defaultZoom,
            center: this.defaultCenter
          });
          this.setInteractivity(false);
          break;
        case "explore":
          if (prev === "intro") {
            this.map.easeTo({
              zoom: this.defaultZoom - 0.5,
              center: [this.defaultCenter[0] - 0.01, this.defaultCenter[1]]
            });
          }
          this.showStations();
          break;
      }
    },
    removePopup() {
      if (this.popup) this.popup.remove();
    },
    hideStations() {
      this.map.setLayoutProperty("station-labels", "visibility", "none");
    },
    showStations() {
      this.map.setLayoutProperty("station-labels", "visibility", "visible");
    },
    flyToStation(stationName) {
      let { lat, lng } = this.StationData.getStation(stationName);
      let options = {
        center: [lng, lat + 0.004],
        zoom: 15
      };
      this.map.flyTo(options);
    },
    showStationPopup(stationName) {
      let { lat, lng } = this.StationData.getStation(stationName);
      this.popup = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: false,
        anchor: "top",
        offset: 30
      })
        .setMaxWidth("0px")
        .setLngLat([lng, lat])
        .setHTML('<div id="popup-content"></div>')
        .addTo(this.map);

      const stationLines = this.StationData.getStationLines(stationName);
      if (stationLines.length === 0) {
        this.$emit("line", "all");
      }
      if (stationLines.length === 1) {
        this.$emit("line", stationLines[0].line);
      }
      if (stationLines.length > 1) {
        new this.popupContent({
          propsData: {
            popupStyle: "station",
            lines: stationLines
          }
        }).$mount("#popup-content");
      }
    },
    setInteractivity(interactive = true) {
      this.interactive = interactive;
      const disabled = ["dragRotate", "doubleClickZoom"];
      const switches = [
        "scrollZoom",
        "boxZoom",
        "dragPan",
        "keyboard",
        "touchZoomRotate"
      ];
      for (let prop of switches) {
        this.map[prop][interactive ? "enable" : "disable"]();
      }
      for (let prop of disabled) {
        this.map[prop].disable();
      }
      this.map.touchZoomRotate.disableRotation();
    },
    setSelectedMarkerStyle(station) {
      this.map.getSource("stations").setData({
        type: "FeatureCollection",
        features: this.StationData.stations.map(n => {
          return {
            type: "Feature",
            properties: {
              Station: n.Station,
              selected: n.Station === station,
              coordinates: `${n.lng},${n.lat}`
            },
            geometry: {
              type: "Point",
              coordinates: [n.lng, n.lat]
            }
          };
        })
      });
    },
    removeSelectedMarkerStyle() {
      this.map.getSource("stations").setData({
        type: "FeatureCollection",
        features: this.StationData.stations.map(n => {
          return {
            type: "Feature",
            properties: {
              Station: n.Station,
              selected: false,
              coordinates: `${n.lng},${n.lat}`
            },
            geometry: {
              type: "Point",
              coordinates: [n.lng, n.lat]
            }
          };
        })
      });
    },
    addStationMarkers() {
      for (let station of this.StationData.stations) {
        let el = document.createElement("div");
        el.className =
          "location-marker " + (station.New || station.Updated ? "new" : "");
        el.station = station.Station;
        el.addEventListener("click", () => {
          this.$emit("station", station.Station);
        });
        let marker = new mapboxgl.Marker(el)
          .setLngLat([station.lng, station.lat])
          .addTo(this.map);
        this.stationMarkers.push(marker);
      }
    },
    updateLineFilter() {
      this.map.setPaintProperty(
        "lines",
        "line-color",
        this.line ? ["get", "color"] : "#59849f"
      );

      if (
        (!this.line || this.line === "all") &&
        (this.mode === "explore" || this.mode === "intro")
      ) {
        this.map.setFilter("lines");
      } else {
        this.map.setFilter("lines", [
          "all",
          ["==", "line", this.line || false]
        ]);
      }
    },
    removeStationMarkerClasses() {
      for (let marker of this.stationMarkers) {
        marker._element.classList.remove("active");
      }
    },
    onStationChange(stationName) {
      this.removeStationMarkerClasses();
      let marker = this.stationMarkers.find(
        n => n._element.station === stationName
      );
      if (marker) marker._element.classList.add("active");
    },
    zoomOut() {
      this.map.zoomTo(this.map.getZoom() - this.zoomStep);
    },
    zoomIn() {
      this.map.zoomTo(this.map.getZoom() + this.zoomStep);
    },
    addColorToLine(line) {
      return {
        ...line,
        properties: {
          ...line.properties,
          color: this.lines.find(n => n.line === line.properties.line).bgcolor
        }
      };
    }
  },
  async mounted() {
    this.map = new mapboxgl.Map({
      container: this.$refs.map,
      style: "mapbox://styles/romeodigital/cjstoyfw57vcj1gny78a9fugc",
      zoom: this.defaultZoom,
      center: this.defaultCenter,
      maxTileCacheSize: 10000000000000000000,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom
    });

    const sources = [
      "local://crr",
      "local://lines",
      "local://lines/doomben",
      "local://lines/shorncliffe",
      "local://lines/caboolture",
      "local://lines/redcliffepeninsula",
      "local://lines/cleveland",
      "local://lines/goldcoast",
      "local://lines/ipswichrosewood",
      "local://lines/springfield",
      "local://lines/beenleigh",
      "local://lines/fernygrove",
      "local://lines/sunshinecoast"
    ];

    this.map.on("load", async () => {
      await this.StationData.init();
      const [crr, lines, ...allLines] = await Loader.batch(sources);
      this.lines = lines;

      this.map.addSource("stations", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: this.StationData.stations.map(n => {
            let align = n.label_align;
            if (n.label_align === "right") {
              align = "left";
            }
            if (n.label_align === "left") {
              align = "right";
            }
            return {
              type: "Feature",
              properties: {
                new: n.Updated || n.New,
                Station: n.Station,
                selected: false,
                coordinates: `${n.lng},${n.lat}`,
                label_align: align
              },
              geometry: {
                type: "Point",
                coordinates: [n.lng, n.lat]
              }
            };
          })
        }
      });

      this.map.addSource("lines", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: allLines.reduce((acc, line) => {
            return acc.concat(line.features.map(this.addColorToLine));
          }, [])
        }
      });
      this.map.addSource("crr", {
        type: "geojson",
        data: crr
      });
      this.map.addLayer({
        id: "lines",
        type: "line",
        source: "lines",
        layout: {
          "line-cap": "round"
        },
        paint: {
          "line-width": 5,
          "line-color": ["get", "color"]
        }
      });

      this.updateLineFilter();
      this.map.addLayer({
        id: "crr",
        type: "line",
        source: "crr",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": [
            "interpolate",
            ["exponential", 0.5],
            ["zoom"],
            11,
            5,
            13,
            ["case", ["get", "tunnel"], 10, 5]
          ],
          "line-color": "#F68926"
        }
      });

      this.map.addLayer({
        id: "station-labels",
        type: "symbol",
        source: "stations",
        paint: {
          "text-color": "#FFFFFF",
          "text-opacity": ["interpolate", ["linear"], ["zoom"], 12, 0, 13, 1]
        },
        layout: {
          visibility: "none",
          "text-size": 18,
          "text-field": ["get", "Station"],
          "text-anchor": ["get", "label_align"],
          "text-offset": [
            "case",
            ["==", ["get", "label_align"], "left"],
            ["literal", [1.8, 0]],
            ["==", ["get", "label_align"], "top"],
            ["literal", [0, -2.5]],
            ["==", ["get", "label_align"], "bottom"],
            ["literal", [0, 2.5]],
            ["==", ["get", "label_align"], "right"],
            ["literal", [-1.8, 0]],
            ["literal", [0, 0]]
          ],
          "text-justify": "auto"
        }
      });

      this.addStationMarkers();

      this.map.addLayer({
        id: "stations",
        source: "stations",
        type: "circle",
        layout: {
          visibility: "none"
        },
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            6,
            3,
            8,
            5,
            11,
            8
          ],
          "circle-stroke-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            8,
            0,
            12,
            3
          ],
          "circle-stroke-color": "#FFFFFF",
          "circle-opacity": 1
        }
      });

      this.map.on("click", "stations", e => {
        let clickedStation = this.map.queryRenderedFeatures(e.point)[0]
          .properties.Station;
        this.setSelectedMarkerStyle(clickedStation);
        this.$emit("station", clickedStation);
      });

      this.map.on("zoomstart", e => {
        if (!e.originalEvent) {
          this.flying = true;
        }
      });

      this.map.on("zoom", () => {
        this.$emit("zoom", this.map.getZoom());
        if (!this.flying) {
          this.removePopup();
          this.$emit("station", null);
          this.$emit("line", null);
        }
      });

      this.map.on("zoomend", () => {
        this.flying = false;
      });

      this.setMode();
      this.setInteractivity(false);
    });
  }
};
</script>

<style lang="scss">
@import "~scss/includes/vars";
.mapbox,
.map {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .map-ctrls {
    width: 3rem;
    position: absolute;
    right: 2rem;
    bottom: 35rem;
    .move-icon {
      font-size: 2rem;
      margin: 0 auto 1rem;
      text-align: center;
      svg {
        fill: white;
      }
    }
    hr {
      border: 0;
      border-top: 2px solid #efefef;
      margin: 0 auto;
    }
    .map-ctrl-btns {
      background: white;
      button {
        appearance: none;
        display: block;
        width: 3rem;
        height: 3rem;
        border: 0;
        font-size: 1.3rem;
        padding-top: 0.6rem;
        outline: 0;
        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }
}
$radius: 15px;
$close: 30px;
.mapbox {
  position: fixed;
  .map {
    position: absolute;
  }
  .mapboxgl-popup-content {
    position: relative;
  }
  .benefit {
    .mapboxgl-popup-content {
      padding-right: $close;
      &:after,
      &:before {
        content: "";
        position: absolute;
        width: 2 * $radius;
        height: 2 * $radius;
        left: -$radius;
        top: calc(50% - #{$radius});
        background: currentColor;
        border-radius: 50%;
        animation: scaleIn 0.4s forwards;
      }
    }
    .mapboxgl-popup-close-button {
      position: absolute;
      top: 0;
      right: 0;
      width: $close;
      height: $close;
      appearance: none;
      border-radius: 0;
      border: 0;
      outline: none;
      opacity: 0;
      animation: 0.5s fade-in 0.5s forwards;
      font-size: 2rem;
      padding: 0;
      line-height: $close;
      text-align: center;
    }
    .mapboxgl-popup-tip {
      display: none;
    }
    $colors: (
      red: $red-light,
      yellow: $yellow,
      blue: $blue-light,
      purple: $purple-light,
      teal: $teal-light
    );
    @each $label, $color in $colors {
      &.#{$label} {
        color: $color;
        .mapboxgl-popup-close-button {
          background: $color;
        }
      }
    }
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(3);
  }
  70.1% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(5px) rotateX(30deg);
    transform-origin: bottom;
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotateX(0deg);
  }
}
</style>
