<template>
  <div :class="['bg-image-slides', fadeClass]">
    <CMSImage class="bg-image outgoing" :image="currentImage" />
    <CMSImage class="bg-image incoming" :image="nextImage" />
  </div>
</template>

<script>
import CMSImage from "@/components/CMSImage";

export default {
  name: "BgImageSlides",
  props: ["images"],
  data() {
    return {
      fadeClass: "slidePause",
      currentIndex: 0,
      fadeTimeout: null,
      fadePeriod: 4000,
      waitPeriod: 6000
    };
  },
  computed: {
    numImages() {
      return this.images.length;
    },
    nextIndex() {
      return (this.currentIndex + 1) % this.numImages;
    },
    currentImage() {
      return this.images[this.currentIndex].image;
    },
    nextImage() {
      return this.images[this.nextIndex].image;
    }
  },
  methods: {
    fadeStart() {
      this.fadeClass = "slideIn";
      this.fadeTimeout = setTimeout(() => this.fadeEnd(), this.fadePeriod);
    },
    fadeEnd() {
      this.currentIndex = this.nextIndex;
      this.fadeClass = "slidePause";
      this.fadeTimeout = setTimeout(() => this.fadeStart(), this.waitPeriod);
    }
  },
  mounted() {
    if (this.numImages > 1) {
      this.fadeTimeout = setTimeout(() => this.fadeStart(), this.waitPeriod);
    }
  },
  components: {
    CMSImage
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
.bg-image-slides {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $grey;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 15%,
      rgba(0, 0, 0, 0)
    );
    z-index: 3;
  }

  .bg-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
  }
  .incoming {
    opacity: 0;
    filter: blur(16px);
    z-index: 2;
  }
  .outgoing {
    opacity: 1;
    filter: blur(0);
    z-index: 1;
  }

  &.slideIn {
    .outgoing {
      filter: blur(16px);
      transition: filter 2s;
    }
    .incoming {
      opacity: 1;
      filter: blur(0);
      transition: opacity 1.5s 0.5s, filter 2s 0.5s;
    }
  }
}
</style>
