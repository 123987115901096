import { render, staticRenderFns } from "./HomeExtraButton.vue?vue&type=template&id=4eef6eb9&scoped=true"
import script from "./HomeExtraButton.vue?vue&type=script&lang=js"
export * from "./HomeExtraButton.vue?vue&type=script&lang=js"
import style0 from "./HomeExtraButton.vue?vue&type=style&index=0&id=4eef6eb9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eef6eb9",
  null
  
)

export default component.exports