<template>
  <router-link :to="link" class="home-extra-button">
    <img :src="image" :alt="label" class="icon" />
    <label class="label">{{ label }}</label>
  </router-link>
</template>

<script>
export default {
  name: "HomeExtraButton",
  props: {
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.home-extra-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    display: block;
    width: auto;
    height: 160px;
    margin-bottom: 10px;
  }

  .label {
    display: block;
    font-size: 24px;
    font-weight: bold;
    padding: 0.75em 1.5em;
    color: $white;
    background-color: $blue;
    box-shadow: 0 2px 4px 0 rgba(black, 0.5);
    cursor: pointer;
  }
}
</style>
