import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Cache is an object with keys matching PayloadCMS global slugs, and values
// representing load status and loaded data

// Status can be "missing", "loaded" or "error"

export default new Vuex.Store({
  state: {
    cache: {}
  },
  getters: {
    page(state) {
      return slug => state.cache[slug] || { status: "missing" };
    },
    pageLoaded(_state, getters) {
      return slug => getters.page(slug).status === "loaded";
    },
    pagesLoaded(_state, getters) {
      return slugs => slugs.every(slug => getters.pageLoaded(slug));
    },
    pageData(_state, getters) {
      return slug => getters.page(slug).data || {};
    }
  },
  mutations: {
    setPageStatus(state, { slug, status }) {
      Vue.set(state.cache, slug, {
        ...state.cache[slug],
        status
      });
    },
    cachePageData(state, { slug, data }) {
      Vue.set(state.cache, slug, {
        status: "loaded",
        data
      });
    }
  },
  actions: {
    loadPage({ getters, commit }, slug) {
      // Check if already loaded
      const cached = getters.page(slug);
      if (cached.status === "loaded") return Promise.resolve();

      // Else fetch from API
      const url = `${process.env.VUE_APP_API}/api/globals/${slug}?depth=5`;
      commit("setPageStatus", { slug, status: "loading" });
      return new Promise((resolve, reject) => {
        fetch(url)
          .then(async res => {
            if (res.ok) {
              const data = await res.json();
              commit("cachePageData", { slug, data });
              resolve();
            } else {
              commit("setPageStatus", { slug, status: "error" });
              reject();
            }
          })
          .catch(() => {
            console.error(`Failed to fetch data for "${slug}".`);
            commit("setPageStatus", { slug, status: "error" });
            reject();
          });
      });
    },
    loadPages({ dispatch }, slugs) {
      return new Promise((resolve, reject) => {
        Promise.all(slugs.map(slug => dispatch("loadPage", slug)))
          .then(resolve)
          .catch(reject);
      });
    }
  }
});
