export default class Loader {
  static _fetch(path) {
    return fetch(path).then(res => res.json());
  }

  static local(file) {
    return Loader._fetch(`/data/${file}.json`);
  }

  static api(endpoint) {
    return Loader._fetch(`/api/${endpoint}`);
  }

  static load(uri) {
    const [scheme, path] = uri.split("://");
    switch (scheme) {
      case "local":
        return Loader.local(path);
      case "api":
        return Loader.api(path);
      default:
        return Promise.reject();
    }
  }

  static batch(uris) {
    return Promise.all(uris.map(Loader.load));
  }
}
