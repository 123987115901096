<template>
  <div className="overlay">
    <div @click.prevent="close" class="background"></div>
    <div @click.prevent="close" class="image">
      <img :src="data.src" :alt="data.alt" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZoomOverlay",
  props: ["data"],
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.overlay {
  position: absolute;
  inset: 0;
}

.background {
  position: absolute;
  inset: 0;
  background: $overlay;

  .overlay-enter-active &,
  .overlay-leave-active & {
    transition: opacity 0.3s;
  }

  .overlay-enter &,
  .overlay-leave-to & {
    opacity: 0;
  }
}

.image {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  background-size: cover;
  background-position: center;
  position: fixed;

  pointer-events: initial;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1400px;
  height: 600px;

  .overlay-enter-active & {
    transition: width 0.6s, height 0.6s, top 0.6s, left 0.6s, transform 0.6s,
      opacity 0.3s;
  }
  .overlay-leave-active & {
    transition: width 0.6s, height 0.6s, top 0.6s, left 0.6s, transform 0.6s,
      opacity 0.3s 0.3s;
  }

  .overlay-enter &,
  .overlay-leave-to & {
    opacity: 0;
    width: 240px;
    height: 60px;
    top: 80%;
    left: 72%;
    transform: translate(0, 0);
  }

  img {
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: inline-block;
    height: auto;
    width: auto;
    margin: auto;
  }
}

// Forces Vue to set transition duration
.overlay-enter-active,
.overlay-leave-active {
  transition: position 0.6s;
}
</style>
