<template>
  <div :class="['sidebar', { open: showSidebar }]">
    <div class="sidebar-title">
      <slot name="title"></slot>
    </div>
    <div class="sidebar-content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "sidebar",
  props: ["open"],
  mixins: [mixins]
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
.sidebar {
  background: $offwhite;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: $sidebarwidth;
  padding: 390px 60px 120px 90px;
  transform: translateX(-100%);
  transition: all 0.6s 0.1s;
  .sidebar-content {
    padding: 30px 0;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: white;
    height: 190px;
  }
  &.open {
    transform: translateX(0);
  }
  ::v-deep {
    h1 {
      .text {
        display: inline-block;
        font-weight: 700;
      }
      .station {
        position: absolute;
        left: 570px - 50px;
        top: calc(100% - 0.35em);
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: $h1border;
          background: $green;
        }
      }
      .h1 {
        &::before {
          display: none;
        }
        &.station-name {
          transition-delay: 0.1s;
        }
      }
    }
    .sidebar-title {
      position: absolute;
      left: 50px;
      top: 190px;
      transition: all 0.6s;
      &::before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        background: white;
        width: 100vw;
      }
      @include enter {
        & {
          opacity: 1;
          transform: translate(0, 0);
        }
      }
      @include leave {
        & {
          opacity: 0;
          transform: translate(-20rem, 0);
        }
      }
    }
    .the-content {
      transition: all 1.5s;
      @include enter {
        opacity: 1;
      }
      @include leave {
        opacity: 0;
      }
    }
  }
}
</style>
