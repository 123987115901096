<template>
  <div>
    <div class="home">
      <div class="bg-image">
        <img :src="homeimg" />
      </div>

      <div class="title">
        <img :src="titleimg" alt="Future Benefits" />
        <h2>Find out what</h2>
        <h1>CROSS RIVER RAIL</h1>
        <h2>means to you</h2>
      </div>

      <div class="button-wrapper">
        <router-link to="/benefits/select" class="benefits-button"
          >Start Here</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import homeimg from "@/assets/images/benefits/home-bg.png";
import titleimg from "@/assets/images/benefits/title-future-benefits.svg";

export default {
  data() {
    return {
      homeimg,
      titleimg
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.home {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #081d29;
}

.bg-image {
  position: relative;

  img {
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.05;
    background: linear-gradient(180deg, #cb37e3 0%, rgba(236, 122, 255, 0) 100%),
      linear-gradient(180deg, rgba(8, 29, 41, 0) 76.14%, #081d29 92.84%);
    background-position: center top;
    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
  }
}

.title {
  width: 850px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);

  img {
    margin-bottom: 60px;
  }

  h1 {
    font-style: italic;
    font-weight: 900;
    font-size: 60px;
    color: $white;
    margin-bottom: 12px;
  }

  h2 {
    font-style: italic;
    font-weight: 900;
    font-size: 40px;
    color: $white;
    margin-bottom: 20px;
  }
}

.button-wrapper {
  margin-top: 230px;
  text-align: center;
}

.benefits-button {
  position: relative;
  display: inline-block;
  border-radius: 35px;
  background-color: #081d29;
  color: $white;
  font-size: 40px;
  font-weight: bold;
  line-height: 60px;
  padding: 6px 40px 4px 40px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 37px;
    background: linear-gradient(90deg, #cb37e3 0%, #25a1c8 100%);
    z-index: -1;
  }
}
</style>
