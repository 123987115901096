<template>
  <div>
    <iframe
      class="frame"
      :src="artUrl"
      @load="onIFrameLoad"
      sandbox="allow-same-origin allow-scripts"
    ></iframe>
    <transition name="fade">
      <div v-if="state !== 'loaded'" class="frame info">
        <StationHomeLink v-if="showHomeLink" />
        <template v-if="state === 'loading'">
          <h2>Station Art</h2>
          <p>Loading, please wait...</p>
        </template>
        <template v-else>
          <h2>Failed to load Station Art</h2>
          <p>Please check this device's internet connection and try again.</p>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import StationHomeLink from "@/components/StationHomeLink";

const ART_ORIGIN = "https://crossriverrailart.qld.gov.au/utils/setkioskmode";
const SUPPORTED_PARAMS = ["kiosk", "home", "embed"];
const LOAD_TIMEOUT = 10000;

export default {
  name: "Art",
  data() {
    return {
      state: "loading",
      loadTimeout: null,
      artUrl: ART_ORIGIN
    };
  },
  computed: {
    visible() {
      return this.state === "loaded";
    },
    showHomeLink() {
      return "home" in this.$route.query;
    }
  },
  methods: {
    onIFrameLoad() {
      this.onLoad();
    },
    onLoad() {
      window.clearTimeout(this.loadTimeout);
      this.state = "loaded";
    },
    onError() {
      this.state = "error";
    },
    onExit() {
      this.$router.back();
    }
  },
  created() {
    // Pass query params to iframe
    const params = SUPPORTED_PARAMS.filter(param => param in this.$route.query);
    if (params.length) {
      this.artUrl += "?" + params.join("&");
    }

    // If iframe doesn't load after set timeframe, assume it won't load at all
    this.loadTimeout = window.setTimeout(this.onError, LOAD_TIMEOUT);
  },
  beforeDestroy() {
    window.clearTimeout(this.loadTimeout);
  },
  components: {
    StationHomeLink
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/transitions";

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background-color: white;
    background-color: #efefed;

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
</style>
