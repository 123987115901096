<template>
  <div>
    <div class="station">
      <div class="bg-image">
        <CMSImage class="station-img" :image="stationData.heroImage" />
        <img class="overlay" :src="bgOverlay" />
      </div>

      <div class="tiles">
        <router-link
          :to="`/progress/station/${stationData.slug}/timehop`"
          v-if="stationData.timeHop.panorama"
          class="tile time-hop"
        >
          <div class="station-image">
            <CMSImage
              :image="stationData.timeHop.thumbnail || stationData.heroImage"
              :width="613"
            />
          </div>
          <div class="title">Time Hop</div>
          <img class="play-icon" :src="playimg" />
        </router-link>
        <router-link
          v-if="stationData.view360.panorama"
          :to="`/progress/station/${stationData.slug}/view360`"
          class="tile view-360"
        >
          <div class="station-image">
            <CMSImage
              :image="stationData.view360.thumbnail || stationData.heroImage"
              :width="613"
            />
          </div>
          <div class="title">360 View</div>
          <img class="play-icon" :src="playimg" />
        </router-link>
        <router-link
          v-if="stationData.constructionProgress.slides"
          :to="`/progress/station/${stationData.slug}/construction`"
          class="tile construction"
        >
          <div class="station-image">
            <CMSImage :image="stationData.heroImage" :width="613" />
          </div>
          <div class="title">
            Construction Progress
          </div>
          <img class="play-icon" :src="playimg" />
        </router-link>
      </div>
    </div>
    <ProgressNav :station="stationData" />
  </div>
</template>

<script>
import playimg from "@/assets/images/taking-shape/home-play-icon.svg";
import bgOverlay from "@/assets/images/progress/bg-overlay.png";
import CMSImage from "@/components/CMSImage";
import ProgressNav from "@/pages/progress/ProgressNav";

export default {
  props: ["slug"],
  data() {
    return {
      playimg,
      bgOverlay
    };
  },
  computed: {
    stationData() {
      const { brisbane, goldCoast } = this.$store.getters.pageData("progress");
      return [...brisbane, ...goldCoast].find(item => item.slug === this.slug);
    }
  },
  components: {
    CMSImage,
    ProgressNav
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.bg-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: $deep-purple;
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .station-img {
    opacity: 0.66;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba($black, 0) 0%,
      rgba($black, 0.75) 100%
    );
  }
}

.station {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.tiles {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100vw;
  height: calc(100vh - 125px);
  z-index: 1;
}

.tile {
  display: inline-block;
  position: relative;
  width: 613px;
  height: 344px;

  clip-path: polygon(90px 0, 613px 0, 523px 344px, 0 344px);
  margin: 12px -45px;

  &.time-hop {
    border-bottom: 10px solid $green;
  }
  &.view-360 {
    border-bottom: 10px solid $orange;
  }
  &.construction {
    border-bottom: 10px solid $purple;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      45deg,
      rgba($black, 0.5) 0%,
      rgba($black, 0) 100%
    );
  }

  .station-image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    position: absolute;
    left: 64px;
    bottom: 28px;

    color: $white;
    font-size: 42px;
    font-style: italic;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -1.2px;
    z-index: 1;
  }

  .play-icon {
    position: absolute;
    width: 128px;
    height: 128px;
    top: calc(50% - 64px);
    left: calc(50% - 64px);
    z-index: 1;
  }
}

.media-screen {
  position: absolute;
  width: 100vw;
  height: 100vh;
  .view-btn {
    position: absolute;
    right: 60px;
    bottom: 29px;
    z-index: 40;
  }
}
</style>
