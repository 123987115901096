import Loader from "@/lib/LoadData";

function getStationLineParser(station) {
  return function(line) {
    const getBenefit = n => ({
      benefit: station[`Line${line}_Benefit ${n}`],
      icon: station[`Line${line}_Benefit ${n} icon`],
      subhead: station[`Line${line}_Benefit ${n} subhead`]
    });
    return {
      line: station[`Line ${line}`],
      benefits: [1, 2].map(getBenefit)
    };
  };
}

class StationData {
  constructor() {
    this.stations = [];
  }

  init() {
    return new Promise(resolve => {
      Loader.load("local://stations").then(data => {
        this.stations = data;
        resolve();
      });
    });
  }

  getStation(name) {
    return this.stations.find(n => n.Station === name);
  }

  getStationLines(name) {
    const activeStation = this.getStation(name);
    if (!activeStation) return [];

    const parser = getStationLineParser(activeStation);
    return [1, 2, 3, 4, 5].map(parser).filter(line => line.line !== 0);
  }
}

export default new StationData();
