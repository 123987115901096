<template>
  <button
    @click.prevent="!disabled ? $emit('clicked') : false"
    :class="['btn', classes, { disabled }]"
  >
    <Icon v-if="icon" :icon="icon" class="icon" />{{ label }}
  </button>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  props: ["label", "icon", "classes", "disabled"],
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
.btn {
  display: inline-block;
  padding: 0.7em 1.5em 0.5em;
  font-size: 22px;
  appearance: none;
  border: 0;
  outline: 0;
  border-radius: 2em;
  background: $green;
  color: $blue;
  font-weight: 700;
  transition: all 0.2s;
  margin-bottom: 1rem;
  &.large {
    font-size: 28px;
  }
  &.orange {
    background: $orange;
  }
  &.white-text {
    color: $white;
  }
  .icon {
    height: 1.25em;
    width: 1.25em;
    vertical-align: middle;
    margin-right: 0.5em;
    margin-bottom: 0.1em;
    fill: currentColor;
  }
  &.disabled {
    background: lighten($grey, 60%);
    color: white;
    opacity: 0.95;
  }
}
</style>
