<template>
  <div>
    <div class="gold-coast">
      <div class="bg-image">
        <img :src="mapimg" />
      </div>

      <div class="content">
        <h1>Gold Coast</h1>
        <h2>3 NEW stations</h2>

        <div class="legend">
          <div class="key">
            <div class="dot orange"></div>
            New Stations
          </div>
        </div>

        <div class="back-button">
          <router-link to="/progress" class="btn btn-orange small-icon">
            <Icon class="icon" icon="close" />
            Back to Menu
          </router-link>
        </div>
      </div>

      <img :src="raillineimg" class="rail-line" />

      <div class="stations">
        <div
          v-for="dot in GoldCoastExtras"
          class="station-dot"
          :key="dot.x"
          :style="`left: ${dot.x}px; top: ${dot.y}px;`"
        ></div>
        <div
          v-for="station in combinedGoldCoastStations"
          :class="[
            'station',
            { above: station.type === 'above' },
            { 'label-right': station.label === 'right' }
          ]"
          :key="station.name"
          :style="`left: ${station.map.x}px; top: ${station.map.y}px;`"
        >
          <router-link
            v-if="!station.unlinked"
            :to="`/progress/station/${station.slug}`"
            class="station-name"
            >{{ station.name }}</router-link
          >
          <div v-else class="station-name-unlinked">{{ station.name }}</div>
          <div v-if="station.heroImage.url" class="station-image">
            <CMSImage :image="station.heroImage" :width="63" :quality="95" />
          </div>
          <div v-else class="station-dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapimg from "@/assets/images/progress/goldcoast-map.jpg";
import raillineimg from "@/assets/images/progress/goldcoast-line.svg";
import CMSImage from "@/components/CMSImage";
import Icon from "@/components/Icon";
import stationsContent, { GoldCoastExtras } from "@/content/progressStations";

export default {
  data() {
    return {
      stationsContent,
      GoldCoastExtras,
      mapimg,
      raillineimg
    };
  },
  computed: {
    combinedGoldCoastStations() {
      return this.goldCoastStations.map(station => {
        const stationData = this.stationsContent.find(
          item => item.slug === station.slug
        );
        return {
          ...station,
          ...stationData
        };
      });
    },
    goldCoastStations() {
      return this.$store.getters.pageData("progress").goldCoast;
    }
  },
  components: {
    CMSImage,
    Icon
  },
  mounted() {
    localStorage.returnpath = "/progress/gold-coast";
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.gold-coast {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-image {
  position: relative;
  background: #705370;

  img {
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    background: linear-gradient(-90deg, rgba(#2c002c, 0) 46.88%, #2c002c 100%);
  }
}

.content {
  position: absolute;
  left: 84px;
  right: 1200px;
  top: 400px;
  bottom: 64px;

  h1 {
    @include typeface-gradient;
    margin-bottom: 32px;
  }
  h2 {
    font-size: 48px;
    color: $white;
  }

  .legend {
    margin-top: 70px;
    .key {
      color: $white;
      text-transform: uppercase;
      font-weight: 900;
      font-style: italic;
      font-size: 24px;
      line-height: 46px;
      margin-bottom: 12px;
      .dot {
        display: inline-block;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 8px;
        &.green {
          background: $green;
        }
        &.orange {
          background: $orange;
        }
      }
    }
  }

  .back-button {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.rail-line {
  position: absolute;
  left: 1029px;
  top: 0;
  width: 609px;
  height: 1080px;
}

.station-dot {
  position: absolute;
  left: -12px;
  top: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $green;
}

.station {
  position: absolute;

  .station-image {
    position: absolute;
    left: -35px;
    top: -35px;
    width: 71px;
    height: 71px;
    border-radius: 50%;
    border: 4px solid $white;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .station-name {
    position: absolute;
    top: -20px;
    right: 42px;
    color: $white;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.6px;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 6px 32px 2px 32px;
    border-radius: 32px;
    background-color: $green;
  }

  &.above .station-name {
    background-color: $orange;
  }

  &.label-right .station-name {
    right: auto;
    left: 42px;
  }
}
</style>
