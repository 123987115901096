const getMeta = route => route.meta || {};

export default {
  computed: {
    onChildRoute() {
      return getMeta(this.$route).child === true;
    },
    showSidebar() {
      return getMeta(this.$route).showSidebar === true;
    },
    childRoutes() {
      const label = getMeta(this.$route.matched[0]).label;
      const route = this.$router.options.routes.find(route => {
        return getMeta(route).label === label;
      });
      return route ? route.children : [];
    }
  }
};
