<template>
  <div class="home">
    <BgImage :image="pageData.heroImage" />
  </div>
</template>

<script>
import BgImage from "@/components/BgImage";
import mixins from "@/mixins";

export default {
  computed: {
    pageData() {
      return this.$store.getters.pageData("stations-home");
    }
  },
  components: {
    BgImage
  },
  mixins: [mixins]
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.home {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: $sidebarwidth;
}
</style>
