<template>
  <div
    :class="[
      'checkmate-experience',
      'mode-' + mode,
      {
        noSearchResults,
        zoomedOut,
        zoomedClose,
        settingPostcode: mode === 'postcode' && station === null
      }
    ]"
  >
    <Map
      ref="map"
      @zoom="onZoom"
      @station="onStation"
      @line="onLine"
      v-bind="{ mode, station, line }"
    />

    <div class="gradient"></div>
    <h1>
      <span class="h1 future"><span class="text">My future</span></span>
      <span class="h1 journey"><span class="text">journey</span></span>
    </h1>

    <p class="intro-copy">
      Find out what<br />Cross River Rail<br />means for you
    </p>
    <div class="intro-actions">
      <Button
        @clicked="enterPostcode"
        icon="postcode"
        classes="large green"
        label="Enter your postcode"
      />
      <Button
        @clicked="enterFreeExplore"
        icon="pan"
        classes="large orange"
        label="See our new stations"
      />
    </div>

    <div class="actions">
      <span @click.prevent="goHome">
        <Icon icon="home" />
      </span>
      <Button
        v-if="mode !== 'postcode'"
        @clicked="enterPostcode"
        icon="postcode"
        classes="large green"
        label="Enter your postcode"
      /><!--  || station === null -->
      <Button
        v-if="mode !== 'explore'"
        @clicked="enterFreeExplore"
        icon="pan"
        classes="large orange"
        label="See our new stations"
      /><!-- v-if="mode !== 'explore' && station !== null" -->
    </div>

    <transition mode="out-in">
      <div
        v-if="showBenefitsContent"
        :class="[
          'benefits-content',
          'orange',
          ...benefitsClasses,
          { hasImages }
        ]"
      >
        <span v-if="stationObject && stationObject.New" class="tag">New</span>
        <span v-if="stationObject && stationObject.Updated" class="tag"
          >Updated</span
        >
        <p class="benefits-content-title">{{ benefitsTitle }}</p>
        <p
          class="benefits-content-description"
          v-if="stationObject && stationObject.Description"
        >
          {{ stationObject.Description }}
        </p>
        <div v-if="mode !== 'benefits'" class="benefits-content-content">
          <div
            v-for="(benefit, i) in stationBenefitsContentColumns"
            class="benefit-column"
            :key="'benefit-column-' + i"
          >
            <div class="benefit-icon"><Icon :icon="benefit.icon" /></div>
            <h4 class="benefit-heading">{{ benefit.subhead }}</h4>
            <p>{{ benefit.benefit }}</p>
          </div>
        </div>
        <div v-if="hasImages" class="benefits-content-images">
          <div
            class="benefits-content-image"
            v-for="(image, i) in stationObject.Images"
            :key="i"
            :style="`background-image: url(${image})`"
          ></div>
        </div>
        <div
          v-if="station"
          class="benefits-content-border"
          :style="benefitsBorderStyle"
        >
          <span class="station-line"
            >{{ station }}<span v-if="lineLabel" v-html="lineLabel"></span
          ></span>
          <span @click="resetTrains" class="close" :style="benefitsBorderStyle"
            ><Icon icon="close"
          /></span>
        </div>
      </div>
    </transition>

    <transition>
      <MapNoResults v-if="showNoResults" class="map-search-no-results" />
    </transition>

    <transition>
      <MapSearch
        v-if="showSearch"
        class="map-search"
        @noResults="onNoResults"
        @home="goHome"
        @station="onStation"
      />
    </transition>

    <h1 @click="goHome" class="small">
      <span class="h1"><span class="text">My future</span></span>
      <span class="h1 journey"><span class="text">journey</span></span>
    </h1>
  </div>
</template>

<script>
import Button from "@/components/Button";
import Map from "@/components/Map";
import MapSearch from "@/components/MapSearch";
import Icon from "@/components/Icon";
import MapNoResults from "@/components/MapNoResults";

import Loader from "@/lib/LoadData";
import StationData from "@/lib/StationData";

export default {
  data() {
    return {
      StationData,
      lines: [],
      mode: "intro", //'intro'
      station: null,
      line: null,
      zoomedOut: false,
      zoomedClose: false,
      noSearchResults: false
    };
  },
  computed: {
    idle() {
      return this.$root.idle;
    },
    hasImages() {
      return (
        this.stationObject &&
        this.stationObject.Images &&
        this.stationObject.Images.length
      );
    },
    lineLabel() {
      if (this.line && this.line !== "all") {
        return ` &mdash; ${this.line}`;
      }
      if (this.stationObject && this.stationObject.New) {
        return " &mdash; New Station";
      }
      if (this.stationObject && this.stationObject.Updated) {
        return " &mdash; Updated Station";
      }
      return false;
    },
    stationObject() {
      return this.StationData.getStation(this.station);
    },
    benefitsTitle() {
      if (this.stationObject.New || this.stationObject.Updated) {
        return this.stationObject.Station + " Station";
      }
      return "Your Future Journey Benefits";
    },
    benefitsClasses() {
      let classes = [];
      if (this.stationObject.New) {
        classes.push("new");
      }
      if (this.stationObject.Updated) {
        classes.push("updated");
      }
      return classes;
    },
    showBenefitsContent() {
      return (
        this.station !== null &&
        this.line &&
        this.stationBenefitsContentColumns.length
      );
    },
    benefitsBorderStyle() {
      let line = this.lines.find(n => n.line === this.line);
      return this.station && this.line && this.line !== "all" && line
        ? {
            backgroundColor: line.bgcolor,
            color: line.color || "white"
          }
        : {
            backgroundColor: "#F68926",
            color: "white"
          };
    },
    showNoResults() {
      return this.mode === "postcode" && this.noSearchResults;
    },
    showSearch() {
      return this.mode === "postcode" && this.station === null;
    },
    stationBenefitsContentColumns() {
      let stationObject = this.stationObject;
      let line = Object.entries(stationObject).find(e => e[1] === this.line);
      let columns = [];
      if (stationObject.New || stationObject.Updated) {
        return [
          {
            icon: stationObject[`Benefit 1 icon`],
            subhead: stationObject[`Benefit 1 subhead`]
          },
          {
            icon: stationObject[`Benefit 2 icon`],
            subhead: stationObject[`Benefit 2 subhead`]
          },
          {
            icon: stationObject[`Benefit 3 icon`],
            subhead: stationObject[`Benefit 3 subhead`]
          }
        ];
      }
      if (!line) {
        if (stationObject["Line 1"] !== 0) {
          return [];
        }
        columns = [
          {
            benefit: stationObject[`Line1_Benefit 1`],
            icon: stationObject[`Line1_Benefit 1 icon`],
            subhead: stationObject[`Line1_Benefit 1 subhead`]
          }
        ];
        if (stationObject[`Line1_Benefit 2`] !== 0) {
          columns.push({
            benefit: stationObject[`Line1_Benefit 2`],
            icon: stationObject[`Line1_Benefit 2 icon`],
            subhead: stationObject[`Line1_Benefit 2 subhead`]
          });
        }
      } else {
        let lineNumber = line[0].split(" ")[1];
        let base = `Line${lineNumber}_Benefit`;
        columns = [
          {
            benefit: stationObject[`${base} 1`],
            icon: stationObject[`${base} 1 icon`],
            subhead: stationObject[`${base} 1 subhead`]
          },
          {
            benefit: stationObject[`${base} 2`],
            icon: stationObject[`${base} 2 icon`],
            subhead: stationObject[`${base} 2 subhead`]
          }
        ];
      }
      columns.push({
        benefit: stationObject[`Roads`],
        icon: stationObject[`Roads icon`],
        subhead: stationObject[`Roads subhead`]
      });
      if (stationObject[`Bonus`] !== 0) {
        columns.push({
          benefit: stationObject[`Bonus`],
          icon: stationObject[`Bonus icon`],
          subhead: stationObject[`Bonus subhead`]
        });
      }
      return columns;
    }
  },
  watch: {
    idle(next, prev) {
      if (next === true && next !== prev) {
        this.goHome();
      }
    },
    station(next, prev) {
      if (next && this.mode === "postcode") {
        this.mode = "explore";
      }
      if (next !== prev && this.mode !== "intro") {
        this.line = "all";
      }
    },
    mode(next, prev) {
      if (next !== "postcode" && prev !== "postcode") {
        this.station = null;
      }
      if (next === "intro") {
        this.line = null;
      }
    }
  },
  methods: {
    onNoResults(noResults) {
      this.noSearchResults = noResults;
    },
    onLine(line) {
      this.line = line;
    },
    onZoom(z) {
      this.zoomedOut = z < 11;
      this.zoomedClose = z > 13;
    },
    onStation(station) {
      this.station = station;
    },
    setMode(mode) {
      this.mode = mode;
    },
    setStation(station) {
      this.station = station;
    },
    resetStation() {
      this.station = null;
    },
    setLine(line) {
      this.line = line;
    },
    resetLine() {
      this.line = null;
    },
    resetTrains() {
      this.resetLine();
      this.resetStation();
      this.$refs.map.removePopup();
    },
    goHome() {
      this.setMode("intro");
      this.resetStation();
    },
    onSearch({ station, line }) {
      this.setStation(station);
      this.setLine(line);
    },
    enterFreeExplore() {
      this.setMode("explore");
    },
    enterPostcode() {
      this.resetStation();
      this.setLine("all");
      this.setMode("postcode");
    }
  },
  async created() {
    await this.StationData.init();
    this.lines = await Loader.load("local://lines");
  },
  mounted() {
    window.addEventListener("select-line", e => {
      this.line = e.line;
    });
  },
  components: {
    Button,
    Icon,
    Map,
    MapSearch,
    MapNoResults
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";

.map-search-no-results {
  position: fixed;
  top: 21%;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  @include enter {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0rem);
  }
  @include leave {
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 2rem);
  }
}

.checkmate-experience {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $navy;
}
.gradient {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 400px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
  pointer-events: none;
  opacity: 1;
  transition: opacity 1s;
  .mode-intro & {
    opacity: 0.95;
    height: 600px;
  }
}
.map-search {
  position: fixed;
  top: 68%;
  left: 0;
  right: 0;
  z-index: 2;
  margin: auto;
}
.intro-copy {
  width: 370px;
  color: white;
  line-height: 1.5;
  position: fixed;
  top: 45%;
  left: 39%;
  font-size: 36px;
  transform: translateY(2rem);
  opacity: 0;
  transition: all 0.6s 0s;
  visibility: hidden;
  text-shadow: 0 0 3rem rgba(0, 0, 0, 0.6);
  .mode-intro & {
    opacity: 1;
    transition: all 1.5s 0.5s;
    transform: translateY(0);
    visibility: visible;
  }
}
p {
  font-size: 36px;
}
h1 {
  position: fixed;
  transform: translate(-60%, -50%);
  left: 50%;
  top: 35%;
  font-size: 120px;
  visibility: hidden;
  &.small {
    left: 950px;
    top: 100px;
    font-size: 26px;
  }
  .h1 {
    color: $blue;
    font-size: 1em;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    padding: 0.35em 0.3em 0.25em;
    visibility: hidden;
    box-shadow: 0em 0em 0em rgba(0, 0, 0, 0);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 0;
      transition: all 0.3s;
      width: 0;
    }
    &::after {
      display: none;
    }
    .text {
      position: relative;
      font-weight: 900;
      transition: all 0.4s;
      opacity: 0;
    }
    &.journey {
      display: inline-block;
      transform: translate(1.6em, -0.4em);
      padding-left: 0.5em;
      padding-right: 0.5em;
      &::before {
        border-bottom: 8px $green solid;
      }
    }
  }
  &.small {
    .text {
      opacity: 1;
    }
    &,
    .h1 {
      visibility: visible;
      opacity: 1;
      transition: all 0.7s;
    }
    .h1 {
      box-shadow: 0.5em 0.5em 1.5em rgba(0, 0, 0, 0.25);
      &.journey {
        box-shadow: 0.5em 1.5em 1em rgba(0, 0, 0, 0.125);
      }
      &::before {
        width: 100%;
        transition: all 0.8s;
      }
      &:nth-of-type(2)::before {
        transition-delay: 0.1s;
      }
    }
  }
  .mode-intro &.small {
    .h1 {
      visibility: hidden;
      &,
      .text {
        opacity: 0;
      }
      &::before {
        width: 0%;
        transition: all 0.5s;
      }
      &:nth-of-type(2)::before {
        transition-delay: 0.2s;
      }
    }
  }
  .mode-intro &:not(.small) {
    opacity: 1;
    visibility: visible;
    .text {
      opacity: 1;
    }
    .h1 {
      opacity: 1;
      visibility: visible;
      box-shadow: 0.5em 0.5em 1.5em rgba(0, 0, 0, 0.25);
      transition: all 0.7s;
      &.journey {
        box-shadow: 0.5em 1.5em 1em rgba(0, 0, 0, 0.125);
      }
      &::before {
        width: 100%;
        transition: all 0.8s;
      }
      &:nth-of-type(2)::before {
        transition-delay: 0.2s;
      }
    }
  }
}
.intro-actions,
.actions {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  filter: blur(3px);
  transition: all 0.3s;
}
.intro-actions {
  top: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 450px;
  left: 50%;
  transform: translateX(-50%);
  .mode-intro & {
    filter: blur(0);
    visibility: visible;
    opacity: 1;
  }
  .btn {
    margin: 0 1em 2em;
  }
}
.actions {
  top: 1730px;
  transform: scale(0.8);
  .icon {
    fill: white;
    font-size: 3rem;
    vertical-align: middle;
    margin-right: 1rem;
  }
  .btn.large {
    margin: 0 0.5em;
    font-size: 1.75rem;
  }
  .checkmate-experience:not(.mode-intro) & {
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    transform: scale(1);
  }
  /*
    .settingPostcode & {
        opacity: 0;
        visibility: hidden;
        filter: blur(3px);
    }
    */
}

.benefits {
  margin-top: 2rem;
  position: fixed;
  top: 1660px;
  left: 0;
  right: 0;
  text-align: center;
  transition: all 0.8s;
  .icon-btn {
    margin: 0 1.5em;
    font-size: 1rem;
  }
  .checkmate-experience:not(.mode-intro) & {
    top: 1550px;
    .icon-btn {
      font-size: 1.25rem;
    }
  }
}
.checkmate-experience.settingPostcode {
  .actions {
    top: 1810px;
    //opacity: 0;
    //visibility: hidden;
    //filter: blur(3px);
  }
  .benefits {
    opacity: 0;
    visibility: hidden;
    filter: blur(3px);
    .icon-btn {
      margin: 0 1.5em;
      font-size: 1rem;
    }
  }
}
.checkmate-experience.noSearchResults.settingPostcode {
  .benefits {
    top: 1100px;
    opacity: 1;
    visibility: visible;
    filter: blur(0);
    .icon-btn {
      margin: 0 1.5em;
      font-size: 1.25rem;
    }
  }
}
.benefits-content {
  $side_padding: 40px;
  background: rgba(white, 0.9);
  border-radius: 3px 3px 0 0;
  position: fixed;
  top: 300px;
  width: 900px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 50px $side_padding 60px;
  .mode-explore & {
    padding: 50px $side_padding;
  }
  text-align: center;
  border-bottom: 8px solid transparent;
  transition: all 0.6s;
  &.hasImages {
    padding-bottom: 0;
  }
  @keyframes tagEntry {
    from {
      transform: translate(-50%, -50%) rotate(0deg) scale(0);
    }
    60% {
      transform: translate(-50%, -50%) rotate(-4deg) scale(1.2);
    }
    to {
      transform: translate(-50%, -50%) rotate(-8deg);
    }
  }
  .tag {
    position: absolute;
    display: inline-block;
    border-radius: 4px;
    background: $orange;
    font-size: 1.75rem;
    text-transform: uppercase;
    padding: 0.45em 0.6em 0.2em;
    color: $navy;
    font-weight: 700;
    top: 4rem;
    left: 8rem;
    transform: translate(-50%, -50%) rotate(-8deg) scale(0);
    animation: tagEntry forwards 0.6s 0.4s ease-in;
  }
  .benefits-content-images {
    display: flex;
    margin-top: 3rem;
    margin-left: -$side_padding;
    margin-right: -$side_padding;
    width: calc(100% + #{$side_padding} * 2);
  }
  .benefits-content-image {
    height: 165px;
    display: block;
    width: 100%;
    background-size: cover;
  }
  .benefits-content-content {
    margin: 1rem auto 0rem;
    display: flex;
    .mode-benefits & {
      margin: 1rem auto 0.5rem;
      max-width: 600px;
    }
    .benefit-icon {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      margin: 1rem auto 2rem;
      border: 9px solid $orange;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 3rem;
        width: 3rem;
        fill: $blue;
      }
      .icon-Seat {
        height: 2.25rem;
      }
    }
    .benefit-heading {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 0.5em;
      font-size: 1.35rem;
    }
    .benefit-column {
      flex-basis: 100%;
      padding: 0 1rem;
      min-width: 25%;
    }
    /deep/ p {
      font-size: 1.5rem;
      line-height: 1.5;
      .mode-benefits & {
        line-height: 1.5;
        font-size: 1.5rem;
      }
    }
  }
  .benefits-content-description {
    font-size: 1.35rem;
    line-height: 1.5;
    font-size: 1.5rem;
    max-width: 640px;
    margin: 1.5rem auto;
  }
  .benefits-content-border {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    min-height: 9px;
    padding: 10px 0 8px;
    color: white;
    .station-line {
      font-weight: 700;
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 2.75rem;
      transform: translateY(100%);
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        fill: white;
        font-size: 1.25rem;
      }
    }
  }
  &.purple {
    border-bottom-color: $purple;
  }
  &.red {
    border-bottom-color: $red;
  }
  &.blue {
    border-bottom-color: $blue;
  }
  &.yellow {
    border-bottom-color: $yellow;
  }
  &.teal {
    border-bottom-color: $teal;
  }
  &.green {
    border-bottom-color: $green;
  }
  &-title {
    color: $blue;
    font-weight: 700;
  }
  @include enter {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
  @include leave {
    opacity: 0;
    transform: scale(0.8);
    filter: blur(3px);
  }
  .tip {
    position: absolute;
    font-size: 1rem;
    color: black;
    text-transform: uppercase;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 700;
    bottom: 30px;
  }
}
</style>
