<template>
  <div
    :class="['past', { zooming, hasZoomIndex: zoomedIndex !== null }]"
    :style="{ marginRight }"
  >
    <div ref="scrollContainer" class="past-content" @scroll="onScroll">
      <div class="intro">
        <div class="content-card">
          <slot></slot>
        </div>
      </div>
      <div class="timeline-years">
        <div
          v-for="(item, i) in timelineContent"
          :key="item.year + i"
          :class="['timeline-year', { issues: isMissingData(item) }]"
        >
          <PastLine
            class="past-line"
            v-bind="{ seenLines }"
            v-if="[0, 1, 2, 3, 5, 6, 7].includes((i % 8) + 1)"
            ref="line"
            :seen="false"
            :stroke="colorByIndex(i)"
            :lineStyle="(i % 8) + 1"
          />
          <div
            :class="['timeline-year__content', 'content-' + i]"
            :style="`animation-delay: ${i * 0.15 + 0.8}s`"
          >
            <div v-if="i % 2 !== 0" class="year">
              <span
                :style="{ borderColor: colorByIndex(i) }"
                class="item-circle"
              ></span
              ><span v-html="formatYear(item.year)"></span
              ><span class="zoom" @click.prevent="zoom(i)"
                ><Icon class="icon" icon="zoom-in"
              /></span>
            </div>
            <div
              @click.prevent="zoom(i)"
              class="image"
              ref="image"
              :style="imageStyle(item, i)"
            ></div>
            <div v-if="i % 2 === 0" class="year">
              <span
                :style="{ borderColor: colorByIndex(i) }"
                class="item-circle"
              ></span
              ><span v-html="formatYear(item.year)"></span
              ><span class="zoom" @click.prevent="zoom(i)"
                ><Icon class="icon" icon="zoom-in"
              /></span>
            </div>
            <div class="caption" v-html="item.caption"></div>
          </div>
        </div>
      </div>
      <portal to="modals">
        <div :class="[{ zooming }]">
          <div @click.prevent="closeZoom" class="zoom-overlay"></div>
          <div
            @click.prevent="onZoomImageClick"
            class="zoom-image"
            :style="zoomImageStyle"
          >
            <CMSImage v-if="zoomImageSrc" :image="zoomImageSrc" :width="1400" />
            <div class="image-description">
              <div
                v-if="zoomImageSrc"
                class="image-caption"
                v-html="timelineContent[zoomedIndex].caption"
              ></div>
              <div
                v-if="zoomImageSrc"
                class="image-source"
                v-html="
                  'Source: ' + timelineContent[zoomedIndex].image.attribution
                "
              ></div>
            </div>
          </div>
        </div>
      </portal>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import PastLine from "@/components/PastLine";
import CMSImage, { getImageSrc } from "@/components/CMSImage";

let colorMap = {
  blue: "#1578BE",
  yellow: "#FFC425",
  purple: "#A54399",
  orange: "#F68926",
  teal: "#1B8A5D"
};
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
function getColors(colorMap) {
  let shuffled = shuffle(Object.entries(colorMap));
  return [
    shuffled[0][1],
    shuffled[1][1],
    shuffled[2][1],
    shuffled[2][1],
    shuffled[1][1],
    shuffled[3][1],
    shuffled[4][1],
    shuffled[4][1]
  ];
}
export default {
  props: ["timelineContent"],
  data() {
    return {
      negativeMarginMap: [
        0,
        -1260,
        -630,
        3,
        4,
        -1260 // 5
      ],
      colors: getColors(colorMap),
      zooming: false,
      zoomedIndex: null,
      seenLines: [],
      zoomImageStyle: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "",
        position: "fixed",
        top: 0,
        left: 0,
        height: 0,
        width: 0,
        zIndex: 100000
      }
    };
  },
  computed: {
    zoomImageSrc() {
      return this.zoomedIndex !== null
        ? this.timelineContent[this.zoomedIndex].image
        : false;
    },
    marginRight() {
      return (
        this.negativeMarginMap[((this.timelineContent.length - 1) % 8) + 1] +
        "px"
      );
    }
  },
  methods: {
    formatYear(year) {
      return year.replace(/s/, '<span class="s">s</span>');
    },
    imageStyle(item, i) {
      return {
        backgroundImage: `url("${getImageSrc(item.image, { width: 480 })}")`,
        opacity: i === this.zoomedIndex ? 0 : 1,
        backgroundPosition: item.bgpos ? item.bgpos : "50%"
      };
    },
    isMissingData(item) {
      const year = item.year;
      const caption = item.caption;
      const image = item.image.url;
      const attribution = item.image.attribution;
      if (year && caption && image && attribution) return false;
      return true;
    },
    colorByIndex(i) {
      let entries = Object.entries(this.colors);
      return entries[i % entries.length][1];
    },
    zoom(i) {
      if (!this.$refs.image[i]) {
        return false;
      }
      this.zoomedIndex = i;
      this.zooming = true;
      let { height, width, top, left } = this.$refs.image[
        i
      ].getBoundingClientRect();
      this.zoomImageStyle.height = height + "px";
      this.zoomImageStyle.width = width + "px";
      this.zoomImageStyle.top = top + "px";
      this.zoomImageStyle.left = left + "px";
      this.zoomImageStyle.transform = "translate(0, 0)";
      this.zoomImageStyle.visibility = "visible";
      this.zoomImageStyle.opacity = 0;
      setTimeout(() => {
        this.zoomImageStyle.opacity = 1;
        this.zoomImageStyle.transition =
          "width 0.6s, height 0.6s, top 0.6s, left 0.6s, transform 0.6s, opacity 0.6s";
        this.zoomImageStyle.top = "50%";
        this.zoomImageStyle.left = "50%";
        this.zoomImageStyle.transform = this.timelineContent[i].caption
          ? "translate(-50%, calc(-50% - 3rem))"
          : "translate(-50%, -50%)";
        this.zoomImageStyle.width = "1400px";
        this.zoomImageStyle.height = "600px";
      }, 100);
    },
    onZoomImageClick(e) {
      if (e.target.classList.contains("zoom-image")) {
        this.closeZoom();
      }
    },
    closeZoom() {
      if (!this.$refs.image[this.zoomedIndex]) {
        return false;
      }
      let i = this.zoomedIndex;
      let { height, width, top, left } = this.$refs.image[
        i
      ].getBoundingClientRect();
      this.zoomImageStyle.height = height + "px";
      this.zoomImageStyle.width = width + "px";
      this.zoomImageStyle.top = top + "px";
      this.zoomImageStyle.left = left + "px";
      this.zoomImageStyle.transform = "translate(0, 0)";
      this.zoomImageStyle.opacity = 0;
      this.zooming = false;
      this.zoomedIndex = null;
      setTimeout(() => {
        this.zoomImageStyle.visibility = "hidden";
        this.zoomImageStyle.transition = "none";
      }, 600);
    },
    onScroll(e) {
      let left = e ? e.target.scrollLeft : 0;
      let windowWidth = window.innerWidth;
      this.lineOffsets = this.lineOffsets.reduce((acc, line) => {
        if (line[1] < left + windowWidth) {
          this.seenLines.push(line[0]);
        } else {
          acc.push(line);
        }
        return acc;
      }, []);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.lineOffsets = this.$refs.line.map(n => [
        n._uid,
        n.$el.getBoundingClientRect().left
      ]);
      this.onScroll();
    });
  },
  components: {
    Icon,
    PastLine,
    CMSImage
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/transitions";
.past {
  &,
  * {
    touch-action: manipulation;
  }
}
.past-content {
  overflow-y: hidden;
  overflow-x: scroll;
  height: calc(100% + 20px);
  display: flex;
  touch-action: manipulation;

  .intro {
    padding: 160px 0 200px 90px;
    display: flex;
    align-items: center;
    opacity: 0;
    animation: fadein 0.5s 0.5s forwards;
    z-index: 1;
    .content-card {
      background: white;
      box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.21);
      padding: 70px 3rem;
      height: 716px;
      width: 680px;
    }
  }
  .hasZoomIndex & {
    pointer-events: none;
  }
}
.zoom-overlay {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0;
  z-index: 1000;
  .zooming & {
    visibility: visible;
    background: $overlay;
    opacity: 1;
  }
}
.zoom-image {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .zooming & {
    pointer-events: initial;
  }
  img,
  .image-wrapper {
    max-height: 100%;
    max-width: 100%;
    display: inline-block;
    height: auto;
    width: auto;
  }
  img {
    min-height: 100%;
    margin: auto;
    .zooming & {
      transition: all 0.3s 0.4s;
    }
  }
  .image-wrapper {
    position: relative;
  }
  .image-description {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    display: block;
    .zooming & {
      transition: all 0.3s 0.9s;
    }
  }
  img,
  .image-description {
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
    .zooming & {
      visibility: visible;
      opacity: 1;
    }
  }
  .image-caption {
    color: white;
    font-size: 18px;
    margin-top: 2rem;
    width: 720px;
    text-align: center;
    line-height: 1.5;
  }
  .image-source {
    font-size: 11px;
    color: white;
    line-height: 1.5;
    opacity: 0.7;
    max-width: 66%;
    margin: 1rem auto 0;
  }
}
.image {
  height: 350px;
  width: auto;
  max-width: 100%;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.21);
  transition: all 0.3s;
  background-color: white;
}
.timeline-years {
  $bottom_padding: 20px;
  height: calc(100% + #{$bottom_padding});
  padding-bottom: $bottom_padding;
  display: flex;
  height: 100vh;
  padding-left: 60px;
}
.timeline-year {
  $timeline_year_width: 480px;
  min-width: $timeline_year_width;
  max-width: $timeline_year_width;
  margin: 0 56px 0 92px;
  padding: 160px 0 0;
  height: 100%;
  display: flex;
  white-space: initial;
  position: relative;
  &:nth-child(odd) {
    padding-top: 360px;
  }
  &__content {
    position: relative;
    width: 100%;
    opacity: 0;
    animation: fadein 0.5s 1s forwards;
  }
  &.issues {
    .image {
      box-shadow: 0 2px 12px rgba(red, 1);
    }
  }
}
.year {
  color: $charcoal;
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 700;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  span {
    font-weight: 700;
  }
  .image + & {
    margin-top: 20px;
  }
  .icon {
    fill: $charcoal;
    font-size: 0.5em;
  }
  ::v-deep .s {
    font-size: 0.6em;
    font-weight: bold;
  }
}
.caption {
  margin-top: 20px;
}
.item-circle {
  height: 2rem;
  width: 2rem;
  background: white;
  border-radius: 50%;
  border: 6px black solid;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(-1rem, -50%);
}
.caption {
  font-size: 24px;
  color: $charcoal;
  line-height: 1.5;
}
.zoom {
  padding-left: 0.5em;
  z-index: 10;
}
.past {
  background: $offwhite;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
