<template>
  <div :class="['video-popup', { paused: !playing, playing }]">
    <div class="overlay" @click.prevent="onOverlayClick">
      <div class="container">
        <div class="bg"></div>
        <video ref="video" :src="data.src" autoplay mute loop></video>
        <div class="video-control close" @click.prevent="$emit('close')">
          <Icon :icon="'close'" /><span>Close</span>
        </div>
        <div class="controls">
          <div class="video-control toggle" @click.prevent="toggle">
            <Icon :icon="playing ? 'pause' : 'play'" /><span>{{
              playing ? "Pause" : "Play"
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "VideoOverlay",
  props: ["data"],
  data() {
    return {
      playing: false
    };
  },
  methods: {
    init() {
      if (this.$refs.video) {
        this.$refs.video.addEventListener("play", () => (this.playing = true));
        this.$refs.video.addEventListener(
          "pause",
          () => (this.playing = false)
        );
        this.playing = !this.$refs.video.paused;
        this.$refs.video.addEventListener("canplay", () => {
          this.$refs.video.play();
        });
      } else {
        setTimeout(this.init, 100);
      }
    },
    onOverlayClick(e) {
      if (e.target.classList.contains("overlay")) {
        this.$emit("close");
      }
    },
    restart() {
      if (!this.$refs.video) {
        return false;
      }
      this.$refs.video.currentTime = 0;
      this.$refs.video.play();
    },
    toggle() {
      if (!this.$refs.video) {
        return false;
      }
      if (this.$refs.video.paused) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    }
  },
  mounted() {
    this.init();
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

$borderradius: 0.25rem;
$controlsize: 2.85em;

.video-popup {
  position: absolute;
  inset: 0;
  margin-top: -2%;

  .controls {
    position: absolute;
    bottom: -12%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 1;

    .video-control {
      margin: 0 0.5rem;
    }
  }

  .container {
    max-width: 80vw;
    max-height: 80vh;
    width: 100%;
    height: auto;
    position: relative;
  }

  .bg {
    position: absolute;
    inset: 0;
    border-radius: $borderradius;
    background: black;
  }

  .video-control {
    height: $controlsize;
    width: $controlsize * 2.65;
    background: transparentize($blue, 0.2);
    border-radius: $controlsize;
    box-shadow: 0.5rem 0.5rem 3rem rgba(0, 0, 0, 0.3);
    display: inline-flex;
    color: white;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    span {
      font-weight: bold;
      display: inline-block;
      margin-top: 0.2em;
    }
  }

  .icon {
    fill: $green;
    height: 28px;
    width: 30px;
    margin-right: 0.2 * $controlsize;
    overflow: visible;
  }

  .close {
    position: absolute;
    right: 3rem;
    top: 3rem;
    .icon {
      fill: white;
      height: 1rem;
      width: 1rem;
    }
  }

  .overlay {
    position: absolute;
    inset: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    animation: fadein 0.3s forwards;
    background: $overlay;
  }

  video {
    width: 100%;
    height: auto;
    border-radius: $borderradius;
    position: relative;
    z-index: 1;
    transition: opacity 0.3s;
  }

  &.paused {
    video {
      opacity: 0.5;
    }
  }
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s;
}

.overlay-enter,
.overlay-leave-to {
  opacity: 0;
}
</style>
