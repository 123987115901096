<template>
  <div class="station-nav">
    <h1 class="station-name">{{ station.name }} Station</h1>
    <router-link
      :to="`/progress/station/${station.slug}`"
      class="button back-button typeface-nav"
      ><Icon icon="home-alt" /> Back to menu</router-link
    >
    <router-link
      v-if="station.timeHop.panorama"
      :to="`/progress/station/${station.slug}/timehop`"
      class="button typeface-nav"
      >Time Hop</router-link
    >
    <router-link
      v-if="station.view360.panorama"
      :to="`/progress/station/${station.slug}/view360`"
      class="button typeface-nav"
      >360 Panorama</router-link
    >
    <router-link
      v-if="station.constructionProgress.slides"
      :to="`/progress/station/${station.slug}/construction`"
      class="button typeface-nav"
      >Construction</router-link
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "@/components/Icon";

export default {
  name: "StationNav",
  props: ["station"],
  data() {
    return {};
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters(["constructionProgress"])
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.station-nav {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
  z-index: 10;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: $nav-height;

  background: linear-gradient(
      180deg,
      rgba($purple, 0) 0%,
      rgba($purple, 0.25) 100%
    ),
    $deep-purple;

  a {
    color: $white;
    font-size: 32px;

    display: flex;
    align-content: center;
    img {
      margin-right: 1rem;
      width: 24px;
      vertical-align: middle;
    }
  }

  .station-name {
    position: absolute;
    right: 60px;
    bottom: ($nav-height + 42);
    @include typeface-header;
    color: $white;
    font-size: 48px;
  }

  .button {
    position: relative;
    height: $nav-height;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      transform: skew(-15deg);
      z-index: -1;
      background: linear-gradient(
          90deg,
          rgba($black, 0.25) 0%,
          rgba($black, 0) 45%
        ),
        linear-gradient(180deg, rgba($purple, 0) 0%, rgba($purple, 0.25) 100%),
        $deep-purple;
    }
  }

  .back-button {
    background: linear-gradient(90deg, $orange 50%, rgba($orange, 0) 51%);
    &::before {
      background: $orange;
    }

    .icon {
      fill: $white;
      height: 24px;
      width: 24px;
      margin-top: -6px;
      margin-right: 12px;
    }
  }

  .typeface-nav {
    color: $white;
    text-align: center;
    font-size: 32px;
    font-style: italic;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
  }
  .router-link-active:not(.back-button) {
    &::before {
      background: linear-gradient(
          90deg,
          rgba($black, 0.25) 0%,
          rgba($black, 0) 45%
        ),
        linear-gradient(180deg, rgba($purple, 0) 0%, rgba($purple, 0.75) 100%),
        $deep-purple;
      border-bottom: 10px solid $orange;
    }
  }
}
</style>
