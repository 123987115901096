<template>
  <div :style="cssVars">
    <div class="stations">
      <header class="header">
        <h1>Stations<br />Taking Shape</h1>
      </header>

      <div class="progress-bar">
        <div class="station-names">
          <button
            type="button"
            :class="['station-name', { active: currentIndex === i }]"
            v-for="(station, i) in stations"
            :key="station.name"
            @click="gotoStation(i)"
          >
            {{ station.name }}
          </button>
        </div>
      </div>

      <div class="image-slider">
        <div ref="swiper" class="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide slide"
              v-for="slide in currentStation.images"
              :key="slide.image.url"
            >
              <CMSImage :image="slide.image" :width="1080" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>

      <div class="progress-controls">
        <button
          @click="prev()"
          type="button"
          :class="['btn', { disabled: currentIndex === 0 }]"
        >
          <Icon icon="chevron-left" /> Prev
        </button>
        <div>{{ currentStation.name }} Station</div>
        <button
          @click="next()"
          type="button"
          :class="['btn', { disabled: currentIndex === stations.length - 1 }]"
        >
          Next <Icon icon="chevron-right" />
        </button>
      </div>

      <div class="station-content">
        <div class="left">
          <div class="overview">
            <h4>Station Overview</h4>
            <div class="typeface-body white">
              <RenderTextArea :text="currentStation.overview" />
            </div>
          </div>
          <router-link to="/taking-shape" class="start-screen-button">
            Return to start screen
          </router-link>
        </div>
        <div class="right">
          <div class="benefits" ref="benefits" @scroll="benefitsScroll">
            <h4>Benefits</h4>
            <RenderTextArea
              class="typeface-body navy"
              :text="currentStation.benefits"
            />
          </div>
          <div class="scroll-bar"><div ref="scrollControl" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CMSImage from "@/components/CMSImage";
import Icon from "@/components/Icon";
import RenderTextArea from "@/components/RenderTextArea.vue";
import Swiper, { Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  data() {
    return {
      currentIndex: 0,
      swiper: null,
      animating: false,
      scrolledToEnd: false
    };
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("taking-shape");
    },
    stations() {
      return this.pageData.stations;
    },
    currentStation: function() {
      return this.stations[this.currentIndex];
    },
    cssVars() {
      return {
        "--width": this.stations.length * 25 + 75 + "vw",
        "--left": 37.5 - this.currentIndex * 25 + "vw",
        "--fade-opacity": this.scrolledToEnd ? 0 : 1
      };
    }
  },
  methods: {
    prev() {
      this.gotoStation(this.currentIndex - 1);
    },
    next() {
      this.gotoStation(this.currentIndex + 1);
    },
    gotoStation(newIndex) {
      if (newIndex >= 0 && newIndex < this.stations.length) {
        this.swiper.slideTo(0, 0, false);
        this.swiper.disable();
        this.currentIndex = newIndex;
        var self = this;
        setTimeout(function() {
          self.onStationChange();
        }, 50);
      }
    },
    onStationChange() {
      this.swiper.enable();
      this.swiper.update();
      this.swiper.slideTo(0, 0, false);
      const benefits = this.$refs.benefits;
      benefits.scrollTo(0, 0);
      this.benefitsScrollHeight();
    },
    benefitsScrollHeight() {
      const benefits = this.$refs.benefits;
      const scrollControl = this.$refs.scrollControl;
      const height = 595 / benefits.scrollHeight;
      scrollControl.style.height = height * 100 + "%";
    },
    benefitsScroll() {
      const benefits = this.$refs.benefits;
      const scrollControl = this.$refs.scrollControl;
      const offset = 555 - scrollControl.clientHeight;
      const top = (offset * benefits.scrollTop) / (benefits.scrollHeight - 595);

      scrollControl.style.top = `${top}px`;
      this.scrolledToEnd = Math.abs(offset - top) < 2;
    }
  },
  components: {
    CMSImage,
    Icon,
    RenderTextArea
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, Pagination],
      centeredSlides: true,
      slidesPerView: 1,
      threshold: 5,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      speed: 500,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      }
    });
    this.benefitsScrollHeight();
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

@mixin typeface-header {
  font-size: 32px;
  font-style: italic;
  font-weight: 900;
  line-height: 0.9;
  letter-spacing: -0.025em;
}

@mixin typeface-body {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

.stations {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c002c;
}

.header {
  position: relative;
  height: 560px;
  padding-left: 50px;
  padding-top: 180px;
  background: url("../../assets/images/taking-shape/stations-header-bg.jpg");
  background-position: center top;
  background-size: cover;

  h1 {
    @include typeface-header;
    text-transform: uppercase;
    font-size: 96px;

    background: linear-gradient(
      90deg,
      rgba(203, 55, 227, 0.5) 0%,
      rgba(247, 148, 42, 0.5) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px $orange;
  }
}

.progress-bar {
  position: absolute;
  left: 0;
  bottom: calc(100vh - 560px);
  width: 100%;
  z-index: 200;
  border-bottom: 20px solid #fff;

  .station-names {
    position: absolute;
    bottom: 0;
    left: var(--left);
    display: flex;
    align-items: center;
    width: var(--width);
    transition: left 0.3s ease-in-out;
    .station-name {
      color: #fff;
      @include typeface-body;
      text-transform: uppercase;
      width: 25vw;
      white-space: nowrap;
      text-align: center;
      margin-bottom: 32px;
      opacity: 0.5;
      background: none;
      border: none;
      transition: opacity 0.3s ease-in-out;
      &.active {
        opacity: 1;
      }
    }
  }

  &::after {
    content: "";
    display: block;
    width: 50vw;
    height: 20px;
    position: absolute;
    left: 0;
    bottom: -20px;
    background: linear-gradient(90deg, #cb37e3 0%, $orange 100%);
  }
  &::before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: calc(50vw - 20px);
    bottom: -30px;
    background: #fff;
    border: 10px solid $orange;
    z-index: 1;
  }
}

.image-slider {
  width: 100%;
  width: 1080px;
  height: 610px;
  background: linear-gradient(
    180deg,
    rgba(#2c002c, 0.35) 0%,
    rgba(#a54399, 0.15) 100%
  );
}

.slide {
  color: #333;
  width: 1080px;

  img {
    pointer-events: none;
    width: 1080px;
    height: 610px;
    object-fit: cover;
  }
}

.progress-controls {
  height: 160px;
  background: linear-gradient(
    180deg,
    rgba(#2c002c, 0.35) 0%,
    rgba(#a54399, 0.35) 100%
  );
  display: flex;
  justify-content: stretch;
  align-items: center;

  div,
  .btn {
    color: #fff;
    @include typeface-header;
    text-transform: uppercase;
    transition: 0.2s;
    .icon {
      fill: #fff;
      margin: 0 8px;
      transition: 0.2s;
    }

    &.disabled {
      color: rgba(#fff, 0);
      .icon {
        fill: rgba(#fff, 0);
      }
    }
  }

  div {
    position: relative;
    height: 160px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
        180deg,
        rgba(#2c002c, 1) 0%,
        rgba(#a54399, 1) 100%
      );
      border-bottom: 15px solid $orange;
      z-index: -1;
      transform: skew(-15deg);
    }
  }

  .btn {
    background: none;
    border: none;
    width: 280px;
  }
}

.station-content {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;

  @include typeface-body;

  h4 {
    @include typeface-header;
    margin-bottom: 16px;
  }

  .left,
  .right {
    width: 50%;
    height: 595px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .overview,
  .benefits {
    flex-grow: 1;
    padding: 60px 48px;
  }

  .right {
    &::after {
      content: "";
      pointer-events: none;
      display: block;
      position: absolute;
      height: 200px;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: var(--fade-opacity);
      transition: opacity 0.2s;
      background: linear-gradient(
        180deg,
        rgba($white, 0) 0%,
        rgba($white, 1) 100%
      );
    }
  }

  .benefits {
    position: relative;
    //height: 595px;
    overflow: auto;
    scroll-behavior: smooth;
    &,
    * {
      touch-action: manipulation;
    }
    * {
      pointer-events: none;
    }
  }

  .scroll-bar {
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 16px;
    width: 8px;
    border-radius: 4px;
    background-color: rgba($orange, 0.33);
    z-index: 2;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 80px;
      width: 8px;
      border-radius: 4px;
      background-color: $orange;
      transition: height 0.1s;
    }
  }

  .overview {
    max-width: 450px;
    p {
      @include typeface-body;
    }
  }

  .left {
    color: #fff;
    background: linear-gradient(
      200deg,
      rgba($orange, 0) 0%,
      rgba($orange, 0.15) 80%,
      rgba($orange, 0.1) 100%
    );
  }

  .right {
    position: relative;
    z-index: 1;
    color: #123064;
    background: #fff;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform-origin: left top;
      transform: skew(-15deg);
      background: #fff;
      z-index: -1;
    }
  }

  .typeface-body {
    p {
      @include typeface-body();
      margin-bottom: 16px;
    }
  }
  .typeface-body.white p {
    color: #fff;
  }
  .typeface-body.navy p {
    color: #123064;
  }

  .home-button {
    @include typeface-header;
    color: #fff;
    text-transform: uppercase;
    background: $orange;
    padding: 32px 48px;
  }
}

.icon {
  fill: #fff;
  height: 24px;
  width: 24px;
}

.start-screen-button {
  position: relative;
  max-width: 280px;
  color: #fff;
  font-size: 28px;
  font-family: "MetaPro";
  font-style: italic;
  font-weight: 900;
  padding-left: 124px;
  margin-bottom: 48px;
  margin-left: 48px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -18px;
    left: 0;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 10px solid $orange;
    background-color: $orange;
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.0386 24.1768C42.0386 25.3018 41.1011 26.1768 40.0386 26.1768H38.0386L38.1011 36.1768C38.1011 36.3643 38.1011 36.5518 38.1011 36.6768V37.6768C38.1011 39.1143 36.9761 40.1768 35.6011 40.1768H34.6011C34.4761 40.1768 34.4136 40.1768 34.3511 40.1768C34.2886 40.1768 34.1636 40.1768 34.1011 40.1768H30.6011C29.1636 40.1768 28.1011 39.1143 28.1011 37.6768V32.1768C28.1011 31.1143 27.1636 30.1768 26.1011 30.1768H22.1011C20.9761 30.1768 20.1011 31.1143 20.1011 32.1768V37.6768C20.1011 39.1143 18.9761 40.1768 17.6011 40.1768H14.1011C13.9761 40.1768 13.9136 40.1768 13.7886 40.1768C13.7261 40.1768 13.6636 40.1768 13.6011 40.1768H12.6011C11.1636 40.1768 10.1011 39.1143 10.1011 37.6768V30.6768C10.1011 30.6768 10.1011 30.6143 10.1011 30.5518V26.1768H8.10107C6.97607 26.1768 6.10107 25.3018 6.10107 24.1768C6.10107 23.6143 6.28857 23.1143 6.72607 22.6768L22.7261 8.73926C23.1636 8.30176 23.6636 8.17676 24.1011 8.17676C24.5386 8.17676 25.0386 8.36426 25.4136 8.67676L41.3511 22.6768C41.8511 23.1143 42.1011 23.6143 42.0386 24.1768Z" fill="white"/></svg>'),
      linear-gradient(180deg, rgba(#b80b4d, 0.3) 0%, rgba($orange, 0.3) 100%);
    background-size: 48px 48px, cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>

<style lang="scss">
.typeface-body {
  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 16px;
  }
}
.typeface-body.white p {
  color: #fff;
}
.typeface-body.navy p {
  color: #123064;
}

.swiper-pagination {
  .swiper-pagination-bullet {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: transparent;
    opacity: 1;
    &.swiper-pagination-bullet-active::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      margin: 3px;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
