<template>
  <div>
    <PastTimeline :timelineContent="pageData.past.timeline">
      <PastContent>
        <RenderRichText :content="pageData.past.overviewHTML" />
      </PastContent>
    </PastTimeline>
  </div>
</template>

<script>
import PastContent from "@/components/PastContent";
import PastTimeline from "@/components/PastTimeline";
import RenderRichText from "@/components/RichText/RenderRichText";

const slug = "explore-boggo-road";

export default {
  components: {
    PastContent,
    PastTimeline,
    RenderRichText
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData(slug);
    }
  }
};
</script>
