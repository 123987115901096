import { VideoDome } from "babylonjs";
import { PanoType } from "./settings";

export const addVideoDome = (type, assetURL, scene) => {
  switch (type) {
    case PanoType.VIDEO:
      return new VideoDome(
        "360",
        [assetURL],
        {
          resolution: 32,
          useDirectMapping: false,
          autoPlay: true,
          loop: true
        },
        scene
      );
    case PanoType.IMAGE:
      return new VideoDome("360", [], {
        poster: assetURL,
        resolution: 32,
        useDirectMapping: false,
        autoPlay: true,
        loop: true
      });
  }
};
