<template>
  <div class="benefits-wrapper">
    <transition name="fade">
      <router-view class="router-view"></router-view>
    </transition>
    <BenefitsNav />
  </div>
</template>

<script>
import BenefitsNav from "@/pages/benefits/BenefitsNav";

export default {
  data() {
    return {
      title: "Future Benefits"
    };
  },
  components: {
    BenefitsNav
  }
};
</script>

<style lang="scss" scoped>
.benefits-wrapper {
  background-color: #081d29;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
