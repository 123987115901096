<template>
  <div class="carousel">
    <h2 class="carousel-title">{{ title }}</h2>
    <div v-if="showSwipeControls" class="controls">
      <div class="control control-left">Swipe</div>
      <div class="control control-right">Swipe</div>
    </div>

    <div class="carousel-wrapper">
      <div
        class="carousel-pane"
        :style="{ width }"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
        @mousemove="onDrag"
        @touchmove="onDrag"
        @mouseleave="onDragEnd"
        @mouseup="onDragEnd"
        @touchcancel="onDragEnd"
      >
        <div
          class="carousel-slide"
          v-for="slide in slides"
          :key="'slide-' + slide.id"
          @click.stop="openPopup(slide)"
        >
          <div class="slide-title">
            <span>{{ slide.title }}</span>
          </div>
          <CMSImage
            :image="slide.image"
            class="slide-image"
            :width="384"
          ></CMSImage>
          <!--div class="slide-content">{{ slide.description }}</div-->
          <div class="slide-meta">
            <div class="slide-meta-left">
              <span>{{ slide.date | formatISO("MMM yyyy") }}</span
              ><span v-if="slide.stage"> &middot; {{ slide.stage }}</span>
            </div>
            <Icon class="icon slide-meta-right" icon="zoom-in" />
          </div>
        </div>
      </div>
    </div>
    <ImagePopup
      v-if="showImage"
      @close="showImage = false"
      :src="imageSrc"
      :losrc="imageLowSrc"
    />
  </div>
</template>

<script>
import ImagePopup from "@/components/ImagePopup";
import Icon from "@/components/Icon";
import CMSImage, { getImageSrc } from "@/components/CMSImage";

export default {
  props: {
    title: String,
    slides: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ImagePopup,
    Icon,
    CMSImage
  },
  computed: {
    width() {
      return this.slides.length * 444 + 100 + "px";
    },
    showSwipeControls() {
      return this.slides.length > 4;
    }
  },
  data() {
    return {
      dragData: {
        dragging: false,
        dragged: false,
        el: null,
        xstart: 0,
        xoffsetstart: 0,
        paneWidth: 0
      },
      showImage: false,
      imageLowSrc: false,
      imageSrc: ""
    };
  },
  methods: {
    openPopup(slide) {
      if (this.dragData.dragged) {
        return;
      }
      this.imageSrc = getImageSrc(slide.image, { width: 1320 });
      this.imageLowSrc = getImageSrc(slide.image, { width: 384 });
      this.showImage = true;
    },
    onDragStart: function(event) {
      let paneWidth = isNaN(parseInt(event.currentTarget.style.width))
        ? 0
        : parseInt(event.currentTarget.style.width);
      let pageX = event.touches ? event.touches[0].pageX : event.pageX;
      if (paneWidth < window.innerWidth) {
        return;
      }
      this.dragData.dragging = true;
      this.dragData.dragged = false;
      this.dragData.el = event.currentTarget;
      this.dragData.xstart = pageX;
      this.dragData.xoffsetstart = isNaN(
        parseInt(event.currentTarget.style.left)
      )
        ? 0
        : parseInt(event.currentTarget.style.left);
      this.dragData.paneWidth = paneWidth;
    },
    onDrag: function(event) {
      if (this.dragData.dragging) {
        let pageX = event.touches ? event.touches[0].pageX : event.pageX;
        let newLeft = Math.min(
          0,
          this.dragData.xoffsetstart + pageX - this.dragData.xstart
        );
        newLeft = Math.max(
          newLeft,
          window.innerWidth - this.dragData.paneWidth
        );
        if (Math.abs(pageX - this.dragData.xstart) > 10) {
          this.dragData.dragged = true;
        }
        this.dragData.el.style.left = newLeft + "px";
      }
    },
    onDragEnd: function() {
      this.dragData.dragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/includes/vars";
.carousel {
  position: relative;
}
.carousel-title {
  position: absolute;
  top: 0;
  left: 80px;
  color: $white;
  font-size: 42px;
  padding-bottom: 10px;
  background: url(~@/assets/images/underline.png) left bottom repeat-x;
}
.controls {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  .control {
    position: absolute;
    width: 80px;
    top: 170px;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    padding-top: 100px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 40px;
      width: 60px;
      height: 2px;
      background: $white;
    }
    &::before {
      transform: rotate(-50deg);
    }
    &::after {
      transform: rotate(50deg);
    }
  }
  .control-left {
    left: 0;
    &::before,
    &::after {
      left: 20px;
      transform-origin: 1px center;
    }
  }
  .control-right {
    right: 0;
    &::before,
    &::after {
      right: 20px;
      transform-origin: right center;
    }
  }
}

// Slide-wrapper
.carousel-wrapper {
  padding-top: 70px;
  height: (275px + 76);
  width: 100%;
  overflow: hidden;
}
.carousel-pane {
  position: relative;
  height: 275px;
  padding-left: 80px;
}

// Slide
.carousel-slide {
  position: relative;
  height: 275px;
  //width: 768px;
  width: 384px;
  margin-right: 60px;
  float: left;
}
.slide-title {
  color: $white;
  font-size: 20px;
  height: 2.2lh;
  padding-bottom: 6px;
  display: flex;
  align-items: flex-end;
  span {
    font-weight: bold;
  }
}
.slide-image {
  position: relative;
  width: 384px;
  height: 209px;
  background: $grey center center no-repeat;
  background-size: cover;
}
.slide-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  margin-top: 6px;
  span {
    font-weight: bold;
  }
  .icon {
    fill: $white;
  }
}
</style>
