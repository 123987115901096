<template>
  <div>
    <!-- HTML content -->
    <div class="rich-text" v-html="content" />
    <!-- Overlays -->
    <portal to="richtext">
      <transition name="overlay" mode="out-in">
        <template v-if="openBlock">
          <VideoOverlay
            v-if="openBlock.type === 'upload-video'"
            :key="`upload-video::${openBlock.id}`"
            :data="openBlock.data"
            @close="closeOpenBlock"
          />
          <ZoomOverlay
            v-else-if="openBlock.type === 'reveal-image-button'"
            :key="`reveal-image-button::${openBlock.id}`"
            :data="openBlock.data"
            @close="closeOpenBlock"
          />
          <ImageOverlay
            v-else-if="openBlock.type === 'image-carousel'"
            :key="`image-carousel::${openBlock.id}::${openBlock.slide}`"
            :data="openBlock.data[openBlock.slide]"
            @close="closeOpenBlock"
          />
        </template>
      </transition>
    </portal>
  </div>
</template>

<script>
import ImageOverlay from "./modals/ImageOverlay";
import VideoOverlay from "./modals/VideoOverlay";
import ZoomOverlay from "./modals/ZoomOverlay";
import Swiper, { Navigation } from "swiper";
import Vue from "vue";

const BlockAttr = {
  id: "v-block-id",
  type: "v-block-type"
};

const ScriptAttr = {
  hydrate: "v-hydrate-id"
};

function getHydrateData(id) {
  const el = document.querySelector(`script[${ScriptAttr.hydrate}="${id}"]`);
  return el ? JSON.parse(el.textContent) : null;
}

export default {
  name: "RenderRichText",
  props: {
    content: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      blocks: {},
      openBlockId: null
    };
  },
  computed: {
    openBlock() {
      const id = this.openBlockId;
      return id ? this.blocks[id] : null;
    }
  },
  methods: {
    setOpenBlock(id) {
      this.openBlockId = id;
    },
    closeOpenBlock() {
      this.openBlockId = null;
    }
  },
  mounted() {
    // Hydrate blocks
    const blocks = this.$el.querySelectorAll(`[${BlockAttr.id}]`);
    for (const el of blocks) {
      // Get attributes and data
      const id = el.getAttribute(BlockAttr.id);
      const type = el.getAttribute(BlockAttr.type);
      const data = getHydrateData(id);

      // Create block object
      const block = { el, id, type, data, unmount: null };
      Vue.set(this.blocks, id, block);

      const openOverlay = () => {
        this.setOpenBlock(id);
      };

      // Block init actions (event listeners etc.)
      switch (type) {
        case "upload-video": {
          el.addEventListener("click", openOverlay);
          block.unmount = () => {
            el.removeEventListener("click", openOverlay);
          };
          break;
        }
        case "reveal-image-button": {
          el.addEventListener("click", openOverlay);
          block.unmount = () => {
            el.removeEventListener("click", openOverlay);
          };
          break;
        }
        case "image-carousel": {
          const select = selector => `[v-block-id="${id}"] ${selector}`;
          const swiper = new Swiper(select(".swiper"), {
            modules: [Navigation],
            loop: true,
            passiveListeners: false,
            navigation: {
              prevEl: select(".nav-button.prev"),
              nextEl: select(".nav-button.next")
            }
          });

          swiper.on("click", (swiper, event) => {
            event.preventDefault();
            event.stopPropagation();
            Vue.set(this.blocks[id], "slide", swiper.realIndex);
            openOverlay();
          });

          block.unmount = () => {
            swiper.destroy();
          };
          break;
        }
        default:
          // Do nothing
          break;
      }
    }
  },
  beforeUnmount() {
    // Clean up block side effects
    for (const block of this.blocks) {
      if (typeof block.unmount === "function") {
        block.unmount();
      }
    }
  },
  components: {
    ImageOverlay,
    VideoOverlay,
    ZoomOverlay
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

@mixin block-padding {
  &:not(:first-child) {
    margin-top: 2em;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
  }
}

.rich-text ::v-deep {
  > *:not(:last-child) {
    margin-bottom: 1em;
  }

  strong {
    font-weight: bold;
  }

  > [v-block-type="blockquote"] {
    padding-left: 2rem;
    border-left: 3px $green solid;
    margin-top: 2rem;

    h2 {
      font-size: 26px;
      line-height: 1.2;
      font-weight: bold;
      margin-bottom: 0.5em;
      color: $blue;
    }

    p {
      font-weight: bold;
      color: $grey;
      font-size: 18px;
      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }

  > [v-block-type="upload-image"] {
    @include block-padding;
    width: 100%;
  }

  > [v-block-type="upload-video"] {
    @include block-padding;
    position: relative;
    display: flex;

    video {
      width: 100%;
    }

    .video-control {
      position: absolute;
      inset: 0;
      color: #fff;
      background: rgba($charcoal, 0.75);
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        fill: #fff;
        width: 30px;
        height: 30px;
        margin-right: 8px;
      }

      span {
        font-size: 20px;
        font-weight: bold;
        text-shadow: 1px 1px 2px $charcoal;
      }
    }
  }

  > [v-block-type="reveal-image-button"] {
    display: inline-block;
    padding: 0.7em 1.5em 0.5em;
    font-size: 22px;
    appearance: none;
    border: 0;
    outline: 0;
    border-radius: 2em;
    background: $green;
    transition: all 0.2s;

    > span {
      color: $blue;
      font-weight: 700;
    }

    .icon {
      height: 1.25em;
      width: 1.25em;
      vertical-align: middle;
      margin-right: 0.5em;
      margin-bottom: 0.1em;
      fill: currentColor;
    }
  }

  > [v-block-type="image-carousel"] {
    @include block-padding;
    position: relative;
    width: 100%;

    .swiper {
      width: 100%;
    }

    .swiper-slide {
      display: flex;
      position: relative;

      img {
        width: 100%;
        margin: 0;
      }

      h5 {
        font-size: 28px;
        color: $white;
        position: absolute;
        left: 20px;
        bottom: 20px;
        font-weight: 700;
      }
    }

    .nav {
      pointer-events: none;
      display: flex;
      position: absolute;
      inset: 0;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      margin-inline: -18px;
    }

    .nav-button {
      $size: 36px;
      $hit-slop: 12px;

      pointer-events: auto;
      cursor: pointer;
      border: 0;
      background: none;
      padding: 0;
      width: $size;
      height: $size;
      text-decoration: none;
      user-select: none;
      box-sizing: content-box;
      border-radius: 50%;
      background-color: $orange;
      background-clip: content-box;
      -webkit-tap-highlight-color: transparent;

      // Hit slop
      margin: -$hit-slop;
      padding: $hit-slop;

      .icon {
        width: 100%;
        height: 100%;
        padding: 8px;
        fill: $white;
      }

      &.prev .icon {
        margin-left: -2px;
      }
      &.next .icon {
        margin-left: 2px;
      }
    }
  }
}
</style>
