import store from "@/store";

import Index from "@/pages/woolloongabba/Index";
import Home from "@/pages/woolloongabba/Home";
import Introduction from "@/pages/woolloongabba/Introduction";
import Origin from "@/pages/woolloongabba/Origin";
import Past from "@/pages/woolloongabba/Past";
import Progress from "@/pages/woolloongabba/Progress";
import Future from "@/pages/woolloongabba/Future";

export default {
  path: "/woolloongabba",
  component: Index,
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "explore-woolloongabba").then(next);
  },
  meta: {
    label: "Woolloongabba",
    group: "Stations"
  },
  children: [
    {
      path: "",
      name: "Woolloongabba Home",
      component: Home,
      meta: {
        label: "Home",
        hide: true
      }
    },
    {
      path: "intro",
      name: "Woolloongabba Introduction",
      component: Introduction,
      meta: {
        label: "Introduction",
        showSidebar: true
      }
    },
    {
      path: "origin",
      name: "Woolloongabba Origin",
      component: Origin,
      meta: {
        child: true,
        label: "Origin"
      }
    },
    {
      path: "past",
      name: "Woolloongabba Past",
      component: Past,
      meta: {
        child: true,
        label: "Past"
      }
    },
    {
      path: "progress",
      name: "Woolloongabba Progress",
      component: Progress,
      meta: {
        child: true,
        label: "Progress"
      }
    },
    {
      path: "future",
      name: "Woolloongabba Future",
      component: Future,
      meta: {
        child: true,
        label: "Future"
      }
    }
  ]
};
