import Index from "@/pages/stations/Index";

import Home from "@/pages/stations/Home";
import Architecture from "@/pages/stations/Architecture";
import Upgrades from "@/pages/stations/Upgrades";
import GoldCoast from "@/pages/stations/GoldCoast";
import store from "@/store";

export default {
  path: "/stations",
  component: Index,
  meta: {
    label: "Stations",
    group: "Progress"
  },
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "stations-home").then(next);
  },
  children: [
    {
      path: "",
      name: "Stations Home",
      component: Home,
      meta: {
        label: "Home",
        showSidebar: true
      }
    },
    {
      path: "architecture",
      name: "Architecture",
      component: Architecture,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "stations-architecture").then(next);
      }
    },
    {
      path: "upgrades",
      name: "Upgrades",
      component: Upgrades,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "stations-upgrades").then(next);
      }
    },
    {
      path: "gold-coast",
      name: "Gold Coast",
      component: GoldCoast,
      meta: {
        child: true
      },
      beforeEnter(to, from, next) {
        store.dispatch("loadPage", "stations-gold-coast").then(next);
      }
    }
  ]
};
