<template>
  <portal to="modals">
    <div class="image-popup">
      <div class="overlay" @click.prevent="onOverlayClick">
        <div
          class="container"
          :style="{ width: imgwidth + 'px', height: imgheight + 'px' }"
        >
          <div class="bg"></div>
          <img class="imglo" :src="losrc" />
          <img class="imghi" :src="src" />
          <div class="video-control close" @click.prevent="$emit('close')">
            <Icon :icon="'close'" /><span>Close</span>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  props: ["src", "losrc"],
  data() {
    return {
      imgW: 0,
      imgH: 0,
      screenW: 1920 - 300,
      screenH: 1080 - 200
    };
  },
  computed: {
    imgRatio() {
      return this.imgW / this.imgH;
    },
    scrRatio() {
      return this.screenW / this.screenH;
    },
    imgwidth() {
      return this.imgRatio > this.scrRatio
        ? this.screenW
        : this.screenH * this.imgRatio;
    },
    imgheight() {
      return this.imgRatio < this.scrRatio
        ? this.screenH
        : this.screenW * this.imgRatio;
    }
  },
  methods: {
    onOverlayClick(e) {
      if (e.target.classList.contains("overlay")) {
        this.$emit("close");
      }
    }
  },
  components: {
    Icon
  },
  mounted() {
    // Lo Img should already be loaded
    let loimg = new Image();
    loimg.src = this.losrc;
    this.imgW = loimg.width;
    this.imgH = loimg.height;
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
$borderradius: 0.25rem;
$controlsize: 2.85em;

.image-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .container {
    position: relative;
    max-width: calc(100vw - 300px);
    max-height: calc(100vh - 200px);
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $borderradius;
    background: black;
  }
  .video-control {
    height: $controlsize;
    width: $controlsize * 2.65;
    background: transparentize($blue, 0.2);
    border-radius: $controlsize;
    box-shadow: 0.5rem 0.5rem 3rem rgba(0, 0, 0, 0.3);
    display: inline-flex;
    color: white;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
    span {
      font-weight: bold;
      display: inline-block;
      margin-top: 0.2em;
    }
  }
  .icon {
    fill: $green;
    height: 28px;
    width: 30px;
    margin-right: 0.2 * $controlsize;
    overflow: visible;
  }
  .close {
    position: absolute;
    right: 3rem;
    top: 3rem;
    z-index: 3;
    .icon {
      fill: white;
      height: 1rem;
      width: 1rem;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    animation: fadein 0.3s forwards;
    background: $overlay;
  }
  .imghi,
  .imglo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    border-radius: $borderradius;
    transition: all 0.3s;
  }
  .imghi {
    z-index: 2;
  }
  .imglo {
    z-index: 1;
  }
}
</style>
