import Vue from "vue";
import Router from "vue-router";

import Index from "@/pages/Index";
import Checkmate from "@/pages/checkmate/Index";
import Game from "@/pages/game/Index";
import Art from "@/pages/art/Index";

import Albert from "./albert";
import Boggo from "./boggo";
import Exhibition from "./exhibition";
import Roma from "./roma";
import Woolloongabba from "./woolloongabba";
import Tunnel from "./tunnel";
import Stations from "./stations";
import Benefits from "./benefits";

import TakingShape from "./taking-shape";
import Progress from "./progress";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      name: "Index",
      component: Index
    },
    {
      path: "/checkmate",
      name: "My Future Journey",
      component: Checkmate,
      meta: {
        label: "My Future Journey",
        group: "Benefits"
      }
    },
    {
      path: "/game",
      name: "Bye Bye Transit Centre",
      component: Game,
      meta: {
        label: "Bye Bye Transit Centre",
        args: "kiosk",
        group: "Embedded"
      }
    },
    {
      path: "/art",
      name: "Station Art",
      component: Art,
      meta: {
        label: "Station Art",
        group: "Embedded"
      }
    },
    Albert,
    Boggo,
    Exhibition,
    Roma,
    Woolloongabba,
    Tunnel,
    Stations,
    Benefits,
    TakingShape,
    Progress
  ]
});

export default router;
