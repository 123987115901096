import { Vector3, Color3 } from "babylonjs";

export const green = new Color3(0.5, 0.8, 0.25);
export const domeEdge = new Vector3(0, 0, 50);
export const closePlaneSize = 40;
export const closePlaneTextureSize = 400;
export const hotspotSize = 64;
export const hotspotConnectorWidth = 3;
export const hotspotConnectorHeight = 40 + hotspotSize / 2;

export const PanoType = {
  VIDEO: "VIDEO",
  IMAGE: "IMAGE"
};

export const Elevation = {
  AERIAL: {
    label: "Aerial",
    icon: "high-elevation"
  },
  ELEVATED: {
    label: "Station-to-Stadium Connection",
    icon: "low-elevation"
  },
  PRECINCT: {
    label: "Precinct",
    icon: "person"
  },
  STATION: {
    label: "Station Entrance",
    icon: "station"
  },
  CONCOURSE: {
    label: "Concourse",
    icon: "concourse"
  },
  PLATFORM: {
    label: "Platform Level",
    icon: "platform"
  }
};
