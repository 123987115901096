<template>
  <div class="elevation-tab" :style="style">
    <Icon class="icon" :icon="icon" />
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "elevation-tab",
  props: {
    label: String,
    icon: String
  },
  data() {
    return {
      left: 0
    };
  },
  computed: {
    style() {
      return {
        left: this.left + "px"
      };
    }
  },
  mounted() {
    // Find title tab width
    const title = document.querySelector(".screen-title-tab");
    if (title) this.left = title.getBoundingClientRect().width;
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";

.elevation-tab {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 1.5rem;
  margin-left: 1px;
  background-color: white;

  .icon {
    width: 45px;
    height: 45px;
    margin-right: 1rem;
  }

  .label {
    color: black;
    text-transform: uppercase;
  }
}
</style>
