<template>
  <img :src="src" :alt="image.alt" />
</template>

<script>
const api = process.env.VUE_APP_API;
const sizes = [64, 240, 480, 768, 1080, 1440, 1920];

function getNearestSize(width) {
  // Find first size in sizes array that is greater than width prop
  for (const size of sizes) {
    if (size >= width) return size;
  }
  // If width is too large, return largest available size
  return sizes[sizes.length - 1];
}

export function getImageSrc(image, { width = 1920, quality = 75 }) {
  const url = image.url;
  const w = getNearestSize(width);
  const q = quality;
  return `${api}/_next/image?url=${url}&q=${q}&w=${w}`;
}

export default {
  name: "CMSImage",
  props: {
    image: {
      type: Object,
      required: true,
      validator(value) {
        return value.url !== undefined && value.alt !== undefined;
      }
    },
    width: {
      type: Number,
      default: 1920
    },
    quality: {
      type: Number,
      default: 75
    }
  },
  computed: {
    src() {
      return getImageSrc(this.image, this);
    }
  }
};
</script>
