<template>
  <div class="bg-image">
    <CMSImage :image="image" :width="width || 1920" />
    <div v-if="attribution" class="image-credit">{{ attribution }}</div>
  </div>
</template>

<script>
import CMSImage from "@/components/CMSImage";
export default {
  name: "BgImage",
  props: {
    image: Object,
    width: Number
  },
  computed: {
    attribution() {
      return this.image.attribution;
    }
  },
  components: { CMSImage }
};
</script>

<style lang="scss" scoped>
.bg-image {
  position: absolute;
  inset: 0;
  ::v-deep img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
