<template>
  <div>
    <div class="progress" :style="`background-image:url(${bgimg});`">
      <div class="carousel carousel-surface">
        <ProgressCarousel
          title="Surface work"
          :slides="pageData.progress.surfaceWork"
        ></ProgressCarousel>
      </div>
      <div class="carousel carousel-underground">
        <ProgressCarousel
          title="Underground work"
          :slides="pageData.progress.undergroundWork"
        ></ProgressCarousel>
      </div>
    </div>
    <div class="title-wrapper">
      <h1 class="title">Construction Progress</h1>
    </div>
  </div>
</template>

<script>
import ProgressCarousel from "@/components/ProgressCarousel";
import bgimg from "@/assets/images/roma/progress-bg.png";

const slug = "explore-roma-street";

export default {
  data() {
    return {
      bgimg,
      constructionOpen: false
    };
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData(slug);
    }
  },
  components: {
    ProgressCarousel
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/includes/vars";

.progress {
  background: $offwhite center center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    pointer-events: none;
  }
  &::before {
    background: rgba(0, 0, 0, 0.33);
  }
  &::after {
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.33) 0%,
      rgba(0, 0, 0, 0) (100% * 200 / 1080),
      rgba(0, 0, 0, 0) (100% - (100 * 200 / 1080)),
      rgba(0, 0, 0, 0.33) 100%
    );
  }
}
.carousel {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 3;
  &.carousel-surface {
    top: 132px;
  }
  &.carousel-underground {
    top: 573px;
  }
}

.title-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 20px;
  right: 0;
  left: 650px;
  height: 110px;
  padding: 0 36px;
  background: rgba($white, 0);
  transition: 0.3s;

  &.white-bg {
    background: rgba($white, 0.8);
  }

  .title {
    color: $orange;
    font-size: 52px;
  }
  .btn {
    margin-bottom: 0;
    .icon {
      width: 1em;
      height: 1em;
    }
  }
}
</style>
