<template>
  <div>
    <div class="sidebar">
      <RenderRichText
        class="instruction"
        :content="pageData.sidebar.introHTML"
      />

      <transition mode="out-in">
        <RenderRichText
          class="hotspot-content"
          v-if="hotspot"
          :key="`hotspot-content-${activeHotspot}`"
          :content="hotspot.contentHTML"
        />
      </transition>
    </div>

    <div class="content-container">
      <div
        class="the-content white mode-explore"
        :style="`background-image:url(${mapimg});`"
      >
        <h2>{{ pageData.title }}</h2>
        <RenderTextArea class="intro narrow white" :text="pageData.overview" />
        <div
          v-for="(hotspot, i) in hotspots"
          :key="hotspot.id"
          :class="['location-marker', 'new', { active: activeHotspot === i }]"
          :style="`top:${hotspot.top}px; left:${hotspot.left}px;`"
          @click.prevent="onHotspot(i)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import RenderRichText from "@/components/RichText/RenderRichText";
import RenderTextArea from "@/components/RenderTextArea";
import mapimg from "@/assets/images/tunnel/project-map.png";

export default {
  data() {
    return {
      mapimg,
      activeHotspot: null
    };
  },
  computed: {
    zoomImageSrc() {
      return this.hotspot !== null && this.hotspot.cutaway
        ? this.hotspot.cutaway
        : false;
    },
    homePath() {
      return this.$root.rootPath;
    },
    pageData() {
      return this.$store.getters.pageData("tunnel-alignment");
    },
    hotspots() {
      return this.pageData.hotspots;
    },
    hotspot() {
      return this.activeHotspot !== null
        ? this.hotspots[this.activeHotspot]
        : null;
    }
  },
  methods: {
    onHotspot(i) {
      if (this.activeHotspot === i) {
        this.activeHotspot = null;
      } else {
        this.activeHotspot = i;
      }
    },
    resetHotspot() {
      this.activeHotspot = null;
    },
    selectStationLink() {
      if (!this.$root.rootPath || this.$root.rootPath == "") {
        return;
      }
      var selectStation = this.$root.rootPath.replace(/^\//, "");
      for (let i = 0; i < this.hotspots.length; i++) {
        const hotspot = this.hotspots[i];
        if (hotspot.station && hotspot.station === selectStation) {
          this.onHotspot(i);
          break;
        }
      }
    }
  },
  components: {
    RenderRichText,
    RenderTextArea
  },
  mounted() {
    this.selectStationLink();
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
@import "~scss/includes/tunnel";

.content-container {
  background: $navy;
  .the-content {
    h2 {
      font-size: 55px;
      width: 300px;
    }
  }
}

.sidebar {
  padding-top: 180px;

  ::v-deep .instruction {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px 0px;
    h2 {
      font-size: 20px;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }

  ::v-deep .hotspot-content {
    height: 900px;
    overflow: auto;
    scroll-behavior: smooth;
    padding-bottom: 40px;
    overflow: auto;
    &,
    * {
      touch-action: manipulation;
    }
    h2 {
      font-size: 34px;
      margin-bottom: 0.5em;
    }
    [v-block-type="upload-video"][v-block-id] {
      margin-top: 2rem;
    }
    [v-block-type="upload-video"]:not([v-block-id]) {
      border-top: 1px solid $charcoal;
      border-bottom: 1px solid $charcoal;
    }
    p {
      margin-bottom: 1em;
      font-size: 20px !important;
      line-height: (34 / 20);
    }

    opacity: 1;
    transition: 0.3s;

    @include enter {
      opacity: 1;
    }
    @include leave {
      opacity: 0;
    }
  }
}
</style>
