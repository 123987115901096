import Index from "@/pages/taking-shape/Index";
import Home from "@/pages/taking-shape/Home";
import Stations from "@/pages/taking-shape/Stations";
import store from "@/store";

export default {
  path: "/taking-shape",
  component: Index,
  meta: {
    label: "Taking Shape",
    group: "Benefits"
  },
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "taking-shape").then(next);
  },
  children: [
    {
      path: "",
      name: "Taking Shape Home",
      component: Home,
      meta: {
        label: "Home",
        hide: true
      }
    },
    {
      path: "stations",
      name: "Taking Shape Stations",
      component: Stations,
      meta: {
        child: true
      }
    }
  ]
};
