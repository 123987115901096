<template>
  <div>
    <div class="stations">
      <div class="bg-image">
        <img :src="bgimg" />
      </div>

      <div class="content">
        <h1>All Stations</h1>
        <h2>Brisbane</h2>
        <AllStationsCarousel :slides="brisbaneStations"></AllStationsCarousel>

        <h2>Gold Coast</h2>
        <AllStationsCarousel :slides="goldcoastStations"></AllStationsCarousel>
      </div>
    </div>

    <ProgressNav station="" />
  </div>
</template>

<script>
import bgimg from "@/assets/images/progress/all-stations-bg.jpg";
import ProgressNav from "@/pages/progress/ProgressNav";
import AllStationsCarousel from "@/pages/progress/AllStationsCarousel";

export default {
  data() {
    return {
      bgimg
    };
  },
  components: {
    ProgressNav,
    AllStationsCarousel
  },
  computed: {
    brisbaneStations() {
      return this.$store.getters.pageData("progress").brisbane;
    },
    goldcoastStations() {
      return this.$store.getters.pageData("progress").goldCoast;
    }
  },
  mounted() {
    localStorage.returnpath = "/progress/all-stations";
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $deep-purple;
  z-index: 0;
  img {
    object-fit: cover;
  }
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 84px;
  z-index: 1;

  h1 {
    @include typeface-gradient;
    font-size: 64px;
    margin: 10px 0 0 0;
  }
  h2 {
    @include typeface-header;
    color: $white;
    text-transform: uppercase;
    margin: 64px 0 12px;
  }
}
.carousel {
  margin-left: -84px;
  margin-right: -84px;
}
</style>
