<template>
  <div>
    <iframe class="frame" :src="gameUrl"></iframe>
    <transition name="fade">
      <div v-if="state !== 'loaded'" class="frame info">
        <StationHomeLink v-if="showHomeLink" />
        <template v-if="state === 'loading'">
          <h2>Bye Bye Transit Centre</h2>
          <p>Loading, please wait...</p>
        </template>
        <template v-else>
          <h2>Failed to load Bye Bye Transit Centre</h2>
          <p>Please check this device's internet connection and try again.</p>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import StationHomeLink from "@/components/StationHomeLink";

const GAME_ORIGIN = "https://byebye.btc.crossriverrail.qld.gov.au";
const GAME_PATH = "/";
const GAME_NAMESPACE = "bbtc";
const SUPPORTED_PARAMS = ["kiosk", "home", "embed"];
const LOAD_TIMEOUT = 10000;

export default {
  name: "Game",
  data() {
    return {
      state: "loading",
      loadTimeout: null,
      gameUrl: GAME_ORIGIN + GAME_PATH
    };
  },
  computed: {
    visible() {
      return this.state === "loaded";
    },
    showHomeLink() {
      return "home" in this.$route.query;
    }
  },
  methods: {
    onMessage({ data, origin }) {
      // Verify message origin
      if (origin !== GAME_ORIGIN) return;

      // Verify message namespace
      const [ns, action] = data.split(":");
      if (ns !== GAME_NAMESPACE) return;

      switch (action) {
        case "load":
          this.onLoad();
          break;
        case "error":
          this.onError();
          break;
        case "exit":
          this.onExit();
          break;
      }
    },
    onLoad() {
      window.clearTimeout(this.loadTimeout);
      this.state = "loaded";
    },
    onError() {
      window.removeEventListener("message", this.onMessage);
      this.state = "error";
    },
    onExit() {
      this.$router.back();
    }
  },
  created() {
    // Pass query params to game
    const params = SUPPORTED_PARAMS.filter(param => param in this.$route.query);
    if (params.length) {
      this.gameUrl += "?" + params.join("&");
    }

    // Listen for messages from game iframe
    window.addEventListener("message", this.onMessage);

    // If game doesn't load after set timeframe, assume it won't load at all
    this.loadTimeout = window.setTimeout(this.onError, LOAD_TIMEOUT);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
    window.clearTimeout(this.loadTimeout);
  },
  components: {
    StationHomeLink
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/transitions";

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;

    > *:not(:last-child) {
      margin-bottom: 1em;
    }
  }
}
</style>
