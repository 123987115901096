import store from "@/store";
import Index from "@/pages/progress/Index";
import Home from "@/pages/progress/Home";
import Brisbane from "@/pages/progress/Brisbane";
import GoldCoast from "@/pages/progress/GoldCoast";
import Stations from "@/pages/progress/AllStations";
import Station from "@/pages/progress/Station";
import Station360 from "@/pages/progress/Station360";
import StationTimeHop from "@/pages/progress/StationTimeHop";
import StationConstruction from "@/pages/progress/StationConstruction";

export default {
  path: "/progress",
  component: Index,
  meta: {
    label: "Progress Screens",
    group: "Progress"
  },
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "progress").then(next);
  },
  children: [
    {
      path: "",
      name: "Progress Home",
      component: Home,
      meta: {
        label: "Progress Screens",
        hide: true,
        showNav: false
      }
    },
    {
      path: "brisbane",
      name: "Progress Brisbane",
      component: Brisbane,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: false
      }
    },
    {
      path: "gold-coast",
      name: "Progress Gold Coast",
      component: GoldCoast,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: false
      }
    },
    {
      path: "all-stations",
      name: "New Upgraded Stations",
      component: Stations,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: true
      }
    },
    {
      path: "station/:slug",
      name: "Station Progress",
      component: Station,
      props: true,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: true
      }
    },
    {
      path: "station/:slug/timehop",
      name: "Station Time Hop",
      component: StationTimeHop,
      props: true,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: false
      }
    },
    {
      path: "station/:slug/view360",
      name: "Station 360 Panarama",
      component: Station360,
      props: true,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: false
      }
    },
    {
      path: "station/:slug/construction",
      name: "Station Construction",
      component: StationConstruction,
      props: true,
      meta: {
        label: "Progress Screens",
        child: true,
        showNav: false
      }
    }
  ]
};
