<template>
  <div>
    <div class="select">
      <div class="bg-image">
        <img :src="`${bgimg}`" />
      </div>

      <div class="title">
        <img class="title-image" :src="`${titleimg}`" alt="Benefits" />
        <img class="city-image" :src="`${cityimg}`" />
      </div>

      <div class="button-wrapper">
        <h2>
          What is your most common reason for travelling to, from or through
          Brisbane?
        </h2>

        <router-link
          v-for="(slide, index) in benefits"
          :key="slide.slug"
          :to="`/benefits/slides?slide=${index}`"
          class="benefits-button"
        >
          {{ index + 1 }}. {{ slide.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import bgimg from "@/assets/images/benefits/select-bg.png";
import cityimg from "@/assets/images/benefits/select-city.png";
import titleimg from "@/assets/images/benefits/title-benefits.svg";

export default {
  data() {
    return {
      bgimg,
      cityimg,
      titleimg
    };
  },
  computed: {
    benefits() {
      return this.$store.getters.pageData("benefits").benefits;
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.select {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #081d29;
}

.bg-image {
  background-position: center top;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
}

.title {
  text-align: center;
  position: absolute;
  top: 33.68%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    position: relative;
  }
  .city-image {
    top: -60px;
    left: 20px;
  }
}

.button-wrapper {
  position: relative;
  top: 180px;
  text-align: center;
  max-width: 1005px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 5;
  column-gap: 20px;
  row-gap: 30px;

  h2 {
    font-style: italic;
    font-weight: 900;
    color: $white;
    font-size: 40px;
    line-height: 1.5;
    margin: 0 50px 34px 50px;
    width: 100%;
  }
}

.benefits-button {
  position: relative;
  display: inline-block;
  border-radius: 30px;
  background-color: #081d29;
  color: $white;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  padding: 12px 24px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    border-radius: 32px;
    background: linear-gradient(90deg, #cb37e3 0%, #25a1c8 100%);
    z-index: -1;
  }
}
</style>
