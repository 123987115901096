// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import PortalVue from "portal-vue";
import { parseISO, format } from "date-fns";
import registerSW, {
  ServiceWorkerNoSupportError
} from "service-worker-loader!@/workers/sw.js";

Vue.config.productionTip = false;
Vue.use(PortalVue);

/**
 * Used to decide where the screen should navigate to when it has reached an idle timeout threshold
 */
const idleMap = {
  Roma: "/roma/",
  Exhibition: "/exhibition/",
  Boggo: "/boggo/",
  Albert: "/albert/",
  Woolloongabba: "/woolloongabba/",
  Tunnel: "/tunnel/",
  Stations: "/stations/",
  "Taking Shape": "/taking-shape/",
  "Progress Screens": "/progress/"
};

/**
 * Global filters
 */
Vue.filter("formatISO", function(value, fmt) {
  return value ? format(parseISO(value), fmt) : "";
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  data() {
    return {
      idleTimeoutThreshold: 1000 * 60 * 5,
      idle: true,
      idleTimeout: null,
      rootPath: ""
    };
  },
  methods: {
    setRootPath(newPath) {
      this.rootPath = newPath;
    },
    // Restart idle timeout
    resetIdleTimeout() {
      if (this.idle) {
        this.idle = false;
      }
      clearTimeout(this.idleTimeout);
      this.idleTimeout = setTimeout(
        () => this.goIdle(),
        this.idleTimeoutThreshold
      );
    },
    // Go to idle
    goIdle() {
      console.log("goIdle", this.$route.name, this.$route.path);
      this.idle = true;
      this.goHome(this.$route.name);
    },
    // Function for going to home page when idle
    goHome(currentRouteName) {
      if (this.rootPath !== "") {
        // && this.$route.path.includes("/tunnel/")) {
        this.$router.push(this.rootPath + "/");
      }
      for (let [find, home] of Object.entries(idleMap)) {
        if (currentRouteName.includes(find)) {
          this.$router.push(home);
        }
      }
    }
  },
  mounted() {
    window.addEventListener("mousemove", () => {
      this.resetIdleTimeout();
    });
    window.addEventListener("touchstart", () => {
      this.resetIdleTimeout();
    });
    window.addEventListener("touchmove", () => {
      this.resetIdleTimeout();
    });
    this.resetIdleTimeout();
  },
  async created() {
    // @TODO re-enable service worker
    // try {
    //   await registerSW();
    // } catch (err) {
    //   if (err instanceof ServiceWorkerNoSupportError) {
    //     console.error("Service workers not supported in this browser.");
    //   } else {
    //     console.error("Failed to register service worker.");
    //   }
    // }
  },
  render: h => h(App)
}).$mount("#app");
