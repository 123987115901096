<template>
  <div class="map-search-no-results-content">
    <div class="map-search-no-results-content__wrapper">
      <h3>Not in South East Queensland?</h3>
      <p>
        If you're outside SEQ or interstate, Cross River Rail will still
        transform your travel in, through and around the region when you visit
        us and provide a host of benefits:
      </p>
      <div ref="slider" :class="['slider', progressClasses]">
        <div ref="sliderscroll" class="slider__scroll">
          <div ref="sliderslides" class="slider__slides">
            <div class="slider__slide">
              <div
                class="slide__image"
                :style="`background-image: url(${images[0]})`"
              ></div>
              <p>
                New city centre stations will provide visitors to Brisbane with
                greater access to hospitals, sports stadiums and entertainment
                venues.
              </p>
            </div>
            <div class="slider__slide">
              <div
                class="slide__image"
                :style="`background-image: url(${images[1]})`"
              ></div>
              <p>
                The new Brisbane Live Entertainment centre planned for Roma
                Street will attract world class acts to Queensland.
              </p>
            </div>
            <div class="slider__slide">
              <div
                class="slide__image"
                :style="`background-image: url(${images[2]})`"
              ></div>
              <p>
                Urban redevelopment at Boggo Road will help grow our State's
                science, health and knowledge credentials, creating a world
                class research centre.
              </p>
            </div>
            <div class="slider__slide">
              <div
                class="slide__image"
                :style="`background-image: url(${images[3]})`"
              ></div>
              <p>
                An improved rail network will grow Queensland's economy, create
                new jobs and stimulate business opportunities.
              </p>
            </div>
          </div>
        </div>
        <div class="slider__navigation">
          <div @click="prev" class="slider__arrow slider__prev">
            <Icon icon="arrow-left" />
          </div>
          <div ref="scrollbar" class="slider__scrollbar">
            <div
              :style="scrollbarInnerStyle"
              class="slider__scrollbar-inner"
            ></div>
          </div>
          <div @click="next" class="slider__arrow slider__next">
            <Icon icon="arrow-right" />
          </div>
        </div>
      </div>
    </div>
    <p class="try-visiting-postcode">
      Why not enter the postcode or suburb of locations you stay or visit<br />
      when you're in South East Queensland?
    </p>
  </div>
</template>
<script>
import Icon from "@/components/Icon";
import imagea from "@/assets/images/not-seq-1.jpg";
import imageb from "@/assets/images/not-seq-2.jpg";
import imagec from "@/assets/images/not-seq-3.jpg";
import imaged from "@/assets/images/not-seq-4.jpg";

export default {
  mounted() {
    this.$nextTick(() => {
      this.update();
      this.$refs.sliderscroll.addEventListener("scroll", this.update);
      window.addEventListener("resize", this.update);
    });
  },
  data() {
    return {
      width: 10,
      scrollbarWidth: 0,
      progress: 0,
      images: [imagea, imageb, imagec, imaged]
    };
  },
  computed: {
    completed() {
      return this.progress >= 0.95;
    },
    started() {
      return this.progress >= 0.05;
    },
    progressClasses() {
      return {
        completed: this.completed,
        started: this.started
      };
    },
    scrollbarInnerStyle() {
      return {
        width: `${this.width}px`,
        left: `${(this.scrollbarWidth - this.width) * this.progress}px`
      };
    }
  },
  methods: {
    next() {
      if (this.completed) {
        return false;
      }
      let sliderWidth = this.$refs.slider.getBoundingClientRect().width;
      this.$refs.sliderscroll.scrollTo({
        left: this.$refs.sliderscroll.scrollLeft + sliderWidth,
        behavior: "smooth",
        duration: "0.3s"
      });
    },
    prev() {
      if (!this.started) {
        return false;
      }
      let sliderWidth = this.$refs.slider.getBoundingClientRect().width;
      this.$refs.sliderscroll.scrollTo({
        left: this.$refs.sliderscroll.scrollLeft - sliderWidth,
        behavior: "smooth",
        duration: "0.3s"
      });
    },
    update() {
      let sliderslidesWidth = this.$refs.sliderslides.getBoundingClientRect()
        .width;
      let sliderWidth = this.$refs.slider.getBoundingClientRect().width;
      let scrollbarWidth = this.$refs.scrollbar.getBoundingClientRect().width;

      let scrollX = this.$refs.sliderscroll.scrollLeft;
      let maxScroll = sliderslidesWidth - sliderWidth || 1;

      this.scrollbarWidth = scrollbarWidth;
      this.width = (scrollbarWidth * sliderWidth) / sliderslidesWidth;

      this.progress = scrollX / maxScroll;
    }
  },
  beforeDestroy() {
    this.$refs.sliderscroll.removeEventListener("scroll", this.update);
    window.removeEventListener("resize", this.update);
  },
  components: {
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";

$box_width: 800px;
$box_side_padding: 48px;
$slide_width: 330px;

.map-search-no-results-content {
  background: rgba(white, 0.95);
  width: $box_width;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem $box_side_padding 2.5rem;
  text-align: center;
  hr {
    display: block;
    height: 1px;
    background: rgba($grey, 0.25);
    border: 0;
    outline: 0;
    margin: 2rem auto;
  }
  h3 {
    color: $blue;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    font-size: 2.5rem;
    margin-bottom: 0.75em;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  p,
  li {
    font-size: 1.3rem;
    line-height: 1.5;
    color: $charcoal;
    margin-bottom: 1em;
  }
  li {
    position: relative;
    padding-left: 3rem;
    flex-basis: 50%;
    &::before {
      content: "";
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background: $blue;
      position: absolute;
      left: 0.5em;
      top: 0.5em;
    }
  }
  &__wrapper {
    width: 100%;
    > p {
      font-size: 1.5rem;
      max-width: 540px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .try-visiting-postcode {
    position: absolute;
    top: 100%;
    color: white;
    text-transform: uppercase;
    transform: translateY(6.5rem);
    font-weight: bold;
  }
}

.slider {
  padding-bottom: 2rem;
  position: relative;
  $gradient_width: 8rem;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: $gradient_width;
    background: radial-gradient(
      ellipse at center,
      rgba(black, 0.2) 0,
      rgba(black, 0) 70%,
      rgba(black, 0) 100%
    );
    transition: opacity 0.7s;
    z-index: -1;
  }
  &::before {
    opacity: 0;
    left: -($gradient_width / 2 + 3rem);
  }
  &::after {
    right: -($gradient_width / 2 + 3rem);
  }
  &.completed::after {
    opacity: 0;
  }
  &.started::before {
    opacity: 1;
  }
}
.slider__arrow {
  margin-top: 3px;
  transition: opacity 0.8s;
  svg {
    font-size: 1.25rem;
    fill: $grey;
  }
  &.slider__next {
    margin-left: 2rem;
  }
  &.slider__prev {
    opacity: 0.25;
    margin-right: 2rem;
  }
}
.slider {
  *,
  & {
    touch-action: manipulation;
  }
  &.completed {
    .slider__next {
      opacity: 0.25;
    }
  }
  &.started {
    .slider__prev {
      opacity: 1;
    }
  }
}
.slider__scroll {
  width: calc(100% + #{$box_side_padding * 2});
  margin-left: -$box_side_padding;
  margin-right: -$box_side_padding;
  padding-left: $box_side_padding;
  padding-right: $box_side_padding;
  overflow: auto;
  touch-action: manipulation;
}
.slider__slides {
  display: inline-block;
  white-space: nowrap;
}
.slider__slide {
  width: $slide_width;
  min-width: $slide_width;
  display: inline-block;
  vertical-align: top;
  position: relative;
  p {
    white-space: initial;
  }
  + .slider__slide {
    padding-left: (
      $box_width - ($box_side_padding * 2) - ($slide_width * 2) - 14px
    );
    box-sizing: content-box;
  }
}
.slide__image {
  width: 100%;
  display: block;
  padding-bottom: 67%;
  border-radius: 4px;
  overflow: hidden;
  border-bottom: 4px solid $teal-light;
  margin-bottom: 2rem;
  background: white;
  background-size: cover;
}

.slider__navigation {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}
$scrollbar_height: 8px;
.slider__scrollbar {
  width: 220px;
  background: $grey-light;
  height: $scrollbar_height;
  border-radius: $scrollbar_height / 2;
  position: relative;
}
.slider__scrollbar-inner {
  position: absolute;
  width: 2rem;
  top: 0;
  left: 0;
  bottom: 0;
  background: $teal-light;
  height: $scrollbar_height;
  border-radius: $scrollbar_height / 2;
}
</style>
