<template>
  <div>
    <p v-for="(p, i) in paragraphs" :key="i">{{ p }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    paragraphs() {
      return this.text.split(/\n+/);
    }
  }
};
</script>
