<template>
  <svg
    :class="classes"
    x="0px"
    y="0px"
    width="759.7px"
    height="68.4px"
    viewBox="0 0 759.7 68.4"
  >
    <path
      class="st0"
      d="M189.4 5.9l-5.9 8.9c-3.3-2.4-6.7-3.6-10.4-3.6-4.4 0-8.7 2.1-11 5.6-2.5 3.7-3.6 9.2-3.6 16.7 0 8.9.7 13.8 2.7 17.4
2.6 4.8 6.9 7.3 12.5 7.3 3.5 0 6.6-1 9.8-3.2.4-.3.8-.7 1.3-1l6 8.2c-5.1 4.2-10.6 6.1-18 6.1-9 0-15.9-2.9-21.2-9-5.2-5.9-7.8-13.7-7.8-23.3
0-8.1 1.4-14.6 4.4-20.2 5-9.4 14.4-15 24.8-15 6.4-.1 12.8 2 16.4 5.1M207.9 12.6l-.1 17.6h4.4c5.6 0 8-.6 9.8-2.1 1.6-1.5 2.5-3.8 2.5-6.5
0-4-1.6-6.7-4.7-7.9-1.5-.6-4.2-.9-7.4-.9h-4.5v-.2zM195 2.1l24.2.1c11.5 0 19.1 7.7 19.1 19 0 9.7-6.4 17.7-14.5 17.9 1.3 1 1.9 1.6 2.7 2.7
3.7 4.7 15.6 25.2 15.6 25.2h-15.4c-2.5-4.3-3.5-6.1-6.1-10.6-6.4-11.7-8.5-14.8-10.7-15.9-.7-.3-1-.5-2.1-.6V67H195V2.1zM268.2 11.4c-4.4 0-8.3
2.2-10.1 5.7-2.2 4.2-3.3 9.9-3.3 17.7 0 11.2 2 18.1 5.8 21 2.1 1.5 4.7 2.2 7.8 2.2 9.1 0 12.9-6.9 12.9-23.4
0-6.6-.6-11.4-1.7-15-1-3.3-4.4-8.2-11.4-8.2m.2-10.1c17.3 0 27.9 12.6 27.9 33 0 14.7-5.1 25.6-14.5 30.8-3.9 2.1-7.6 3-13 3-9.8
0-16.2-2.7-21.1-9.2-4.9-6.4-7.5-14.6-7.5-24.1 0-14 5.4-25.2 14.7-30.3 3.9-2.1 8.7-3.2 13.5-3.2M343.7 6.7l-6.2 9.3c-5.9-3.5-10.3-4.9-15.1-4.9-5.3
0-9.1 3.1-9.1 7.5 0 3.4 1.8 5 7.2 6.5l7.8 2.1c10.6 2.9 16.4 9.7 16.4 19.3 0 13.2-10.6 21.9-26.8 21.9-7.4 0-15.6-2.1-22.1-5.9l4.9-10.2c6.1
3.2 11.3 5.2 17.8 5.2 7.5 0 11.4-3.1 11.4-8.9 0-4.2-2.7-6.9-8.5-8.4l-7.2-2c-6.4-1.8-10.5-4.3-12.8-8.2-1.8-2.9-2.7-6.3-2.7-9.9C298.7 8.2
308.2 0 322 0c7.9.2 16 2.6 21.7 6.7M392.6 6.7l-6.2 9.3c-5.9-3.5-10.3-4.9-15.1-4.9-5.3 0-9.1 3.1-9.1 7.5 0 3.4 1.8 5 7.2 6.5l7.8 2.1c10.6
2.9 16.4 9.7 16.4 19.3 0 13.2-10.6 21.9-26.8 21.9-7.4 0-15.6-2.1-22.1-5.9l4.9-10.2c6.1 3.2 11.3 5.2 17.8 5.2 7.5 0 11.4-3.1 11.4-8.9
0-4.2-2.7-6.9-8.5-8.4l-7.2-2c-6.4-1.8-10.5-4.3-12.8-8.2-1.8-2.9-2.7-6.3-2.7-9.9 0-11.9 9.5-20.1 23.3-20.1 7.9.2 16 2.6 21.7 6.7M414.2
12.6l-.1 17.6h4.4c5.6 0 8-.6 9.8-2.1 1.6-1.5 2.5-3.8 2.5-6.5 0-4-1.6-6.7-4.7-7.9-1.5-.6-4.2-.9-7.4-.9h-4.5v-.2zM401.3 2.1l24.2.1c11.5 0 19.1
7.7 19.1 19 0 9.7-6.4 17.7-14.5 17.9 1.3 1 1.9 1.6 2.7 2.7 3.7 4.7 15.6 25.2 15.6
25.2H433c-2.5-4.3-3.5-6.1-6.1-10.6-6.4-11.7-8.5-14.8-10.7-15.9-.7-.3-1-.5-2.1-.6V67h-12.8V2.1zM451 2.1h13.6V67H451zM467.4 2.1h14.3l10.7 33.8c.3
1.1 1.3 4.5 2.1 7.3.4 1.6.6 2.2.9 4 .4-1.9.6-2.4 1.1-4.7 1-3.5 1.6-5 2.6-8.3l11-32.1 13.7-.1-22.6 65.5h-11.9L467.4 2.1zM526.5 2.1h36.9l-1.6
10.7h-22.3v15h18.6v10.7h-18.4v17.3h24.6V67h-37.8zM581 12.6l-.1 17.6h4.4c5.6 0 8-.6 9.8-2.1 1.6-1.5 2.5-3.8 2.5-6.5
0-4-1.6-6.7-4.7-7.9-1.5-.6-4.2-.9-7.4-.9H581v-.2zM568.1 2.1l24.2.1c11.5 0 19.1 7.7 19.1 19 0 9.7-6.4 17.7-14.5 17.9 1.3 1 1.9 1.6 2.7 2.7 3.7
4.7 15.6 25.2 15.6 25.2h-15.4c-2.5-4.3-3.5-6.1-6.1-10.6-6.4-11.7-8.5-14.8-10.7-15.9-.7-.3-1-.5-2.1-.6V67h-12.8V2.1zM630.6 9v24.2h6.9c5.8 0 9.3-.9
11.7-3.3 2.2-2 3.3-5.5 3.3-9.7s-2.6-8.4-6.3-9.8c-2.1-.8-4.6-1.3-8.1-1.3h-7.5V9zm-7.8-6.5h15.1c8.9 0 13.2 1.6 16.3 4.2 2.9 2.4 6.4 6.7 6.4 13.6 0
10.7-6.9 17.7-17.2 17.7h-.9c3 2.5 5.2 5.5 6.5 7.3s3 4.3 5.3 7.5c2.4 3.5 9.6 14.3 9.6
14.3h-9.7c-1.5-3.4-2.2-4.6-5-9-4.1-6.1-8-11.8-11.5-16-2.5-3.2-4-4.1-7.2-4.1v29.2h-7.7V2.5z"
    />
    <path
      class="st0"
      d="M673.4 40.9h20s-5.1-16.1-6.8-21.7l-3.1-10-3.2 9.5c-.4 1.4-6.9 22.2-6.9 22.2m15.5-38.5l21.4 64.7h-8.8l-6.1-19.9h-24.2L665
67.1h-7.8l21.6-64.7h10.1zM713.7 2.4h7.5v64.7h-7.5zM726.4 2.4v64.7h32.3l1-6.5h-25.5V2.4z"
    />
    <path
      class="st1"
      d="M27 68.4H0V57.3h25L91.1 1.4c1-.8 2.3-1.3 3.6-1.3h27.7v11.1H96.7L30.6 67.1c-1 .9-2.3 1.3-3.6 1.3z"
    />
    <path class="st0" d="M0 29.1h122.4v11.1H0z" />
    <path
      class="st2"
      d="M122.4 68.4H92.6c-1.4 0-2.7-.5-3.8-1.5L28.1 11.2H0V.1h30.3c1.4 0 2.7.5 3.8 1.5l60.7 55.7h27.6v11.1z"
    />
  </svg>
</template>

<script>
const WHITE_LOGO_ROUTES = [
  "My Future Journey",
  "Albert Street Home",
  "Boggo Road Home",
  "Exhibition Home",
  "Roma Street Home",
  "Woolloongabba Home",
  "Benefits Home",
  "Benefits Select",
  "Benefits Slides",
  "Taking Shape Home",
  "Taking Shape Stations",
  "Progress Home",
  "Progress Brisbane",
  "Progress Gold Coast",
  "New Upgraded Stations",
  "Station Progress"
];

export default {
  name: "CRRLogo",
  computed: {
    classes() {
      const classes = [];
      if (WHITE_LOGO_ROUTES.includes(this.$route.name)) classes.push("white");
      return classes;
    }
  }
};
</script>

<style lang="scss" scoped>
.st0 {
  fill: #003c66;
  transition: 0.3s;
}
.white .st0 {
  fill: white;
}
.st1 {
  fill: #86bc2f;
}
.st2 {
  fill: #f29318;
}
</style>
