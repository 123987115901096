<template>
  <router-link :to="link" :class="classes">
    <label class="label">{{ label }}</label>
  </router-link>
</template>

<script>
export default {
  name: "HomeButton",
  props: {
    label: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "blue",
      validator: v => ["blue", "orange"].includes(v)
    }
  },
  computed: {
    classes() {
      return `home-button home-button--${this.color}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.home-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 0;
  flex-grow: 1;
  height: 128px;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 2px 4px 0 rgba(black, 0.5);
  cursor: pointer;

  &--blue {
    background: $blue url(~@/assets/images/underline.png) bottom left repeat-x;
  }
  &--orange {
    background: $orange url(~@/assets/images/underline-white.png) bottom left
      repeat-x;
  }

  .label {
    font-size: 42px;
    font-weight: bold;
    line-height: 1.25;
    padding: 0 1em;
    color: $white;
    cursor: pointer;
  }
}
</style>
