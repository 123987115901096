<template>
  <div class="carousel">
    <h2 class="carousel-title">{{ title }}</h2>
    <div v-if="showSwipeControls" class="controls">
      <div class="control control-left">Swipe</div>
      <div class="control control-right">Swipe</div>
    </div>

    <div class="carousel-wrapper">
      <div
        class="carousel-pane"
        :style="{ width }"
        @mousedown="onDragStart"
        @touchstart="onDragStart"
        @mousemove="onDrag"
        @touchmove="onDrag"
        @mouseleave="onDragEnd"
        @mouseup="onDragEnd"
        @touchcancel="onDragEnd"
      >
        <div
          class="carousel-slide"
          v-for="slide in slides"
          :key="'slide-' + slide.id"
          @click.stop="openPopup(slide)"
        >
          <CMSImage class="slide-image" :image="slide.image" :width="420" />
          <div class="slide-title">{{ slide.title }}</div>
          <div class="slide-content">{{ slide.description }}</div>
        </div>
      </div>
    </div>
    <ImagePopup
      v-if="showImage"
      @close="showImage = false"
      :src="imageSrc"
      :losrc="imageLowSrc"
    />
  </div>
</template>

<script>
import CMSImage, { getImageSrc } from "@/components/CMSImage";
import ImagePopup from "@/components/ImagePopup";

export default {
  props: {
    title: String,
    slides: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CMSImage,
    ImagePopup
  },
  computed: {
    width() {
      return this.slides.length * 444 + 168 + "px";
    },
    showSwipeControls() {
      return this.slides.length > 4;
    }
  },
  data() {
    return {
      dragData: {
        dragging: false,
        dragged: false,
        el: null,
        xstart: 0,
        xoffsetstart: 0,
        paneWidth: 0
      },
      showImage: false,
      imageLowSrc: false,
      imageSrc: ""
    };
  },
  methods: {
    openPopup(slide) {
      if (this.dragData.dragged) {
        return;
      }
      this.imageSrc = getImageSrc(slide.image, { width: 1320 });
      this.imageLowSrc = getImageSrc(slide.image, { width: 420 });
      this.showImage = true;
    },
    onDragStart: function(event) {
      let paneWidth = isNaN(parseInt(event.currentTarget.style.width))
        ? 0
        : parseInt(event.currentTarget.style.width);
      let pageX = event.touches ? event.touches[0].pageX : event.pageX;
      if (paneWidth < window.innerWidth) {
        return;
      }
      this.dragData.dragging = true;
      this.dragData.dragged = false;
      this.dragData.el = event.currentTarget;
      this.dragData.xstart = pageX;
      this.dragData.xoffsetstart = isNaN(
        parseInt(event.currentTarget.style.left)
      )
        ? 0
        : parseInt(event.currentTarget.style.left);
      this.dragData.paneWidth = paneWidth;
    },
    onDrag: function(event) {
      if (this.dragData.dragging) {
        let pageX = event.touches ? event.touches[0].pageX : event.pageX;
        let newLeft = Math.min(
          0,
          this.dragData.xoffsetstart + pageX - this.dragData.xstart
        );
        newLeft = Math.max(
          newLeft,
          window.innerWidth - this.dragData.paneWidth
        );
        if (Math.abs(pageX - this.dragData.xstart) > 10) {
          this.dragData.dragged = true;
        }
        this.dragData.el.style.left = newLeft + "px";
      }
    },
    onDragEnd: function() {
      this.dragData.dragging = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/includes/vars";
@import "~scss/includes/progress";
.carousel {
  position: relative;
}
.carousel-title {
  @include typeface-header;
  color: $white;
  text-transform: uppercase;
  margin-bottom: 32px;
  margin-left: 84px;
}
.controls {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  .control {
    position: absolute;
    width: 80px;
    top: 80px;
    text-transform: uppercase;
    color: $white;
    text-align: center;
    padding-top: 100px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 40px;
      width: 60px;
      height: 2px;
      background: $white;
    }
    &::before {
      transform: rotate(-50deg);
    }
    &::after {
      transform: rotate(50deg);
    }
  }
  .control-left {
    left: 0;
    &::before,
    &::after {
      left: 20px;
      transform-origin: 1px center;
    }
  }
  .control-right {
    right: 0;
    &::before,
    &::after {
      right: 20px;
      transform-origin: right center;
    }
  }
}

// Slide-wrapper
.carousel-wrapper {
  height: 520px;
  width: 100%;
  overflow: hidden;

  mask-image: linear-gradient(
    90deg,
    rgba($black, 0) 0%,
    rgba($black, 1) 5%,
    rgba($white, 1) 95%,
    rgba($white, 0) 100%
  );
}
.carousel-pane {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0 84px;
}

// Slide
.carousel-slide {
  height: auto;
  width: 420px;
}

.slide-image {
  position: relative;
  width: 420px;
  height: 250px;
  background: $grey center center no-repeat;
  background-size: cover;
  border-radius: 15px;

  &.has-video {
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::before {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: $orange;
    }
    &::after {
      width: 0;
      height: 0;
      border-left: 14px solid $white;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
    }
  }
}
.slide-title {
  color: $white;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  margin: 28px 0 16px 0;
  width: 367px;
}
.slide-content {
  color: $white;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 367px;
}
</style>
