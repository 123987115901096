<template>
  <div :class="['map-popup-content', popupStyle]">
    <a v-if="popupStyle === 'benefit'" class="mapboxgl-popup-close-button">×</a>
    <div
      v-if="popupStyle === 'benefit'"
      class="benefit-popup-image"
      :style="`background-image: url(${image})`"
    ></div>
    <p class="label" v-if="popupStyle === 'benefit'">{{ content }}</p>
    <div v-if="popupStyle === 'station'">
      <p class="label">{{ label }}</p>
      <div :class="['lines', { wrap: lines.length > 3 }]">
        <a
          v-for="({ line }, i) in lines"
          :key="i"
          :class="['line', { active: activeLine === line }]"
          :style="lineStyle(line)"
          :href="onLineClick(line)"
        >
          <span>{{ lineLabel(line) }}</span>
          <span :style="lineCircleStyle(line)" class="line-circle"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/lib/LoadData";

export default {
  name: "map-popup",
  props: {
    image: String,
    popupStyle: {
      type: String,
      required: true
    },
    content: String,
    lines: Array
  },
  data() {
    return {
      allLines: [],
      activeLine: null,
      label: "Select a train line to see your future journey benefits"
    };
  },
  methods: {
    lineStyle(line) {
      let lineColor = this.lineColor(line);
      return {
        borderRadius: "0px",
        borderWidth: "1px",
        borderColor: "#737476",
        borderBottomWidth: "3px",
        borderStyle: "solid",
        borderBottomColor: lineColor,
        backgroundColor: this.activeLine === line ? lineColor : "#F4F4F4",
        color:
          this.activeLine === line
            ? this.allLines.find(n => n.line === line).color || "white"
            : "black"
      };
    },
    lineCircleStyle(line) {
      let lineColor = this.lineColor(line);
      return {
        borderColor: lineColor
      };
    },
    lineColor(line) {
      return this.allLines.find(n => n.line === line).bgcolor;
    },
    lineLabel(line) {
      return line.replace(/ line/, "");
    },
    setLine(line) {
      this.activeLine = line;
    },
    onLineClick(line) {
      return (
        'javascript: var event = new Event("select-line"); event.line = "' +
        line +
        '"; window.dispatchEvent(event)'
      );
    }
  },
  mounted() {
    if (this.popupStyle === "station") {
      if (this.lines.length === 1) {
        this.setLine(this.lines[0].line);
      }
      window.addEventListener("select-line", e => {
        this.setLine(e.line);
      });
    }
  },
  async created() {
    this.allLines = await Loader.load("local://lines");
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/transitions";

.label {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: black;
  line-height: 1.4;
  margin-bottom: 2rem;
}

.map-popup-content {
  background: white;
  animation: fadein 0.6s 0.1s forwards;
  opacity: 0;
  padding: 3rem 2rem 2rem;
  $benefit_image_height: 180px;
  &.benefit {
    background: rgba(white, 0.9);
    padding: 2rem 2rem;
    width: 100%;
    opacity: 1;
    clip-path: inset(50% 100% 50% 0% round 10px);
    animation: wipe 0.6s 0.1s forwards;
    padding-top: $benefit_image_height;
    .benefit-popup-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-size: cover;
      background-position: center;
      height: $benefit_image_height - 32px;
    }
    .label {
      font-size: 16px;
      margin: 0;
      text-align: left;
      font-weight: 400;
      text-transform: none;
    }
  }
  &.station {
    background: rgba(white, 0.9);
    width: 700px;
    min-height: 200px;
    border-radius: 2px;
    transform: translateX(-50%);
  }
  &.station::before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-width: 2rem;
    border-color: transparent;
    border-bottom-color: rgba(white, 0.9);
    border-style: solid;
    left: 50%;
    transform: translate(-50%, 0);
    top: -4rem;
  }
  .lines {
    display: flex;
    width: 100%;
    margin-bottom: -1rem;
  }
  .line-circle {
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 13px;
    width: 13px;
    border-width: 3px;
    border-style: solid;
    background: white;
    border-radius: 50%;
  }
  .line {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    // text-transform: uppercase;
    background: $offwhite;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    color: $charcoal;
    transition: all 0.3s;
    position: relative;
    &.active {
      color: white;
    }
    span {
      font-weight: 700;
      text-align: center;
    }
  }
}
@keyframes wipe {
  0% {
    clip-path: inset(10% 100% 10% 0% round 10px);
    opacity: 0;
  }
  100% {
    clip-path: inset(0% 0% 0% 0% round 0px);
    opacity: 1;
  }
}
</style>
