<template>
  <div ref="swiper" class="swiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(slide, index) in pageData.benefits"
        :key="slide.slug"
        :class="{ 'animate-once': slideHasAnimated[index] }"
      >
        <div class="slide-wrapper">
          <div
            :class="['slide', slideItem.orientation, slideItem.size]"
            v-for="slideItem in slide.images"
            :key="slideItem.image.url"
          >
            <CMSImage :image="slideItem.image" :width="768" />
          </div>
        </div>

        <div class="caption">
          <div class="number-indicator">
            <span>{{ index + 1 }}</span>
          </div>
          <h2>{{ slide.subtitle }}</h2>
          <RenderTextArea :text="slide.overview" />
        </div>
      </div>
    </div>
    <div class="swiper-btn swiper-btn-prev"><Icon icon="chevron-left" /></div>
    <div class="swiper-btn swiper-btn-next"><Icon icon="chevron-right" /></div>
  </div>
</template>

<script>
import CMSImage from "@/components/CMSImage.vue";
import RenderTextArea from "@/components/RenderTextArea.vue";
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import Icon from "../../components/Icon.vue";

export default {
  computed: {
    pageData() {
      return this.$store.getters.pageData("benefits");
    }
  },
  data() {
    return {
      slideHasAnimated: [] // Array to track whether each slide has animated
    };
  },
  mounted() {
    this.slideHasAnimated = this.pageData.benefits.map(() => false);

    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Navigation],
      centeredSlides: true,
      slidesPerView: 1,
      speed: 500,
      navigation: {
        nextEl: ".swiper-btn-next",
        prevEl: ".swiper-btn-prev"
      },
      on: {
        slideChange: this.onSlideChange
      }
    });
    this.activateSlideFromURL();
  },
  methods: {
    onSlideChange() {
      try {
        const activeIndex = this.swiper.activeIndex;

        if (!this.slideHasAnimated[activeIndex]) {
          this.$set(this.slideHasAnimated, activeIndex, true); // Mark this slide as animated
        }
      } catch (error) {
        console.error("Error in onSlideChange:", error);
      }
    },
    activateSlideFromURL() {
      try {
        const hash = window.location.hash;
        const params = new URLSearchParams(hash.split("?")[1]);

        const slideIndex = params.get("slide");
        if (slideIndex && this.swiper) {
          // Ensure the slideIndex is a number and within bounds
          const index = parseInt(slideIndex, 10);

          if (index >= 0 && index < this.pageData.benefits.length) {
            // Temporarily disable transition
            this.swiper.params.speed = 0;

            // Slide to the desired slide
            this.swiper.slideTo(index, 0, false);
            if (index == 0) {
              this.onSlideChange();
            }

            this.swiper.params.speed = 500;
          } else {
            console.error("Slide index out of bounds:", index);
          }
        }
      } catch (error) {
        console.error("Error in activateSlideFromURL:", error);
      }
    }
  },
  components: {
    CMSImage,
    RenderTextArea,
    Icon
  }
};
</script>

<style lang="scss" scoped>
.swiper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #081d29;
  background: linear-gradient(
    180deg,
    #081d29 0%,
    rgba(#cb37e3, 0.4) 33%,
    #081d29 75%
  );
}

.slide-wrapper {
  position: relative;
  width: 940px;
  height: 1096px;
  margin: 80px auto 60px auto;
}

.swiper-btn {
  position: fixed;
  top: calc(1096px / 2 - 83px);
  display: flex;
  align-items: center;
  width: 166px;
  height: 166px;
  background: linear-gradient(0deg, #062438 0%, rgba(6, 36, 56, 0.5) 100%);
  border-radius: 50%;
  padding: 0 32px;
  z-index: 99;
  transition: all 0.25s ease;
  svg {
    fill: #fff;
    color: #fff;
    width: 32px;
    height: 32px;
  }
  &.swiper-button-disabled {
    opacity: 0.5;
  }
  &.swiper-btn-prev {
    left: -85px;
    justify-content: flex-end;
  }
  &.swiper-btn-next {
    right: -85px;
    justify-content: flex-start;
  }
}

.slide {
  position: absolute;
  text-align: center;
  width: 415px * 1.25;
  margin: 0;
  padding: 0;

  img {
    pointer-events: none;
    width: 415px * 1.25;
    height: 524px * 1.25;
    object-fit: cover;
  }

  &.small {
    width: 468px * 0.9;
    img {
      width: 468px * 0.9;
      height: 591px * 0.9;
    }
  }

  &.large {
    width: 468px * 1.2;
    img {
      width: 468px * 1.2;
      height: 591px * 1.2;
    }
  }

  &.landscape {
    width: 591px;
    img {
      width: 591px;
      height: 468px;
    }

    &.small {
      width: 415px * 1.25;
      img {
        width: 415px * 1.25;
        height: 329px * 1.25;
      }
    }

    &.large {
      width: 591px * 1.25;
      img {
        width: 591px * 1.25;
        height: 468px * 1.25;
      }
    }
  }

  opacity: 0;
  transform: scale(0.8);

  $bc-anim-time: 3s;
  $bc-anim-delay: 3s;

  .animate-once & {
    &:nth-of-type(1) {
      animation: fadeIn $bc-anim-time ease-in-out 0s 1 forwards;
    }
    &:nth-of-type(2) {
      animation: fadeIn $bc-anim-time ease-in-out $bc-anim-delay 1 forwards;
    }
    &:nth-of-type(3) {
      animation: fadeIn $bc-anim-time ease-in-out ($bc-anim-delay * 2) 1
        forwards;
    }
    &:nth-of-type(4) {
      animation: fadeIn $bc-anim-time ease-in-out ($bc-anim-delay * 3) 1
        forwards;
    }
    &:nth-of-type(5) {
      animation: fadeInCenter $bc-anim-time ease-in-out ($bc-anim-delay * 4) 1
        forwards;
    }
  }
  &:nth-of-type(1) {
    top: 0;
    left: 0;
    transform-origin: top left;
  }
  &:nth-of-type(2) {
    top: 0;
    right: 0;
    transform-origin: top right;
  }
  &:nth-of-type(3) {
    bottom: 0;
    right: 0;
    transform-origin: bottom right;
  }
  &:nth-of-type(4) {
    bottom: 0;
    left: 0;
    transform-origin: left bottom;
  }
  &:nth-of-type(5) {
    top: 50%;
    left: 50%;
  }

  &:nth-of-type(1).landscape,
  &:nth-of-type(2).landscape {
    top: 40px;
  }
  &:nth-of-type(3).landscape,
  &:nth-of-type(4).landscape {
    bottom: 40px;
  }

  &:nth-of-type(1).small,
  &:nth-of-type(2).small {
    top: 40px;
  }
  &:nth-of-type(3).small,
  &:nth-of-type(4).small {
    bottom: 40px;
  }

  &:nth-of-type(1).small.landscape,
  &:nth-of-type(2).small.landscape {
    top: 60px;
  }
  &:nth-of-type(3).small.landscape,
  &:nth-of-type(4).small.landscape {
    bottom: 61px;
  }
}

.caption {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 70px;
  margin: 0 auto;

  h2 {
    font-size: 40px;
    font-style: italic;
    color: #fff;
    margin-bottom: 35px;
  }
  ::v-deep p {
    color: #fff;
    font-size: 28px;
    line-height: 40px;
  }
  .number-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    border: 3px solid transparent;
    background-image: linear-gradient(#081d29, #081d29),
      linear-gradient(90deg, #cb37e3 0%, #25a1c8 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    span {
      display: block;
      line-height: 1;
      margin-top: 0.1em;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(0.8);
  }
}
@keyframes fadeInCenter {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
</style>
