<template>
  <div>
    <transition>
      <router-view class="router-view"></router-view>
    </transition>
    <Sidebar class="tunnel-sidebar">
      <template v-slot:title>
        <transition>
          <div v-if="!onChildRoute">
            <h1>
              <span class="h1">{{ title }}</span>
            </h1>
          </div>
        </transition>
      </template>
      <template v-slot:content>
        <transition>
          <div v-if="!onChildRoute">
            <div class="the-content">
              <h2>{{ pageData.subtitle }}</h2>
              <RenderTextArea :text="pageData.overview" />
            </div>
          </div>
        </transition>
      </template>
    </Sidebar>
    <ScreenNavigation base="/tunnel" :routes="childRoutes" />
    <ScreenTitleTab :label="title" />
    <StationHomeLink />
  </div>
</template>

<script>
import StationHomeLink from "@/components/StationHomeLink";
import ScreenTitleTab from "@/components/ScreenTitleTab";
import ScreenNavigation from "@/components/ScreenNavigation";
import Sidebar from "@/components/Sidebar";
import RenderTextArea from "@/components/RenderTextArea";
import mixins from "@/mixins";

export default {
  data() {
    return {
      title: "Making a tunnel"
    };
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("tunnel-home");
    }
  },
  components: {
    StationHomeLink,
    ScreenTitleTab,
    ScreenNavigation,
    Sidebar,
    RenderTextArea
  },
  mixins: [mixins]
};
</script>

<style lang="scss" scoped>
h1 {
  position: absolute;
  left: 0;
  top: 0;
  &::before {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100vw;
    background: white;
  }
}
h1,
.h1 {
  font-size: 100px;
}
.h1::after {
  left: 520px;
}

.sidebar.tunnel-sidebar {
  padding: 370px 60px 120px 90px;
}

.sidebar.tunnel-sidebar .sidebar-content {
  h2 {
    font-size: 36px;
    margin-bottom: 18px;
    line-height: 1.25;
  }
}
</style>

<style lang="scss">
.sidebar.tunnel-sidebar .sidebar-content {
  height: calc(100% - 70px);
  overflow: auto;
  scroll-behavior: smooth;
  &,
  * {
    touch-action: manipulation;
  }

  .the-content {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  p {
    font-size: 19px;
    margin-bottom: 0.5em;
  }
}
</style>
