<template>
  <div :class="['screen-title-tab', { visible }]">
    <div class="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "screen-title-tab",
  props: ["label"],
  computed: {
    visible() {
      return this.$route.meta.child === true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
.screen-title-tab {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 2rem 0 (280px + 20);
  &::before {
    transition: all 1s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    background: white;
  }
  .label {
    position: relative;
    color: $blue;
    font-weight: 900;
    font-size: 28px;
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-3rem);
  }
  &.visible {
    &::before {
      right: 0;
    }
    .label {
      opacity: 1;
      transform: translateX(0rem);
      transition: all 1s 0.4s;
    }
  }
}
</style>
