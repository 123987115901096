import { render, staticRenderFns } from "./PastTimeline.vue?vue&type=template&id=6498f842&scoped=true"
import script from "./PastTimeline.vue?vue&type=script&lang=js"
export * from "./PastTimeline.vue?vue&type=script&lang=js"
import style0 from "./PastTimeline.vue?vue&type=style&index=0&id=6498f842&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6498f842",
  null
  
)

export default component.exports