import { render, staticRenderFns } from "./ConstructionCarousel.vue?vue&type=template&id=3568f919&scoped=true"
import script from "./ConstructionCarousel.vue?vue&type=script&lang=js"
export * from "./ConstructionCarousel.vue?vue&type=script&lang=js"
import style0 from "./ConstructionCarousel.vue?vue&type=style&index=0&id=3568f919&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3568f919",
  null
  
)

export default component.exports