<template>
  <div>
    <div ref="sidebar" class="sidebar">
      <RenderRichText
        class="introduction"
        :content="pageData.sidebar.introHTML"
      />
      <div
        ref="sidebar-content"
        class="sidebar-content"
        @scroll="onContentScroll"
      >
        <RenderRichText :content="pageData.sidebar.contentHTML" />
        <div class="control" @click="scrollContent">
          <Icon icon="chevron-down" />
        </div>
      </div>
    </div>

    <div class="content-container">
      <div class="the-content">
        <h2>{{ pageData.title }}</h2>
        <div class="intro">
          <RenderTextArea :text="pageData.overview" />
        </div>
        <div class="borer">
          <div
            class="line"
            :style="`top:${sampleDepthTop}px; height:${sampleDepthHeight}px;`"
          ></div>
        </div>
        <span v-if="samples.length > 1" class="interaction-tip"
          ><Icon icon="interactive" /><span class="label"
            >Use the arrows to explore core samples</span
          ></span
        >

        <div v-if="samples.length > 1" class="samples-controls">
          <span
            v-on:click="prevSample()"
            :class="{ inactive: activeSample === 0 }"
            ><Icon icon="chevron-left"
          /></span>
          <span
            v-on:click="nextSample()"
            :class="{
              inactive: activeSample === samples.length - 1
            }"
            ><Icon icon="chevron-right"
          /></span>
        </div>

        <div v-if="samples" class="sample-container">
          <transition mode="out-in">
            <div
              class="sample"
              :key="'sample-' + currentSample.id + '-' + currentSample"
              :style="`background-image:url(${currentSample.image.url});`"
            >
              <div class="sample-data">
                <h3>{{ currentSample.title }}</h3>
                <p v-if="currentSample.minDepth || currentSample.maxDepth">
                  <Icon icon="distance" /> {{ currentSample.minDepth }}m -
                  {{ currentSample.maxDepth }}m
                </p>
                <p v-if="currentSample.date">
                  <Icon icon="calendar-alt" />
                  {{ dateFormat(currentSample.date) }}
                </p>
              </div>
              <!-- <div class="sample-title">{{currentSample.title}}</div> -->
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import RenderRichText from "@/components/RichText/RenderRichText";
import RenderTextArea from "@/components/RenderTextArea";

export default {
  data() {
    return {
      activeSample: 0,
      showVideo: false,
      showVideo2: false,
      showVideo3: false
    };
  },
  methods: {
    nextSample() {
      this.activeSample = Math.min(
        this.activeSample + 1,
        this.samples.length - 1
      );
    },
    prevSample() {
      this.activeSample = Math.max(this.activeSample - 1, 0);
    },
    dateFormat(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    videoPauseAll() {
      for (let vid of this.$refs.video) {
        vid.pause();
      }
    },
    onContentScroll(e) {
      const sidebar = this.$refs.sidebar;
      if (sidebar) {
        if (
          e.target.scrollTop <
          e.target.scrollHeight - e.target.clientHeight
        ) {
          sidebar.classList.remove("at-bottom");
        } else {
          sidebar.classList.add("at-bottom");
        }
      }
    },
    scrollContent() {
      const el = this.$refs["sidebar-content"];
      if (el.scrollTop < el.scrollHeight - el.clientHeight) {
        el.scrollTop = Math.min(
          el.scrollHeight - el.clientHeight,
          el.scrollTop + el.clientHeight - 60
        );
      } else {
        el.scrollTop = 0;
      }
    }
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("tunnel-geotech");
    },
    samples() {
      return this.pageData.samples;
    },
    currentSample() {
      return this.pageData.samples[this.activeSample];
    },
    sampleDepthTop() {
      if (this.currentSample) {
        const { minDepth } = this.currentSample;
        return 95 + (minDepth * (370 - 95)) / 41;
      }
      return 95;
    },
    sampleDepthHeight() {
      if (this.currentSample) {
        const { minDepth, maxDepth } = this.currentSample;
        return ((maxDepth - minDepth) * (370 - 95)) / 41;
      }
      return 0;
    }
  },
  components: {
    Icon,
    RenderRichText,
    RenderTextArea
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
@import "~scss/includes/tunnel";

::v-deep .sidebar-content {
  height: calc(100% - 142px);
  overflow: auto;
  scroll-behavior: smooth;
  padding-top: 40px;
  padding-bottom: 80px;
  &,
  * {
    touch-action: manipulation;
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    color: $grey;
    margin-bottom: 0.5em;
  }
  ul {
    margin: 1rem 0 1rem 2rem;
    li {
      font-size: 18px;
      list-style: disc;
      color: $grey;
      margin-top: 1em;
      line-height: 1.5;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: $blue;
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  h3 {
    font-size: 24px;
    line-height: (32 / 24);
    font-weight: bold;
    margin-bottom: 0.5em;
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0.5em;
  }

  .control {
    text-align: center;
    padding-top: 10px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    .icon {
      width: 32px;
      height: 19px;
      fill: $green;
      transition: 0.3s;
    }
  }
}

.content-container {
  .borer {
    position: absolute;
    top: 60px;
    right: 40px;
    width: 135px;
    height: 370px;
    background: url("../../assets/images/tunnel/early-works-borer.svg") center
      center no-repeat;
    .line {
      position: absolute;
      width: 100px;
      left: 5px;
      right: 5px;
      top: 95px;
      bottom: 0;
      background: rgba($green, 0.5);
      border-top: 1px solid $green;
      border-bottom: 1px solid $green;
      transition: 0.3s;
      z-index: -1;
    }
  }

  .samples-controls {
    position: absolute;
    top: 55%;
    left: 60px;
    right: 60px;
    display: flex;
    justify-content: space-between;
    z-index: 5;
    .icon {
      width: 29px;
      height: 48px;
      fill: $grey-light;
      transition: 0.3s;
    }
    .inactive .icon {
      fill: $offwhite;
    }
  }
}

.interaction-tip {
  position: absolute;
  bottom: 180px;
}
::v-deep .sidebar {
  .introduction {
    background: #fff;
    margin: -60px -40px 0 -40px;
    padding: 60px 40px 30px;
    h2 {
      font-size: 40px;
      margin-bottom: 12px;
      color: $blue;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0.5em;
    }
  }
  &.at-bottom .control .icon {
    transform: rotate(180deg);
  }
  .tabs {
    background: #fff;
    margin: 0 -40px;
    padding: 40px 0 0 40px;
    .tab {
      display: inline-block;
      background: #fff;
      font-size: 20px;
      line-height: 49px;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      padding: 0 24px;
      transition: 0.3s;
      &.active {
        background: $offwhite;
        color: $blue;
      }
    }
  }

  .location-content {
    padding-top: 60px;
    height: calc(100% - 138px);
    overflow-x: hidden;
    overflow-y: auto;
    .block {
      padding-bottom: 60px;
    }
    h2 {
      font-size: 28px;
      margin-bottom: 12px;
    }
    .location-description {
      font-size: 20px;
      line-height: 1.5;
    }

    opacity: 1;
    transition: 0.15s;
    @include enter {
      opacity: 1;
    }
    @include leave {
      opacity: 0;
    }
  }
}

.sample-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .sample {
    position: absolute;
    top: 0;
    background: center 70% no-repeat;
    background-size: 750px;
    width: 100%;
    height: 100%;
    padding: 60px;

    &-data {
      position: absolute;
      top: 60px;
      right: 200px;
      width: 230px;
      background: $offwhite;
      padding: 24px;
      font-size: 20px;
      h3 {
        color: $blue;
        font-size: 24px;
        font-weight: bold;
      }
      h4 {
        font-weight: bold;
        margin-bottom: 0.25em;
      }
      p {
        font-size: 20px;
        line-height: 2;
      }
      .icon {
        width: 1em;
        height: 1em;
        margin-right: 0.5em;
        fill: $blue;
        vertical-align: middle;
      }
    }
    &-title {
      position: absolute;
      top: 50%;
      left: 210px;
      color: $blue;
      font-size: 28px;
      font-weight: bold;
      &::before,
      &::after {
        content: "";
        display: block;
        background: $grey;
        position: absolute;
        height: 4px;
        width: 140px;
        bottom: -8px;
      }
      &::before {
        border-radius: 0 2px 2px 0;
        left: 0;
      }
      &::after {
        border-radius: 2px 0 0 2px;
        background: $grey;
        left: 138px;
        width: 80px;
        transform-origin: 0 50%;
        transform: rotate(40deg);
      }
    }

    opacity: 1;
    filter: blur(0);
    transition: 0.15s;

    @include enter {
      opacity: 1;
      filter: blur(0);
    }
    @include leave {
      opacity: 0;
      filter: blur(3px);
    }
  }
}

.video-control {
  pointer-events: none;
}
</style>
