import store from "@/store";

import Index from "@/pages/boggo/Index";
import Home from "@/pages/boggo/Home";
import Introduction from "@/pages/boggo/Introduction";
import Origin from "@/pages/boggo/Origin";
import Past from "@/pages/boggo/Past";
import Progress from "@/pages/boggo/Progress";
import Future from "@/pages/boggo/Future";

export default {
  path: "/boggo",
  component: Index,
  beforeEnter(to, from, next) {
    store.dispatch("loadPage", "explore-boggo-road").then(next);
  },
  meta: {
    label: "Boggo Road",
    group: "Stations"
  },
  children: [
    {
      path: "",
      name: "Boggo Road Home",
      component: Home,
      meta: {
        label: "Home",
        hide: true
      }
    },
    {
      path: "intro",
      name: "Boggo Road Introduction",
      component: Introduction,
      meta: {
        label: "Introduction",
        showSidebar: true
      }
    },
    {
      path: "origin",
      name: "Boggo Road Origin",
      component: Origin,
      meta: {
        child: true,
        label: "Origin"
      }
    },
    {
      path: "past",
      name: "Boggo Road Past",
      component: Past,
      meta: {
        child: true,
        label: "Past"
      }
    },
    {
      path: "progress",
      name: "Boggo Road Progress",
      component: Progress,
      meta: {
        child: true,
        label: "Progress"
      }
    },
    {
      path: "future",
      name: "Boggo Road Future",
      component: Future,
      meta: {
        child: true,
        label: "Future"
      }
    }
  ]
};
