<template>
  <div :class="classes">
    <router-link :to="returnPath" :class="returnCSS">{{
      returnText
    }}</router-link>
    <!--router-link class="start-link benefits-button" to="/benefits"
      >Start screen</router-link
    -->
  </div>
</template>

<script>
export default {
  name: "BenefitsNav",
  data() {
    return {};
  },
  computed: {
    classes() {
      const classes = ["benefits-nav"];
      if (this.$route.name === "Benefits Home") classes.push("off-screen");
      if (this.$route.name === "Benefits Select") classes.push("select-screen");
      return classes;
    },
    returnPath() {
      let returnPath = "/benefits";
      if (this.$route.name === "Benefits Slides") {
        returnPath = "/benefits/select";
      } else if (localStorage.returnpath) {
        returnPath = localStorage.returnpath;
      }
      return returnPath;
    },
    returnText() {
      let returnText = "Return to start screen";
      return returnText;
    },
    returnCSS() {
      let returnCSS = ["start-screen-button"];
      return returnCSS;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.benefits-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 180px;
  padding: 0 100px;
  background-color: #0b2933;
  box-shadow: 0px -7px 31px rgba(21, 120, 190, 0.4);

  a {
    color: $white;
    font-size: 32px;

    display: flex;
    align-content: center;
    img {
      margin-right: 1rem;
      width: 24px;
      vertical-align: middle;
    }
  }

  .benefits-button {
    position: relative;
    display: inline-block;
    border-radius: 30px;
    background-color: #0b2933;
    color: $white;
    font-size: 32px;
    line-height: 50px;
    padding: 7px 40px 3px 40px;
    margin: 30px 20px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border-radius: 32px;
      background: linear-gradient(90deg, #cb37e3 0%, #25a1c8 100%);
      z-index: -1;
    }
  }

  .start-screen-button {
    position: relative;
    max-width: 304px;
    font-family: "MetaPro";
    font-style: italic;
    font-weight: 900;
    padding-left: 124px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      left: 0;
      width: 80px;
      height: 80px;
      border-radius: 50px;
      border: 10px solid $orange;
      background-color: $orange;
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M42.0386 24.1768C42.0386 25.3018 41.1011 26.1768 40.0386 26.1768H38.0386L38.1011 36.1768C38.1011 36.3643 38.1011 36.5518 38.1011 36.6768V37.6768C38.1011 39.1143 36.9761 40.1768 35.6011 40.1768H34.6011C34.4761 40.1768 34.4136 40.1768 34.3511 40.1768C34.2886 40.1768 34.1636 40.1768 34.1011 40.1768H30.6011C29.1636 40.1768 28.1011 39.1143 28.1011 37.6768V32.1768C28.1011 31.1143 27.1636 30.1768 26.1011 30.1768H22.1011C20.9761 30.1768 20.1011 31.1143 20.1011 32.1768V37.6768C20.1011 39.1143 18.9761 40.1768 17.6011 40.1768H14.1011C13.9761 40.1768 13.9136 40.1768 13.7886 40.1768C13.7261 40.1768 13.6636 40.1768 13.6011 40.1768H12.6011C11.1636 40.1768 10.1011 39.1143 10.1011 37.6768V30.6768C10.1011 30.6768 10.1011 30.6143 10.1011 30.5518V26.1768H8.10107C6.97607 26.1768 6.10107 25.3018 6.10107 24.1768C6.10107 23.6143 6.28857 23.1143 6.72607 22.6768L22.7261 8.73926C23.1636 8.30176 23.6636 8.17676 24.1011 8.17676C24.5386 8.17676 25.0386 8.36426 25.4136 8.67676L41.3511 22.6768C41.8511 23.1143 42.1011 23.6143 42.0386 24.1768Z" fill="white"/></svg>'),
        linear-gradient(180deg, rgba(#b80b4d, 0.3) 0%, rgba($orange, 0.3) 100%);
      background-size: 48px 48px, cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.select-screen .start-link {
    display: none;
  }

  &.off-screen {
    bottom: -110px;
  }
}
</style>
