export default [
  {
    slug: "exhibition",
    type: "above",
    label: "right",
    map: {
      x: 1274,
      y: 176
    }
  },
  {
    slug: "roma",
    type: "under",
    label: "left",
    map: {
      x: 1150,
      y: 314
    }
  },
  {
    slug: "albert",
    type: "under",
    label: "right",
    map: {
      x: 1239,
      y: 350
    }
  },
  {
    slug: "woolloongabba",
    type: "under",
    label: "right",
    map: {
      x: 1271,
      y: 438
    }
  },
  {
    slug: "boggo",
    type: "under",
    label: "left",
    map: {
      x: 1203,
      y: 498
    }
  },
  {
    slug: "dutton",
    type: "above",
    label: "right",
    map: {
      x: 1271,
      y: 560
    }
  },
  {
    slug: "fairfield",
    type: "above",
    label: "left",
    map: {
      x: 1190,
      y: 640
    }
  },
  {
    slug: "yeronga",
    type: "above",
    label: "right",
    map: {
      x: 1170,
      y: 732
    }
  },
  {
    slug: "yeerongpilly",
    type: "above",
    label: "left",
    map: {
      x: 1195,
      y: 827
    }
  },
  {
    slug: "moorooka",
    type: "above",
    label: "right",
    map: {
      x: 1275,
      y: 872
    }
  },
  {
    slug: "rocklea",
    type: "above",
    label: "left",
    map: {
      x: 1323,
      y: 948
    }
  },
  {
    slug: "salisbury",
    type: "above",
    label: "right",
    map: {
      x: 1408,
      y: 1022
    }
  },

  // Gold Coast
  {
    slug: "pimpama",
    type: "above",
    label: "right",
    map: {
      x: 1155,
      y: 125
    }
  },
  {
    slug: "hope-island",
    type: "above",
    label: "right",
    map: {
      x: 1256,
      y: 316
    }
  },
  {
    slug: "merrimac",
    type: "above",
    label: "right",
    map: {
      x: 1334,
      y: 825
    }
  }
];

export const GoldCoastExtras = [
  { x: 1096, y: 44 },
  { x: 1213, y: 228 },
  { x: 1255, y: 449 },
  { x: 1283, y: 667 },
  { x: 1366, y: 913 },
  { x: 1433, y: 1003 }
];
