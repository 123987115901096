<template>
  <div>
    <div class="home">
      <div class="bg-image">
        <img :src="homeimg" />
      </div>

      <div class="content">
        <div class="left">
          <img class="rail-path" :src="lineimg" />

          <router-link to="/progress/brisbane" class="map-location brisbane">
            <img :src="brisimg" />
            <h2>Brisbane</h2>
            <div class="explore-tag">Explore</div>
          </router-link>
          <router-link
            to="/progress/gold-coast"
            class="map-location gold-coast"
          >
            <img :src="gcimg" />
            <h2>Gold Coast</h2>
            <div class="explore-tag">Explore</div>
          </router-link>
        </div>

        <div class="right">
          <h1><span class="number">4</span> New underground stations</h1>
          <h2>{{ pageData.subtitle }}</h2>
          <RenderTextArea :text="pageData.overview" />
          <p class="instruction">
            <img :src="playimg" /> Tap either city to explore!
          </p>

          <div class="buttons">
            <div class="tap-here-tag">Or Tap Here</div>
            <router-link to="/progress/all-stations" class="btn">
              <Icon class="icon" icon="progress-train" />
              See All Stations
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeimg from "@/assets/images/progress/home-map.jpg";
import brisimg from "@/assets/images/progress/home-dot-bris.png";
import gcimg from "@/assets/images/progress/home-dot-gc.png";
import lineimg from "@/assets/images/progress/home-line.svg";
import playimg from "@/assets/images/taking-shape/home-play-icon.svg";

import Icon from "@/components/Icon";
import RenderTextArea from "@/components/RenderTextArea";

export default {
  data() {
    return {
      homeimg,
      brisimg,
      gcimg,
      lineimg,
      playimg
    };
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData("progress");
    }
  },
  components: {
    Icon,
    RenderTextArea
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.home {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-image {
  position: relative;
  background: #705370;

  img {
    opacity: 1;
    //width: 972px;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    left: 180px;
    opacity: 1;
    background: linear-gradient(
      90deg,
      rgba($deep-purple, 0) 46.88%,
      $deep-purple 100%
    );
  }
}

.content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  z-index: 1;

  .left,
  .right {
    position: relative;
    width: 50%;
  }

  .right {
    padding: 220px 100px 0 100px;

    h1 {
      position: relative;
      @include typeface-header;
      font-size: 72px;
      line-height: 1;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 32px;
      padding-left: 110px;
    }
    .number {
      position: absolute;
      top: -0.15em;
      left: 0;
      @include typeface-header;
      font-size: 170px;
    }

    h2 {
      @include typeface-header;
      font-size: 32px;
      line-height: 1.1;
      text-transform: uppercase;
      color: $white;
      max-width: 585px;
      margin-bottom: 64px;
    }
    ::v-deep p {
      @include typeface-body;
      font-size: 28px;
      font-weight: 400;
      color: $white;
    }
    .instruction {
      @include typeface-header;
      font-size: 32px;
      margin-top: 48px;
      img {
        width: 56px;
        height: 56px;
        vertical-align: middle;
      }
    }
    .buttons {
      position: relative;
      margin-top: 210px;
      .btn {
        margin-right: 30px;
      }
    }

    .tap-here-tag {
      position: absolute;
      top: -70px;
      left: 44px;
      background: $orange;
      color: $white;
      @include typeface-body;
      font-size: 18px;
      line-height: 32px;
      text-transform: uppercase;
      height: 32px;
      width: 180px;
      text-align: center;
      border-radius: 14px;
      &::after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        bottom: -15px;
        left: 83px;
        display: block;
        width: 28px;
        height: 16px;
        border-style: solid;
        border-width: 16px 14px 0 14px;
        border-color: $orange transparent transparent transparent;
      }
    }
  }
}

.map-location {
  position: absolute;
  width: 270px;
  height: 281px;

  h2 {
    @include typeface-header;
    font-size: 36px;
    line-height: 1;
    text-transform: uppercase;
    color: $white;
    background: $green;
    padding: 10px 32px 4px 32px;
    text-align: center;
    border-radius: 60px;
    margin-top: -24px;
    position: relative;
    z-index: 1;
  }

  img {
    width: 246px;
    height: 246px;
    margin: 0 12px;
  }

  .explore-tag {
    position: absolute;
    top: 32px;
    left: -32px;
    transform: rotate(-5deg);
    background: $orange;
    color: $white;
    @include typeface-header;
    font-size: 24px;
    line-height: 1;
    text-transform: uppercase;
    height: 28px;
    padding: 4px 16px 0 16px;
    &::after {
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 0;
      right: -15px;
      display: block;
      width: 16px;
      height: 28px;
      border-style: solid;
      border-width: 14px 0 14px 16px;
      border-color: transparent transparent transparent $orange;
    }
  }

  &.brisbane {
    left: 202px;
    top: 92px;
  }
  &.gold-coast {
    left: 620px;
    top: 737px;
  }
}

.rail-path {
  position: absolute;
  width: 398px;
  height: 650px;
  left: 361px;
  top: 293px;
}
</style>
