<template>
  <div>
    <div ref="sidebar" class="sidebar">
      <RenderRichText
        class="introduction"
        :content="pageData.sidebar.introHTML"
      />
      <div
        ref="sidebar-content"
        class="sidebar-content"
        @scroll="onContentScroll"
      >
        <RenderRichText :content="pageData.sidebar.contentHTML" />
      </div>
    </div>

    <div class="content-container">
      <div class="background-container">
        <BgImage :image="pageData.heroImage" :width="1340" />
        <div class="background-container gradient">
          <div class="the-content">
            <h2>{{ pageData.title }}</h2>
            <RenderTextArea class="intro" :text="pageData.overview" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BgImage from "@/components/BgImage";
import RenderRichText from "@/components/RichText/RenderRichText";
import RenderTextArea from "@/components/RenderTextArea";

export default {
  computed: {
    pageData() {
      return this.$store.getters.pageData("stations-gold-coast");
    }
  },
  methods: {
    onContentScroll: function(e) {
      const sidebar = this.$refs.sidebar;
      if (sidebar) {
        if (
          e.target.scrollTop <
          e.target.scrollHeight - e.target.clientHeight
        ) {
          sidebar.classList.remove("at-bottom");
        } else {
          sidebar.classList.add("at-bottom");
        }
      }
    },
    scrollContent: function() {
      const el = this.$refs["sidebar-content"];
      if (el.scrollTop < el.scrollHeight - el.clientHeight) {
        //el.scrollTop = el.scrollHeight - el.clientHeight;
        el.scrollTop = Math.min(
          el.scrollHeight - el.clientHeight,
          el.scrollTop + el.clientHeight - 60
        );
      } else {
        el.scrollTop = 0;
      }
    }
  },
  components: {
    BgImage,
    RenderRichText,
    RenderTextArea
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
@import "~scss/includes/tunnel";

::v-deep .sidebar-content {
  height: calc(100% - 142px);
  overflow-x: visible;
  scroll-behavior: smooth;
  padding-top: 40px;
  padding-bottom: 80px;
  &,
  * {
    touch-action: manipulation;
  }
  p {
    font-size: 18px;
    line-height: 1.5;
    color: $grey;
    margin-bottom: 0.5em;
  }
  ul {
    margin: 1rem 0 1rem 2rem;
    li {
      font-size: 18px;
      list-style: disc;
      color: $grey;
      margin-top: 1em;
      line-height: 1.5;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: $blue;
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  h3 {
    font-size: 24px;
    line-height: (32 / 24);
    font-weight: bold;
    margin-bottom: 0.5em;
    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0.5em;
  }
}

.content-container {
  .background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - #{$tunnel-sidebarwidth});
    bottom: 0;
    height: 100vh;
  }

  .gradient {
    background: linear-gradient(to right, $black -15%, transparent 60%);
  }

  .the-content {
    position: relative;
    h2 {
      color: $white;
    }
  }

  ::v-deep p {
    color: $white;
  }

  .the-content .intro {
    border-color: $white;
  }
}

::v-deep .sidebar {
  .introduction {
    background: #fff;
    margin: -60px 0 0 0;
    padding: 60px 0 30px;
    h2 {
      font-size: 40px;
      margin-bottom: 12px;
      color: $blue;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0.5em;
    }
  }

  .tabs {
    background: #fff;
    margin: 0 -40px;
    padding: 40px 0 0 40px;
    .tab {
      display: inline-block;
      background: #fff;
      font-size: 20px;
      line-height: 49px;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      padding: 0 24px;
      transition: 0.3s;
      &.active {
        background: $offwhite;
        color: $blue;
      }
    }
  }

  .arrow-container {
    padding-top: 30px;
  }
}
</style>
