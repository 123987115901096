<template>
  <div class="index-page">
    <div v-for="group in routeGroups" :key="group.label" class="route-group">
      <h2 class="group-label">{{ group.label }}</h2>
      <div class="routes">
        <div class="route" v-for="route in group.routes" :key="route.path">
          <router-link :to="route.path">{{ route.label }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["screens"],
  computed: {
    routes() {
      return this.$router.options.routes
        .filter(r => r.path !== "/")
        .map(r => {
          let { path, meta } = r;
          if (meta.args) path += `?${meta.args}`;
          let label = (meta && meta.label) || path;
          return { path, label };
        });
    },
    routeGroups() {
      return this.$router.options.routes.reduce((acc, route) => {
        let { path, meta = {} } = route;
        if (path === "/") return acc;

        // Resolve route card args
        const { label = path, group = "Other", args } = meta;
        if (args) path += `?${args}`;

        // Create group if required, then add route to it
        if (!acc[group]) acc[group] = { label: group, routes: [] };
        acc[group].routes.push({ path, label });
        return acc;
      }, {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
.crr-logo {
  position: fixed;
  left: 0;
  top: 65px;
  width: 411px;
  z-index: 10;
}
.index-page {
  position: fixed;
  inset: 0;
  overflow-y: scroll;
  width: 100%;
  background: $offwhite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  padding-top: 1rem;
}
.route-group {
  width: 100%;
  margin: 0 auto;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.group-label {
  grid-column: 1 / span 4;
  font-size: 36px;
}
.routes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.route {
  text-align: center;
  background: white;
  border-bottom: 0.5rem $green solid;
  border-radius: 4px;
  a {
    display: block;
    box-shadow: 0 0.5em 1em rgba(darken($green, 10%), 0.2);
    height: 100%;
    font-weight: 700;
    color: $blue;
    padding: 2rem 1rem 1.6rem;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .route-group {
    max-width: 1600px;
  }
  .group-label {
    font-size: 40px;
  }
  .routes {
    grid-template-columns: repeat(4, 1fr);
  }
  .route a {
    font-size: 1.75rem;
  }
}
</style>
