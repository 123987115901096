<template>
  <div :class="{ seen }">
    <svg
      ref="st1"
      class="st1"
      v-if="lineStyle === 1"
      x="0px"
      y="0px"
      width="2192.2px"
      height="923.2px"
      viewBox="0 0 2192.2 923.2"
    >
      <path
        :style="{ stroke }"
        class="st1"
        d="M4.2,919c0,0,238.5-238.5,248-248s13-21,13-37s0-160,0-179s4.5-28.5,19-43s243-243,252.5-252.5
                s29.5-18.5,56.5-18.5s817,0,836,0s43.5-10.5,52-19s81.5-82.5,96-97s31-19,55-19s312,0,334,0s39,3,50,14s110,107,127,124
                c8.4,8.4,13.1,12.5,49,12.5"
      />
    </svg>
    <svg
      ref="st2"
      class="st2"
      v-if="lineStyle === 2"
      x="0px"
      y="0px"
      width="1357.5px"
      height="585.5px"
      viewBox="0 0 1357.5 585.5"
    >
      <path
        :style="{ stroke }"
        class="st2"
        d="M6,341.5c0,0,282,0,313,0s54,26,54,26s170,170,194,194c13,13,23,18,44,18c27,0,435,0,466,0s43-11,50-18
                s64.5-64.5,71-71s23-28,23-45s0-252,0-277s5-38,19-52s109-108,109-108"
      />
    </svg>
    <svg
      ref="st3"
      class="st3"
      v-if="lineStyle === 3"
      x="0px"
      y="0px"
      width="1422.9px"
      height="1121.1px"
      viewBox="0 0 1422.9 1121.1"
    >
      <path
        :style="{ stroke }"
        class="st3"
        d="M4.2,1116.9c0,0,122.1-122.1,133.8-133.8c11.7-11.7,28.9-22.8,52.6-22.8s331,0,357,0s41.7-11.6,56.2-26.2
                c14.6-14.6,94.7-94.7,109-109c14.3-14.3,15.1-31.8,15.1-51.5s0-100.6,0-119.8c0-19.2,6.6-37.3,37.1-37.3s519.2,0,551.5,0
                c32.3,0,37.3-19.9,37.3-37.2c0-17.3,0-487.5,0-511s21-62.3,69-62.3"
      />
    </svg>

    <svg
      ref="st5"
      class="st5"
      v-if="lineStyle === 5"
      x="0px"
      y="0px"
      width="2431.2px"
      height="915.9px"
      viewBox="0 0 2431.2 915.9"
    >
      <path
        :style="{ stroke }"
        class="st5"
        d="M8.5,907.4c0,0,220.5-220.5,233.5-233.5c13-13,26.1-14.1,45.6-14.1c19.5,0,140,0,165.4,0
                c25.4,0,37-18.6,37-25.8s0-146.1,0-167s3.1-37.5,18.5-52.9s233.8-233.8,253.1-253.1S802.8,142,822,142s796.7,0,824.6,0
                c27.9,0,39.7-0.6,65.3-26.1s70.3-70.3,84.9-84.9S1826.8,6,1862,6c35.2,0,309.9,0,329.9,0c20,0,35-1.8,65,28.3c30,30,79.9,79.9,97,97
                s26.6,25.5,71.3,25.5"
      />
    </svg>

    <svg
      ref="st6"
      class="st6"
      v-if="lineStyle === 6"
      x="0px"
      y="0px"
      width="1454.4px"
      height="681px"
      viewBox="0 0 1454.4 681"
    >
      <path
        :style="{ stroke }"
        class="st6"
        d="M6,437c0,0,293,0,316,0s39.5,13.1,51.8,25.4s193.3,193.3,203,203c9.7,9.7,19,9.7,36,9.7s436.6,0,462.1,0
                s41.4-5.5,54.9-19s60.7-60.7,73.7-73.7s19.7-33.3,19.7-50c0-16.7,0-244,0-266s5.2-40.1,26.4-61.4S1446,8.5,1446,8.5"
      />
    </svg>

    <svg
      ref="st7"
      class="st7"
      v-if="lineStyle === 7"
      x="0px"
      y="0px"
      width="1503.9px"
      height="1123.8px"
      viewBox="0 0 1503.9 1123.8"
    >
      <path
        :style="{ stroke }"
        class="st7"
        d="M4.2,1119.5c0,0,114.4-114.4,135.2-135.2c20.8-20.8,30.1-25.8,67.5-25.8s294,0,330,0s46.3,0,78-31.7
                c31.7-31.7,78.4-78.4,97.3-97.3c18.9-18.9,20.7-36.1,20.7-57.7c0-21.6,0-96.6,0-119.8s7.8-37.2,37.2-37.2c29.4,0,527.1,0,557.9,0
                c30.8,0,31.2-25.3,31.2-42c0-10.7,0-365.3,0-395.3s6.5-37.7,25.5-56.7S1499.6,4.2,1499.6,4.2"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: ["lineStyle", "seenLines", "stroke"],
  computed: {
    seen() {
      return this.seenLines && this.seenLines.includes(this._uid);
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
path {
  fill: none;
  stroke-width: 12;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-dashoffset: 3200;
  stroke-dasharray: 3200;
  .seen & {
    animation: stroke forwards ease-in-out 3s 1s;
  }
}
[class^="st"] {
  position: absolute;
  z-index: -100;
}
.sta {
  top: -30px;
  left: -50px;
}

.st1 {
  bottom: -40px;
  left: -297px;
}
.st2 {
  left: -1254px;
  top: -10px;
}
.st3 {
  left: -758px;
  bottom: -30px;
}
.st5 {
  left: -522px;
  bottom: -31px;
}
.st6 {
  left: -1255px;
  top: -104px;
}
.st7 {
  left: -763px;
  bottom: -30px;
}
</style>
