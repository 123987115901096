var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'checkmate-experience',
    'mode-' + _vm.mode,
    {
      noSearchResults: _vm.noSearchResults,
      zoomedOut: _vm.zoomedOut,
      zoomedClose: _vm.zoomedClose,
      settingPostcode: _vm.mode === 'postcode' && _vm.station === null
    }
  ]},[_c('Map',_vm._b({ref:"map",on:{"zoom":_vm.onZoom,"station":_vm.onStation,"line":_vm.onLine}},'Map',{ mode: _vm.mode, station: _vm.station, line: _vm.line },false)),_c('div',{staticClass:"gradient"}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"intro-actions"},[_c('Button',{attrs:{"icon":"postcode","classes":"large green","label":"Enter your postcode"},on:{"clicked":_vm.enterPostcode}}),_c('Button',{attrs:{"icon":"pan","classes":"large orange","label":"See our new stations"},on:{"clicked":_vm.enterFreeExplore}})],1),_c('div',{staticClass:"actions"},[_c('span',{on:{"click":function($event){$event.preventDefault();return _vm.goHome.apply(null, arguments)}}},[_c('Icon',{attrs:{"icon":"home"}})],1),(_vm.mode !== 'postcode')?_c('Button',{attrs:{"icon":"postcode","classes":"large green","label":"Enter your postcode"},on:{"clicked":_vm.enterPostcode}}):_vm._e(),(_vm.mode !== 'explore')?_c('Button',{attrs:{"icon":"pan","classes":"large orange","label":"See our new stations"},on:{"clicked":_vm.enterFreeExplore}}):_vm._e()],1),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.showBenefitsContent)?_c('div',{class:[
        'benefits-content',
        'orange',
        ..._vm.benefitsClasses,
        { hasImages: _vm.hasImages }
      ]},[(_vm.stationObject && _vm.stationObject.New)?_c('span',{staticClass:"tag"},[_vm._v("New")]):_vm._e(),(_vm.stationObject && _vm.stationObject.Updated)?_c('span',{staticClass:"tag"},[_vm._v("Updated")]):_vm._e(),_c('p',{staticClass:"benefits-content-title"},[_vm._v(_vm._s(_vm.benefitsTitle))]),(_vm.stationObject && _vm.stationObject.Description)?_c('p',{staticClass:"benefits-content-description"},[_vm._v(" "+_vm._s(_vm.stationObject.Description)+" ")]):_vm._e(),(_vm.mode !== 'benefits')?_c('div',{staticClass:"benefits-content-content"},_vm._l((_vm.stationBenefitsContentColumns),function(benefit,i){return _c('div',{key:'benefit-column-' + i,staticClass:"benefit-column"},[_c('div',{staticClass:"benefit-icon"},[_c('Icon',{attrs:{"icon":benefit.icon}})],1),_c('h4',{staticClass:"benefit-heading"},[_vm._v(_vm._s(benefit.subhead))]),_c('p',[_vm._v(_vm._s(benefit.benefit))])])}),0):_vm._e(),(_vm.hasImages)?_c('div',{staticClass:"benefits-content-images"},_vm._l((_vm.stationObject.Images),function(image,i){return _c('div',{key:i,staticClass:"benefits-content-image",style:(`background-image: url(${image})`)})}),0):_vm._e(),(_vm.station)?_c('div',{staticClass:"benefits-content-border",style:(_vm.benefitsBorderStyle)},[_c('span',{staticClass:"station-line"},[_vm._v(_vm._s(_vm.station)),(_vm.lineLabel)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.lineLabel)}}):_vm._e()]),_c('span',{staticClass:"close",style:(_vm.benefitsBorderStyle),on:{"click":_vm.resetTrains}},[_c('Icon',{attrs:{"icon":"close"}})],1)]):_vm._e()]):_vm._e()]),_c('transition',[(_vm.showNoResults)?_c('MapNoResults',{staticClass:"map-search-no-results"}):_vm._e()],1),_c('transition',[(_vm.showSearch)?_c('MapSearch',{staticClass:"map-search",on:{"noResults":_vm.onNoResults,"home":_vm.goHome,"station":_vm.onStation}}):_vm._e()],1),_c('h1',{staticClass:"small",on:{"click":_vm.goHome}},[_vm._m(2),_vm._m(3)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('span',{staticClass:"h1 future"},[_c('span',{staticClass:"text"},[_vm._v("My future")])]),_c('span',{staticClass:"h1 journey"},[_c('span',{staticClass:"text"},[_vm._v("journey")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"intro-copy"},[_vm._v(" Find out what"),_c('br'),_vm._v("Cross River Rail"),_c('br'),_vm._v("means for you ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"h1"},[_c('span',{staticClass:"text"},[_vm._v("My future")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"h1 journey"},[_c('span',{staticClass:"text"},[_vm._v("journey")])])
}]

export { render, staticRenderFns }