<template>
  <div :class="['search', step]">
    <div class="home" @click.prevent="$emit('home')">
      <Icon icon="home" />
    </div>
    <div :class="['postcode-value-display', { empty: value.length === 0 }]">
      <p v-if="step === 'enterPostcode'" class="label enter-your-postcode">
        Enter your postal code
      </p>
      <div class="keyboard-value">
        <span :class="['value value-0', { empty: valueChar(0) === null }]">{{
          valueChar(0) || "4"
        }}</span>
        <span :class="['value value-1', { empty: valueChar(1) === null }]">{{
          valueChar(1) || "0"
        }}</span>
        <span :class="['value value-2', { empty: valueChar(2) === null }]">{{
          valueChar(2) || "0"
        }}</span>
        <span :class="['value value-3', { empty: valueChar(3) === null }]">{{
          valueChar(3) || "0"
        }}</span>
        <div @click.prevent="reset" class="reset">
          <Icon icon="close" />
        </div>
      </div>
    </div>
    <div v-if="step === 'enterPostcode'" class="keyboard">
      <div class="container">
        <div
          @click.prevent="onKey(key)"
          v-for="key in [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]"
          :key="key"
          class="keyboard-key-wrapper"
        >
          <div class="keyboard-key key">
            <span class="char">{{ key }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="step !== 'enterPostcode'" class="station-picker">
      <p class="label">Choose your nearest train station</p>

      <div class="container">
        <div class="stations">
          <div
            @click.prevent="setStation(s)"
            v-for="(s, i) in stationChoices"
            :key="i"
            :class="['station', { active: s.Station === station }]"
          >
            <Icon icon="train" />
            <div class="station__name">{{ s.Station }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="step !== 'enterPostcode' && lineChoices > 0 && station"
      class="station-picker"
    >
      <p class="label">Choose your train line</p>
      <div class="container">
        <div class="stations">
          <div
            @click.prevent="line = i"
            v-for="(l, i) in lineChoices"
            :key="i"
            :class="['station', { active: line === i }]"
          >
            <div class="station__name">{{ l.line }}</div>
          </div>
        </div>
      </div>
    </div>
    <Button @clicked="onContinue" :disabled="!valid" :label="buttonLabel" />
  </div>
</template>

<script>
import Loader from "@/lib/LoadData";
import Button from "@/components/Button";
import Icon from "@/components/Icon";

export default {
  name: "search",
  data() {
    return {
      postcodes: [],
      stations: [],
      value: "",
      station: null,
      line: null,
      postcodeSet: false,
      stationSet: false,
      lineSet: false
    };
  },
  mounted() {
    this.$emit("noResults", false);
  },
  computed: {
    buttonLabel() {
      if (
        this.step === "enterPostcode" &&
        this.value.length === 4 &&
        this.stationChoices.length === 0
      ) {
        return "No results";
      }
      return "Continue";
    },
    stationChoices() {
      return this.postcodes.filter(n => n.Postcode.toString() === this.value);
    },
    activeStation() {
      return this.stations.find(n => n.Station === this.station);
    },
    lineChoices() {
      let choices = [];
      for (let i = 0; i <= this.lineCount.length; i++) {
        choices.push({
          line: this.activeStation[`Line ${i}`],
          benefit1: {
            icon: this.activeStation[`Line${i}_Benefit 1 icon`],
            subhead: this.activeStation[`Line${i}_Benefit 1 subhead`]
          },
          benefit2: {
            icon: this.activeStation[`Line${i}_Benefit 2 icon`],
            subhead: this.activeStation[`Line${i}_Benefit 2 subhead`]
          }
        });
      }
      return choices;
    },
    step() {
      if (!this.postcodeSet) {
        return "enterPostcode";
      }
      return "setStation";
    },
    valid() {
      if (
        this.step === "enterPostcode" &&
        this.value.length === 4 &&
        this.stationChoices.length >= 1
      ) {
        return true;
      }
      if (this.step === "setStation" && this.station !== null) {
        return true;
      }
      return false;
    },
    lineCount() {
      let stationObject = this.activeStation;
      if (!stationObject) {
        return 0;
      }
      if (stationObject["Line 5"] !== 0) return 5;
      if (stationObject["Line 4"] !== 0) return 4;
      if (stationObject["Line 3"] !== 0) return 3;
      if (stationObject["Line 2"] !== 0) return 2;
      if (stationObject["Line 1"] !== 0) return 1;
      return 0;
    }
  },
  methods: {
    onContinue() {
      if (this.step === "enterPostcode") {
        this.postcodeSet = true;
      }
      if (this.step === "setStation") {
        this.stationSet = true;
        this.$emit("station", this.station);
      }
    },
    setStation(station) {
      this.station = station.Station;
    },
    valueChar(index) {
      return this.value.split("")[index] || null;
    },
    onKey(key) {
      if (this.value.length === 4) {
        return false;
      }
      this.value = this.value + key.toString();
    },
    reset() {
      this.value = "";
      this.station = null;
      this.line = null;
      this.postcodeSet = false;
      this.stationSet = false;
      this.lineSet = false;
      this.$emit("noResults", false);
    }
  },
  async created() {
    const [stations, postcodes] = await Loader.batch([
      "local://stations",
      "local://postcodes"
    ]);
    this.stations = stations;
    this.postcodes = postcodes;
  },
  beforeDestroy() {
    this.$emit("noResults", false);
  },
  watch: {
    buttonLabel(next) {
      this.$emit("noResults", next === "No results");
    }
  },
  components: {
    Button,
    Icon
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/util";
.home {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 1;
  transition: all 0.4s;
  svg {
    width: 40px;
    height: 40px;
    fill: white;
  }
  .setStation & {
    top: 2.6rem;
  }
}

.keyboard-value {
  color: white;
  font-size: 60px;
  text-align: center;
  display: block;
  position: relative;
  .value {
    font-weight: 700;
    display: inline-block;
    width: 0.6em;
    &.empty {
      opacity: 0.25;
    }
  }
  .reset {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #ecf1f5;
    transform: translate(7rem, -50%);
    transition: all 0.5s;
    opacity: 1;
    visibility: visible;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: $grey;
      height: 1rem;
      width: 1rem;
    }
  }
}
.postcode-value-display {
  position: relative;
  border-radius: 4px 4px 0 0;
  padding: 30px 0 30px;
  background: $blue;
  &.empty {
    .reset {
      opacity: 0;
      visibility: hidden;
      transform: translate(7rem, -50%) scale(0);
    }
  }
  .enterPostcode & {
    padding: 80px 0 30px;
  }
}
.label {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: white;
}
.enter-your-postcode {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
}
.search {
  background: rgba(#e2e3e5, 0.95);
  padding: 0;
  border-radius: 2px;
  box-shadow: 0 1em 3em rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  transform: translateY(2rem);
  border-radius: 4px;
  width: 650px;
  margin: auto;
  &.enterPostcode {
    width: 430px;
  }
  .btn {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    font-size: 2rem;
    white-space: nowrap;
  }
  @include enter {
    opacity: 1;
    transform: translateY(0rem);
  }
  @include leave {
    opacity: 0;
    transform: translateY(2rem);
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.keyboard {
  display: flex;
  width: 100%;
  padding: 4rem 3rem 80px;
  margin-bottom: -14px;
  background: white;
  text-align: center;
  border-radius: 0 0 4px 4px;

  .keyboard-key-wrapper {
    width: 20%;
    margin-bottom: 14px;
  }
  .container {
    max-width: 350px;
    display: flex;
    flex-wrap: wrap;
  }
  .key {
    background: $orange;
    height: 56px;
    width: 56px;
    border-radius: 50%;
    color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: scaleIn 0.6s forwards;
    transform: scale(0);
    .char {
      font-size: 32px;
      font-weight: 700;
    }
  }
  @for $i from 0 through 10 {
    .keyboard-key-wrapper:nth-child(#{$i}) .key {
      animation-delay: #{0.02 * $i}s;
    }
  }
}
.station-picker {
  padding: 3rem 2rem 80px;
  position: relative;
  .label {
    color: black;
  }
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    height: 2px;
    background: #efefef;
    width: 82%;
    transform: translateX(-50%);
  }
}
.stations {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  min-width: 590px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.station {
  background: #efefef;
  height: 150px;
  display: inline-flex;
  flex-basis: 150px;
  margin-left: 15px;
  margin-right: 15px;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  animation: scaleIn 0.6s forwards;
  transform: scale(0);
  transition: all 0.3s;
  &.active {
    background: $blue;
    border-bottom: 4px $orange solid;
    .icon {
      fill: white;
    }
    .station__name {
      color: white;
    }
  }
  .icon {
    height: 3.5rem;
    width: 3.5rem;
    fill: $grey;
    margin-bottom: 1.5rem;
  }
  &__name {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: $charcoal;
  }
}
</style>
