<template>
  <svg @click.prevent="$emit('clicked')" class="icon" :class="id">
    <use :xlink:href="`#${id}`"></use>
  </svg>
</template>

<script>
export default {
  name: "icon",
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  computed: {
    id() {
      return `icon-${this.icon}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 1em;
  height: 1em;
  width: 1em;
}
</style>
