<template>
  <div>
    <div class="station-construction">
      <div class="bg-image">
        <CMSImage class="station-img" :image="stationData.heroImage" />
        <img class="overlay" :src="bgOverlay" />
      </div>

      <div class="content">
        <header>
          <h1>{{ stationData.name }}</h1>
          <h2>/</h2>
          <h2>Construction Progress</h2>
        </header>

        <ConstructionCarousel
          title="Progress Work"
          :slides="stationData.constructionProgress.slides"
        />
      </div>
    </div>
    <StationNav :station="stationData" />
  </div>
</template>

<script>
import playimg from "@/assets/images/taking-shape/home-play-icon.svg";
import bgOverlay from "@/assets/images/progress/bg-overlay.png";
import CMSImage from "@/components/CMSImage";
import ConstructionCarousel from "@/components/ConstructionCarousel";
import StationNav from "@/pages/progress/StationNav";

export default {
  props: ["slug"],
  data() {
    return {
      playimg,
      bgOverlay
    };
  },
  computed: {
    stationData() {
      const { brisbane, goldCoast } = this.$store.getters.pageData("progress");
      return [...brisbane, ...goldCoast].find(item => item.slug === this.slug);
    }
  },
  components: {
    CMSImage,
    ConstructionCarousel,
    StationNav
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.station-construction {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.bg-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: $deep-purple;
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .station-img {
    opacity: 0.25;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba($black, 0) 0%,
      rgba($black, 0.75) 100%
    );
  }
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: $nav-height;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 84px;

    h1 {
      @include typeface-gradient;
      font-size: 64px;
      margin: 0;
      padding-right: 36px;
    }
    h2 {
      @include typeface-header;
      color: $white;
      text-transform: uppercase;
      margin: 0 36px 0 0;
    }
  }
}
</style>
<style lang="scss">
@import "~scss/includes/vars";
@import "~scss/includes/progress";
.modals-portal .image-popup {
  .overlay {
    background: linear-gradient(180deg, rgba($black, 0.25), rgba($black, 0.75));
  }
  .container {
    .bg {
      background: none;
    }
    img.imghi,
    img.imglo {
      border-radius: 15px;
    }
  }
  .video-control.close {
    background: none;
    box-shadow: none;
    height: auto;
    right: auto;
    top: auto;
    left: 24px;
    bottom: 24px;
    .icon {
      height: 24px;
      width: 24px;
    }
    span {
      @include typeface-header;
      text-transform: uppercase;
    }
  }
}
</style>
