<template>
  <svg
    aria-hidden="true"
    style="position: absolute; width: 0; height: 0; overflow: hidden;"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="icon-arrow-left" viewBox="0 0 18.3 17">
        <path
          d="M8.7,16.9l0.9-0.9c0.1-0.1,0.1-0.4,0-0.5L3.8,9.5h14.1c0.2,0,0.4-0.2,0.4-0.4V7.8c0-0.2-0.2-0.4-0.4-0.4H3.8
                    l5.8-5.9c0.1-0.1,0.1-0.4,0-0.5L8.7,0.1C8.5,0,8.3,0,8.2,0.1c0,0,0,0,0,0L0.1,8.2C0,8.4,0,8.6,0.1,8.7l8.1,8.1
                    C8.3,17,8.5,17,8.7,16.9C8.7,16.9,8.7,16.9,8.7,16.9z"
        />
      </symbol>

      <symbol id="icon-arrow-right" viewBox="0 0 18.3 17">
        <path
          d="M9.6,0.1L8.7,1.1c-0.1,0.1-0.1,0.4,0,0.5l5.8,5.9H0.4C0.2,7.4,0,7.6,0,7.8v1.4c0,0.2,0.2,0.4,0.4,0.4h14.1
                    l-5.8,5.9c-0.1,0.1-0.1,0.4,0,0.5l0.9,0.9c0.1,0.1,0.4,0.1,0.5,0c0,0,0,0,0,0l8.1-8.1c0.1-0.1,0.1-0.4,0-0.5l-8.1-8.1
                    C10,0,9.7,0,9.6,0.1C9.6,0.1,9.6,0.1,9.6,0.1z"
        />
      </symbol>

      <symbol id="icon-chevron-left" viewBox="0 0 28.3 47.71">
        <path
          d="M0.52,22.59L22.31,0.53c0.7-0.7,1.83-0.7,2.52,0l2.94,2.95c0.7,0.7,0.7,1.83,0,2.53L10.19,23.85
	                L27.78,41.7c0.7,0.7,0.7,1.83,0,2.53l-2.94,2.95c-0.7,0.7-1.83,0.7-2.52,0L0.52,25.12C-0.17,24.42-0.17,23.29,0.52,22.59z"
        />
      </symbol>
      <symbol id="icon-chevron-right" viewBox="0 0 28.3 47.71">
        <path
          d="M27.78,25.12L5.99,47.18c-0.7,0.7-1.83,0.7-2.52,0l-2.94-2.95c-0.7-0.7-0.7-1.83,0-2.53l17.59-17.84
	                L0.52,6.01c-0.7-0.7-0.7-1.83,0-2.53l2.94-2.95c0.7-0.7,1.83-0.7,2.52,0l21.79,22.06C28.47,23.29,28.47,24.42,27.78,25.12z"
        />
      </symbol>

      <symbol id="icon-chevron-up" viewBox="0 0 47.71 28.3">
        <path
          d="M25.12,0.52l22.06,21.79c0.7,0.7,0.7,1.83,0,2.52l-2.95,2.94c-0.7,0.7-1.83,0.7-2.53,0L23.85,10.19
	                L6.01,27.78c-0.7,0.7-1.83,0.7-2.53,0l-2.95-2.94c-0.7-0.7-0.7-1.83,0-2.52L22.59,0.52C23.29-0.17,24.42-0.17,25.12,0.52z"
        />
      </symbol>
      <symbol id="icon-chevron-down" viewBox="0 0 47.71 28.3">
        <path
          d="M22.59,27.78L0.53,5.99c-0.7-0.7-0.7-1.83,0-2.52l2.95-2.94c0.7-0.7,1.83-0.7,2.53,0l17.84,17.59
	                L41.7,0.52c0.7-0.7,1.83-0.7,2.53,0l2.95,2.94c0.7,0.7,0.7,1.83,0,2.52L25.12,27.78C24.42,28.47,23.29,28.47,22.59,27.78z"
        />
      </symbol>

      <symbol id="icon-interactive" viewBox="0 0 29.9 32.1">
        <path
          d="M29.9,26c-0.1-0.5-0.1-1.2-0.1-2.1c0-2.7,0.1-7.3-3.4-12.7l-2.1-3.2c-0.4-0.6-1-1-1.7-1.2c-0.7-0.2-1.4,0-2.1,0.4
                    c-0.3,0.2-0.6,0.4-0.8,0.7c-0.3-0.2-0.6-0.3-0.9-0.4c-0.7-0.2-1.5,0-2.1,0.3c0,0-0.1,0-0.1,0.1c-0.4,0.3-0.7,0.6-0.9,1
                    c-0.8-0.3-1.6-0.2-2.4,0.2c-0.1,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.4-0.7,0.6L9.2,5C8.7,4.3,8.1,3.9,7.3,3.7C6.5,3.6,5.7,3.7,5,4.1
                    c0,0-0.2,0.1-0.2,0.1C4.2,4.7,3.8,5.3,3.6,6C3.4,6.8,3.6,7.6,4,8.3l5.5,8.4c-0.2,0.1-0.3,0.2-0.5,0.3l-0.2,0.1
                    c-0.1,0.1-0.2,0.2-0.4,0.2c-0.7,0.4-1.2,1.1-1.4,2c-0.2,0.8,0,1.7,0.4,2.3l2,3c0,0.1,0.1,0.1,0.1,0.2c2.5,3.3,5.4,4.1,7.4,4.7
                    l0.2,0.1c0.4,0.1,0.8,0.2,1.1,0.3c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.8,0.2,1.1,0.6,1.6,1.4l0.2,0.3c0.2,0.3,0.5,0.4,0.8,0.4
                    c0.1,0,0.3,0,0.5-0.2c0.2-0.1,0.4-0.4,0.4-0.6c0.1-0.2,0-0.5-0.1-0.7l-0.2-0.3c-0.6-0.9-1.2-1.9-2.8-2.2c-0.3-0.2-0.8-0.3-1.2-0.4
                    l-0.3-0.1c-2-0.5-4.7-1.3-6.8-4.5l-1.7-2.5C9,20.3,8.9,20,9,19.7c0.1-0.3,0.3-0.6,0.5-0.7c0.1,0,0.2-0.1,0.2-0.1
                    c0.1,0,0.1-0.1,0.2-0.1c0.2-0.1,0.4-0.3,0.6-0.4c0,0,0,0,0,0l2.3,3.5c0.2,0.3,0.5,0.4,0.8,0.4c0.1,0,0.3,0,0.5-0.2
                    c0.2-0.1,0.4-0.4,0.4-0.6c0-0.2,0-0.5-0.2-0.7L5.7,7.2C5.5,7,5.4,6.7,5.5,6.4c0-0.3,0.2-0.5,0.4-0.6c0,0,0,0,0,0l0.1,0
                    c0.2-0.2,0.5-0.2,0.8-0.1c0.3,0,0.5,0.2,0.7,0.5l6.9,10.4c0.2,0.3,0.5,0.4,0.8,0.4c0.1,0,0.3,0,0.5-0.2c0.2-0.1,0.4-0.4,0.4-0.6
                    c0-0.2,0-0.5-0.2-0.7l-2.2-3.4c-0.1-0.2-0.2-0.4-0.1-0.6c0.1-0.2,0.2-0.4,0.4-0.5c0.4-0.3,1-0.1,1.2,0.3l2.2,3.3
                    c0.2,0.3,0.5,0.4,0.8,0.4c0.1,0,0.3,0,0.5-0.2c0.2-0.1,0.4-0.4,0.4-0.6c0-0.2,0-0.5-0.2-0.7l-1.8-2.8c-0.1-0.2-0.2-0.4-0.1-0.6
                    c0-0.1,0.1-0.4,0.4-0.5c0.2-0.1,0.5-0.2,0.7-0.1c0.2,0.1,0.5,0.2,0.6,0.4l2.2,3.3c0.2,0.3,0.5,0.4,0.8,0.4c0.1,0,0.3,0,0.5-0.2
                    c0.2-0.1,0.4-0.4,0.4-0.6c0-0.2,0-0.5-0.2-0.7l-1.5-2.2c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.4-0.1,0.6-0.1
                    c0.2,0,0.4,0.2,0.5,0.3l2.1,3.2c3.2,4.8,3.2,8.9,3.2,11.6v0.1c0,1.1,0,1.9,0.2,2.6c0.1,0.4,0.5,0.7,0.9,0.7c0.1,0,0.2,0,0.2,0
                    c0.3,0,0.5-0.2,0.6-0.4C29.9,26.5,30,26.3,29.9,26z"
        />
        <path
          d="M4.5,11c0.4,0.2,0.6,0.6,0.4,1c-0.2,0.4-0.6,0.6-1,0.4C1,11.3-0.6,8.1,0.2,5c0.9-3.5,4.5-5.6,8-4.8c3,0.7,5,3.3,4.9,6.3
                    c0,0.4-0.4,0.8-0.8,0.8s-0.8-0.4-0.8-0.8c0.1-2.2-1.4-4.2-3.7-4.7C5.3,1.1,2.5,2.8,1.8,5.4C1.1,7.7,2.3,10.1,4.5,11z"
        />
      </symbol>

      <symbol id="icon-Line" viewBox="0 0 31.6 33.6">
        <path
          d="M30.3,14.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-5.5V7.2h5.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-5.5
                    V1.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v3.4H9.3V1.3C9.3,0.6,8.7,0,8,0S6.7,0.6,6.7,1.3v3.4H1.3C0.6,4.6,0,5.2,0,5.9
                    s0.6,1.3,1.3,1.3h5.4v4.6H1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h5.4v4.6H1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h5.4v4.6
                    H1.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h5.4v2.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-2.9h12.9v3.4c0,0.7,0.6,1.3,1.3,1.3
                    s1.3-0.6,1.3-1.3v-3.4h5.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-5.5v-4.6h5.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3h-5.5v-4.6
                    H30.3z M9.3,7.2h12.9v4.6H9.3V7.2z M22.2,26.3H9.3v-4.6h12.9V26.3z M22.2,19.1H9.3v-4.6h12.9V19.1z"
        />
      </symbol>

      <symbol id="icon-pan" viewBox="0 0 36 48">
        <path
          d="M36,40c-0.2-0.7-0.2-1.8-0.2-3c0-4,0-10.1-4.7-17.5l0,0L28.1,15c-0.5-0.8-1.3-1.3-2.2-1.5
                    c-0.9-0.2-1.9,0-2.6,0.5l0,0c-0.5,0.3-0.8,0.7-1.1,1.2c-0.4-0.3-0.9-0.6-1.4-0.6c-1-0.2-1.9,0-2.7,0.5c0,0,0,0-0.1,0.1
                    c-0.6,0.4-1.1,0.9-1.3,1.6c-1-0.4-2.2-0.4-3.3,0.2c0,0-0.1,0-0.1,0.1c-0.4,0.3-0.8,0.6-1.1,1.1l-4.7-7.3C6.8,9.9,5.9,9.3,4.9,9.1
                    c-1-0.2-2.1,0-3,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,0.6-1.5,1.4-1.7,2.4s0,2,0.5,2.9l7.9,12c-0.4,0.2-0.7,0.4-1.1,0.7
                    C7.1,27.8,7,27.9,6.8,28c-0.9,0.6-1.6,1.5-1.8,2.5c-0.2,1,0,2.1,0.5,3.1l2.7,4.2c0,0.1,0.1,0.1,0.2,0.2c3.3,4.5,7.4,5.6,10.1,6.4
                    c0.7,0.2,1.4,0.4,1.9,0.6c0.1,0,0.2,0,0.3,0.1l0,0c1.3,0.3,1.7,0.9,2.4,2l0.3,0.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2
                    c0.5-0.3,0.7-0.9,0.4-1.4L25,46c-0.8-1.3-1.7-2.5-3.8-3l0,0c-0.6-0.3-1.3-0.4-2-0.6c-2.8-0.8-6.6-1.8-9.6-6.4l-2.3-3.5
                    C7,32,6.9,31.5,7,31c0.1-0.5,0.4-0.9,0.9-1.2l0,0c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.3,0.9-0.6,1.1-0.6c0,0,0.1,0,0.2,0.2l3.2,4.9
                    c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-3.2-4.9l-9.1-14c-0.3-0.4-0.4-0.9-0.2-1.3
                    C2.3,12,2.6,11.6,3,11.4c0,0,0,0,0,0h0C3.5,11.1,4,11,4.5,11.2c0.5,0.1,0.9,0.4,1.1,0.8l6.5,9.9l3,4.6c0.2,0.3,0.5,0.5,0.9,0.5
                    c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-3.1-4.7c-0.4-0.7-0.2-1.6,0.5-2l0,0c0.7-0.4,1.6-0.2,2.1,0.5l3,4.6
                    c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-2.5-3.9c-0.2-0.3-0.3-0.7-0.2-1.1c0.1-0.4,0.3-0.7,0.7-0.9
                    l0,0c0.4-0.2,0.7-0.3,1.1-0.2c0.4,0.1,0.7,0.3,1,0.6l1,1.5l2,3.1c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2
                    c0.5-0.3,0.7-0.9,0.3-1.4l-2-3.1c-0.2-0.3-0.3-0.7-0.2-1c0.1-0.3,0.3-0.6,0.6-0.9l0,0c0.3-0.2,0.7-0.3,1.1-0.2
                    c0.4,0.1,0.7,0.3,0.9,0.6l2.9,4.4c4.5,6.8,4.4,12.6,4.4,16.4c0,1.5,0,2.6,0.2,3.5c0.1,0.5,0.6,0.8,1,0.8c0.1,0,0.2,0,0.3,0
                    C35.8,41.1,36.1,40.5,36,40z"
        />
        <path
          d="M36,40c-0.2-0.7-0.2-1.8-0.2-3c0-4,0-10.1-4.7-17.5l0,0L28.1,15c-0.5-0.8-1.3-1.3-2.2-1.5
                    c-0.9-0.2-1.9,0-2.6,0.5l0,0c-0.5,0.3-0.8,0.7-1.1,1.2c-0.4-0.3-0.9-0.6-1.4-0.6c-1-0.2-1.9,0-2.7,0.5c0,0,0,0-0.1,0.1
                    c-0.6,0.4-1.1,0.9-1.3,1.6c-1-0.4-2.2-0.4-3.3,0.2c0,0-0.1,0-0.1,0.1c-0.4,0.3-0.8,0.6-1.1,1.1l-4.7-7.3C6.8,9.9,5.9,9.3,4.9,9.1
                    c-1-0.2-2.1,0-3,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.9,0.6-1.5,1.4-1.7,2.4c-0.2,1,0,2,0.5,2.9l7.9,12c-0.4,0.2-0.7,0.4-1.1,0.7
                    C7.1,27.8,7,27.9,6.8,28c-0.9,0.6-1.6,1.5-1.8,2.5c-0.2,1,0,2.1,0.5,3.1l2.7,4.2c0,0.1,0.1,0.1,0.2,0.2c3.3,4.5,7.4,5.6,10.1,6.4
                    c0.7,0.2,1.4,0.4,1.9,0.6c0.1,0,0.2,0,0.3,0.1l0,0c1.3,0.3,1.7,0.9,2.4,2l0.3,0.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2
                    c0.5-0.3,0.7-0.9,0.4-1.4L25,46c-0.8-1.3-1.7-2.5-3.8-3l0,0c-0.6-0.3-1.3-0.4-2-0.6c-2.8-0.8-6.6-1.8-9.6-6.4l-2.3-3.5
                    C7,32,6.9,31.5,7,31c0.1-0.5,0.4-0.9,0.9-1.2l0,0c0.2-0.1,0.4-0.3,0.6-0.4c0.4-0.3,0.9-0.6,1.1-0.6c0,0,0.1,0,0.2,0.2l3.2,4.9
                    c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-3.2-4.9l-9.1-14c-0.3-0.4-0.4-0.9-0.2-1.3
                    C2.3,12,2.6,11.6,3,11.4c0,0,0,0,0,0h0C3.5,11.1,4,11,4.5,11.2c0.5,0.1,0.9,0.4,1.1,0.8l6.5,9.9l3,4.6c0.2,0.3,0.5,0.5,0.9,0.5
                    c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-3.1-4.7c-0.4-0.7-0.2-1.6,0.5-2l0,0c0.7-0.4,1.6-0.2,2.1,0.5l3,4.6
                    c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2c0.5-0.3,0.7-0.9,0.3-1.4l-2.5-3.9c-0.2-0.3-0.3-0.7-0.2-1.1c0.1-0.4,0.3-0.7,0.7-0.9
                    l0,0c0.4-0.2,0.7-0.3,1.1-0.2c0.4,0.1,0.7,0.3,1,0.6l1,1.5l2,3.1c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.6-0.2
                    c0.5-0.3,0.7-0.9,0.3-1.4l-2-3.1c-0.2-0.3-0.3-0.7-0.2-1s0.3-0.6,0.6-0.9l0,0c0.3-0.2,0.7-0.3,1.1-0.2c0.4,0.1,0.7,0.3,0.9,0.6
                    l2.9,4.4c4.5,6.8,4.4,12.6,4.4,16.4c0,1.5,0,2.6,0.2,3.5c0.1,0.5,0.6,0.8,1,0.8c0.1,0,0.2,0,0.3,0C35.8,41.1,36.1,40.5,36,40z"
        />
        <path
          d="M7.8,8l3.5,2c0.2,0.1,0.5,0.1,0.7-0.1c0,0,0.1-0.1,0.1-0.1c0.2-0.3,0.1-0.6-0.2-0.8l-1.6-0.9l4.2,0l0,4.2
                    l-0.9-1.6c-0.2-0.3-0.5-0.4-0.8-0.2c-0.3,0.2-0.4,0.5-0.2,0.8l2,3.5c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.2
                    c0.2,0,0.4-0.1,0.5-0.3l2-3.5c0.2-0.3,0.1-0.6-0.2-0.8c-0.3-0.2-0.6-0.1-0.8,0.2l-0.9,1.6l0-4.2l4.2,0L18.2,9
                    c-0.3,0.2-0.4,0.5-0.2,0.8c0.2,0.3,0.5,0.4,0.8,0.2l3.5-2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.4-0.3-0.5
                    l-3.5-2c-0.3-0.2-0.6-0.1-0.8,0.2c-0.2,0.3-0.1,0.6,0.2,0.8l1.6,0.9l-4.2,0l0-4.2l0.9,1.6c0.2,0.3,0.5,0.4,0.8,0.2
                    c0.3-0.2,0.4-0.5,0.2-0.8l-2-3.5C15.4,0.1,15.2,0,15,0c-0.2,0-0.4,0.1-0.5,0.3l-2,3.5c-0.1,0.2-0.1,0.5,0.1,0.7c0,0,0.1,0.1,0.1,0.1
                    c0.3,0.2,0.6,0.1,0.8-0.2l0.9-1.6l0,4.2l-4.2,0L11.8,6c0.3-0.2,0.4-0.5,0.2-0.8c-0.2-0.3-0.5-0.4-0.8-0.2L7.8,7
                    C7.6,7.1,7.5,7.3,7.5,7.5C7.5,7.7,7.6,7.9,7.8,8"
        />
      </symbol>

      <symbol id="icon-play-button" viewBox="0 0 32.5 32.5">
        <path
          d="M16.2,32.5C7.3,32.5,0,25.2,0,16.2S7.3,0,16.2,0s16.2,7.3,16.2,16.2S25.2,32.5,16.2,32.5z M16.2,2.5
                    C8.7,2.5,2.5,8.7,2.5,16.2S8.7,30,16.2,30S30,23.8,30,16.2S23.8,2.5,16.2,2.5z M13.3,23.7c-0.7,0-1.5-0.4-1.9-1
                    c-0.2-0.4-0.4-0.8-0.4-1.2v-9.3c0-1.2,1-2.2,2.2-2.2c0.4,0,0.9,0.1,1.2,0.4l7.2,4.7c0.5,0.3,0.8,0.8,1,1.4s0,1.2-0.3,1.7
                    c-0.2,0.3-0.4,0.5-0.7,0.7l-7.2,4.7C14.1,23.5,13.7,23.7,13.3,23.7z M13.5,12.6v8.4l6.5-4.2L13.5,12.6z"
        />
      </symbol>

      <symbol id="icon-cricketbat" viewBox="0 0 36 36.8">
        <path
          d="M26.2,24.7c-1.6-0.5-3.3-0.3-4.7,0.5C20,26,19,27.3,18.6,29.1c-0.5,1.6-0.2,3.3,0.6,4.7
                    c0.8,1.4,2.1,2.4,3.7,2.9l0.1,0c0.5,0.1,1,0.2,1.6,0.2c1.1,0,2.1-0.3,3-0.8c1.4-0.8,2.5-2.1,2.9-3.7c0.4-1.6,0.2-3.2-0.6-4.7
                    C29.1,26.2,27.8,25.1,26.2,24.7z M28.3,31.6c-0.3,1-0.9,1.8-1.9,2.4c-0.9,0.5-2,0.6-3,0.4c-1-0.3-1.8-1-2.4-1.8
                    c-0.5-0.9-0.6-1.9-0.3-2.9l0-0.1c0.2-1.1,0.8-1.9,1.8-2.3l0.1,0c0.9-0.5,1.9-0.6,2.9-0.3c1,0.3,1.9,1,2.4,1.8
                    C28.5,29.6,28.6,30.6,28.3,31.6z"
        />
        <path
          d="M35.6,2.3l-1.9-1.9c-0.5-0.5-1.4-0.5-1.9,0l-9.5,9.2c-1.4-1.4-3.7-1.4-5.1,0L0.9,25.2C0.3,25.9,0,26.7,0,27.6
                    c0,0.8,0.4,1.6,1,2.2L5.3,34c0.6,0.6,1.5,1,2.3,1c0.8,0,1.7-0.3,2.3-1l16.4-16c0.6-0.5,1-1.3,1-2.3c0-0.8-0.4-1.6-1-2.2l-0.2-0.2
                    l9.5-9.2c0.3-0.2,0.4-0.6,0.4-0.9C36,2.9,35.8,2.5,35.6,2.3z M24.8,16.6L13,28.1l-6-5.8l11.8-11.5c0.3-0.2,0.5-0.4,0.9-0.4
                    c0.1,0,0.2,0,0.3,0.1l-4.8,8.2c-0.2,0.4-0.1,0.9,0.2,1.2c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.3,0,0.5-0.1l8.5-4.6c0,0.1,0,0.2,0,0.3
                    C25.2,16.1,25.1,16.4,24.8,16.6L24.8,16.6z M18.9,16.6l2.7-4.7l0,0l0,0l2.2,2.1L18.9,16.6z M23.7,11l6.6-6.3l0.9,0.9l-6.6,6.4
                    L23.7,11z M31.7,3.2l0.9-0.9l0.9,0.9l-0.9,0.9L31.7,3.2z M11.6,29.4l-0.7,0.7l-6-5.8l0.7-0.7L11.6,29.4z M6.7,32.5l-4.3-4.1
                    l-0.1-0.1c-0.2-0.2-0.3-0.5-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8l1-0.9l6,5.8l-1,0.9C7.9,33,7.2,33,6.7,32.5z"
        />
      </symbol>

      <symbol id="icon-drama" viewBox="0 0 43 43">
        <path
          d="M7.3,25.1c0.8,0.1,1.6-0.4,1.7-1.2c0-0.3,0.2-0.5,0.4-0.7C9.6,23,9.9,23,10.2,23c0.6,0.1,0.9,0.7,0.8,1.2
                    c-0.1,0.8,0.4,1.6,1.2,1.7c0.1,0,0.2,0,0.3,0c0.7,0,1.3-0.5,1.5-1.2c0.4-2.2-1.1-4.3-3.3-4.7c-1.1-0.2-2.1,0-3,0.7
                    c-0.9,0.6-1.5,1.6-1.7,2.6C5.9,24.2,6.4,25,7.3,25.1z"
        />
        <path
          d="M19.7,22.1c-1.1-0.2-2.1,0-3,0.7c-0.9,0.6-1.5,1.6-1.7,2.6c-0.1,0.8,0.4,1.6,1.2,1.7c0.8,0.1,1.6-0.4,1.7-1.2
                    c0-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.2,0.7-0.2c0.6,0.1,0.9,0.7,0.8,1.2c-0.1,0.8,0.4,1.6,1.2,1.7c0.1,0,0.2,0,0.3,0
                    c0.7,0,1.3-0.5,1.5-1.2C23.3,24.6,21.9,22.5,19.7,22.1z"
        />
        <path
          d="M19.4,30.6c-0.7-0.5-1.6-0.3-2.1,0.3c-1.1,1.6-2.8,2.4-4.5,2c-1.7-0.4-3.2-1.8-3.8-3.9c-0.2-0.8-1.1-1.2-1.9-1
                    c-0.8,0.2-1.2,1.1-1,1.9c0.9,3.1,3.3,5.3,6.1,5.9c0.5,0.1,1,0.1,1.4,0.1c2.4,0,4.6-1.2,6.2-3.3C20.2,32,20.1,31.1,19.4,30.6z"
        />
        <path
          d="M41.3,2.2l-0.1-0.5c-0.1-0.6-0.6-1-1.2-1.1c-8.9-1.7-17.9,0.3-25.4,5.8c-0.5,0.3-0.7,0.9-0.6,1.5l0.1,0.5
                    c0.2,1.3,0.5,2.6,0.8,4c-4-0.6-8.2-0.5-12.3,0.5C2,13,1.5,13.5,1.4,14l-0.1,0.5c-1.3,6.7-2.8,14.9,1.6,22.6c1.7,3,4.6,5.1,7.9,5.7
                    c0.7,0.1,1.4,0.2,2,0.2c2.6,0,5.2-0.9,7.3-2.7c3.3-2.8,5.3-6.3,6.5-10c1.2,0.4,2.5,0.7,3.8,0.7c0.8,0,1.6-0.1,2.4-0.3
                    c3.2-0.7,6-2.9,7.6-6C44.6,16.9,42.7,8.4,41.3,2.2z M18.2,38C18.2,38,18.2,38,18.2,38c-1.9,1.6-4.4,2.3-6.8,1.9
                    c-2.4-0.5-4.6-2-5.8-4.3c-3.8-6.5-2.5-14-1.3-20c7.5-1.5,15.1-0.1,21.7,4.1C25,25.8,23.9,33.2,18.2,38z M37.9,23.3
                    c-1.2,2.3-3.2,3.9-5.6,4.5c-1.6,0.4-3.2,0.2-4.7-0.4c0.6-2.4,1-4.7,1.3-6.8c0.5-0.2,1-0.4,1.5-0.5c1.9-0.4,3.9,0.2,5.2,1.5
                    c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.8-0.2,1.1-0.5c0.6-0.6,0.6-1.5,0-2.1c-2-1.9-5-2.8-7.8-2.3c-0.5,0.1-1,0.2-1.5,0.4
                    c-1.7-1.1-3.4-2-5.2-2.8c0-0.2,0-0.4,0-0.6c-0.1-0.2,0-0.5,0.1-0.7c0.1-0.2,0.4-0.4,0.7-0.4c0.6-0.1,1.1,0.2,1.2,0.8
                    c0.2,0.8,1,1.3,1.8,1.1c0.8-0.2,1.3-1,1.1-1.8c-0.5-2.1-2.6-3.5-4.8-3c-1.1,0.2-2,0.8-2.6,1.8c-0.3,0.5-0.5,1.1-0.6,1.8
                    c-0.6-0.2-1.3-0.4-1.9-0.6c-0.4-1.6-0.7-3.3-1-4.8C23.5,3.9,31,2.2,38.5,3.4C39.8,9.4,41.4,16.7,37.9,23.3z"
        />
        <path
          d="M32.2,8.1c-1.1,0.2-2,0.8-2.6,1.8c-0.6,0.9-0.8,1.9-0.5,3c0.2,0.7,0.8,1.2,1.5,1.2c0.1,0,0.2,0,0.3,0
                    c0.8-0.2,1.3-1,1.1-1.8c-0.1-0.2,0-0.5,0.1-0.7c0.1-0.2,0.4-0.4,0.7-0.4c0.6-0.1,1.1,0.2,1.2,0.8c0.2,0.8,1,1.3,1.8,1.1
                    c0.8-0.2,1.3-1,1.1-1.8C36.5,9,34.4,7.6,32.2,8.1z"
        />
      </symbol>

      <symbol id="icon-cutlery" viewBox="0 0 36.2 38.3">
        <path
          d="M11.2,13.5c0.5,0,0.9-0.2,1.1-0.6c0.2-0.4,0.2-0.9-0.1-1.2l-6.8-9C5,2,4.2,1.9,3.6,2.3C3.1,2.6,2.9,3.4,3.3,3.9
                    l6.8,9C10.4,13.3,10.8,13.4,11.2,13.5L11.2,13.5z M16.9,15.6c0.7-1.7,0.5-3.7-0.6-5.2L9.1,0.5C8.7,0,7.9-0.2,7.4,0.2
                    C6.8,0.6,6.7,1.3,7.1,1.9l7.3,10c0.6,0.8,0.7,1.9,0.3,2.9c-0.3,0.7-0.2,1.6,0.3,2.2L28.3,34c0.2,0.2,0.3,0.6,0.2,0.9
                    c0,0.3-0.2,0.6-0.5,0.8c-0.3,0.2-0.6,0.2-0.9,0.2c-0.3-0.1-0.6-0.2-0.8-0.5L14.3,17.4l0,0c-0.5-0.7-1.3-1-2.1-1
                    c-1.1,0.1-2.1-0.4-2.7-1.2l-7.3-10C1.8,4.8,1.1,4.7,0.5,5C0,5.4-0.2,6.2,0.2,6.7l7.3,10c1.1,1.5,2.9,2.3,4.8,2.2l12.1,17.8
                    c0.5,0.8,1.4,1.3,2.4,1.5c0.2,0,0.4,0.1,0.7,0.1c1.4,0,2.6-0.8,3.2-2c0.6-1.2,0.5-2.7-0.4-3.7L16.9,15.6z M34,1
                    c-1.6-1.2-3.7-1.3-6-0.3c-2.3,1.1-4.2,2.8-5.6,4.9c-2,3-3,6.4-2.6,9.2c0.1,0.5,0,1.1-0.1,1.6c-0.2,0.6,0.1,1.3,0.7,1.5
                    c0.6,0.2,1.3-0.1,1.5-0.7c0.3-0.9,0.4-1.9,0.2-2.8c-0.3-2.1,0.5-4.9,2.1-7.4C25.5,5.3,27.1,3.9,29,3c0.9-0.4,2.5-0.9,3.7,0
                    c1.2,0.9,1.3,2.7,1.2,3.6c-0.2,2.1-1,4.2-2.2,5.9c-1.7,2.5-4,4.2-6,4.5c-0.9,0.2-1.7,0.5-2.5,1.1c-0.2,0.2-0.4,0.3-0.6,0.5
                    c-0.4,0.5-0.4,1.2,0,1.7c0.4,0.5,1.2,0.5,1.6,0c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.3,0.9-0.6,1.4-0.7c2.6-0.5,5.5-2.5,7.5-5.5
                    c1.5-2.1,2.4-4.6,2.6-7.2C36.4,4.2,35.6,2.1,34,1L34,1z M17.3,30.4L13,36.6c-0.6,0.8-1.5,1.4-2.5,1.6c-0.2,0-0.5,0.1-0.7,0.1
                    c-0.7,0-1.4-0.2-2-0.6c-0.8-0.5-1.4-1.4-1.6-2.4c-0.2-1,0.1-1.9,0.7-2.7l5.9-7.3h0c0.4-0.5,1.2-0.6,1.7-0.2c0.5,0.4,0.6,1.1,0.2,1.6
                    l-5.9,7.3h0c-0.2,0.3-0.3,0.6-0.2,0.9c0.1,0.3,0.3,0.6,0.5,0.8C9.5,36,9.8,36,10.1,36c0.4-0.1,0.7-0.3,0.9-0.6l4.3-6.3
                    c0.2-0.3,0.4-0.5,0.8-0.6c0.3-0.1,0.7,0,1,0.2c0.3,0.2,0.5,0.5,0.5,0.8C17.6,29.8,17.5,30.1,17.3,30.4L17.3,30.4z"
        />
      </symbol>

      <symbol id="icon-tags" viewBox="0 0 35 39.3">
        <path
          d="M4.6,20c0.2-0.6,0.1-1.2-0.3-1.7l-0.7-0.8L17.2,3.2h10.5V4c0,0.9,0.7,1.6,1.5,1.6s1.5-0.7,1.5-1.6V1.6
                    c0-0.9-0.7-1.6-1.5-1.6H16.5c-0.4,0-0.8,0.2-1.1,0.5l-15,15.8c-0.6,0.6-0.6,1.5-0.1,2.2L2,20.4c0.3,0.3,0.7,0.5,1.1,0.5
                    C3.8,20.9,4.3,20.5,4.6,20z"
        />
        <path
          d="M33.5,7.9H20.8c-0.4,0-0.8,0.2-1.1,0.5l-15,15.3c-0.6,0.6-0.6,1.5-0.1,2.1l11.5,13c0.3,0.3,0.7,0.5,1.1,0.5h0
                    c0.4,0,0.8-0.1,1-0.4l16.2-15.3c0.3-0.3,0.5-0.7,0.5-1.1v-13C35,8.6,34.3,7.9,33.5,7.9z M32,11v10.7L17.4,35.5L7.9,24.8L21.5,11H32
                    z"
        />
        <path
          d="M26.9,19.7L26.9,19.7c1.4,0,2.6-0.9,3.2-2.2c0.5-1.3,0.2-2.8-0.7-3.8c-1-1-2.5-1.3-3.7-0.8
                    c-1.3,0.5-2.1,1.8-2.1,3.2C23.5,18.1,25.1,19.7,26.9,19.7z M26.9,14.6c0.6,0,1.2,0.4,1.4,1c0.2,0.6,0.1,1.2-0.3,1.7
                    c-0.3,0.3-0.7,0.5-1.1,0.5c-0.2,0-0.4,0-0.6-0.1c-0.6-0.2-0.9-0.8-0.9-1.4c0-0.4,0.2-0.8,0.4-1.1C26.2,14.8,26.5,14.6,26.9,14.6z"
        />
      </symbol>

      <symbol id="icon-postcode" viewBox="0 0 28 34.1">
        <path
          d="M21.7,13.7c3.7,0,6.3-2.9,6.3-7c0-4-2.5-6.7-6.2-6.7c-3.7,0-6.3,2.8-6.3,6.9C15.6,10.9,18,13.7,21.7,13.7z
                    M21.9,3c0.4,0,1.3,0.2,1.3,3.6c0,2.1-0.1,4-1.4,4c-0.4,0-1.4-0.3-1.4-3.7C20.4,4.9,20.5,3,21.9,3z"
        />
        <path
          d="M11.8,7.2V3.9L7.9,4.4L7.6,6C7.4,7.6,7.4,8.5,7.4,9.3c0,0.1,0,0.2,0,0.3c-0.1,0-0.2,0-0.3,0H5.2
                    c-0.3,0-0.5,0-0.6,0c0.5-0.5,0.9-1.4,1.1-2.1L8.8,0H4.4L0,10.6v2.8h6.7c0.3,0,0.7,0,0.8-0.1c-0.1,0.2-0.1,0.5-0.1,0.6v4.3l4.4-0.3
                    v-3.6c0-0.5,0-0.9-0.1-1c0.1,0,0.3,0.1,0.5,0.1h0.9V9.6h-1.5C11.7,9.4,11.8,8.5,11.8,7.2z"
        />
        <path
          d="M6.6,20.4c-3.7,0-6.3,2.8-6.3,6.9c0,4,2.4,6.8,6.2,6.8c3.7,0,6.3-3,6.3-7C12.8,23.1,10.3,20.4,6.6,20.4z
                    M6.5,31c-0.4,0-1.4-0.3-1.4-3.7c0-2.1,0.2-3.9,1.5-3.9C7.1,23.5,8,23.6,8,27C8,29.1,7.9,31,6.5,31z"
        />
        <path
          d="M21.8,20.4c-3.7,0-6.3,2.8-6.3,6.9c0,4,2.4,6.8,6.2,6.8c3.7,0,6.3-3,6.3-7C28,23.1,25.6,20.4,21.8,20.4z
                    M21.8,31c-0.4,0-1.4-0.3-1.4-3.7c0-2.1,0.2-3.9,1.5-3.9c0.4,0,1.3,0.2,1.3,3.6C23.2,29.1,23.1,31,21.8,31z"
        />
      </symbol>

      <symbol id="icon-microscope" viewBox="0 0 29.1 44">
        <path
          d="M14.9,6l5.9,4.2L9.4,25.9l-5.9-4.2L14.9,6z M19.8,3.5l2.4,1.7l-1.1,1.5L18.6,5L19.8,3.5z M19.3,0
                    c-0.4,0-0.8,0.3-1.1,0.6l-2,2.7l-0.8-0.6c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0.1-0.7,0.3-1,0.6L0.3,21.2c-0.4,0.7-0.3,1.6,0.3,2l8.2,5.9
                    c0.3,0.2,0.7,0.3,1.1,0.3c0.4-0.1,0.7-0.3,0.9-0.6l5.4-7.4c2,2.3,3.6,6.4,3.6,9.5c0,1-0.2,1.7-0.6,2.4H3.9c-0.1,0-0.1,0-0.2,0
                    c-0.4,0-0.7,0.2-1,0.5c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.4,0.2,0.8,0.5,1c0.3,0.3,0.7,0.4,1,0.4h12.2c-0.2,0.1-0.4,0.2-0.5,0.3
                    c-2.8,1.6-6.8,2.9-11,4.6H1.5c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0.2-1,0.5C0.1,41.9,0,42.3,0,42.7c0,0.4,0.2,0.8,0.5,1
                    c0.3,0.3,0.7,0.4,1,0.4h26.1c0.4,0,0.8-0.1,1-0.4c0.3-0.3,0.4-0.7,0.4-1c0-0.4-0.2-0.8-0.4-1c-0.3-0.3-0.7-0.4-1-0.4h-2.1
                    c1.3-3,2.1-6.1,2.1-9.3c0-4.6-1.3-9.3-4.1-13.3c-0.3-0.5-0.9-0.7-1.4-0.6c-0.5,0.1-0.9,0.4-1.1,0.9c-0.2,0.5-0.1,1,0.2,1.5
                    c2.5,3.5,3.6,7.5,3.6,11.6c0,3-0.9,6.2-2.4,9.3h-9.7c1.5-0.6,3-1.3,4.3-2c1.6-0.9,3.1-1.9,4.1-3.3c1.1-1.4,1.7-3,1.7-5
                    c0-4.2-1.8-9-4.8-11.9l6-8.2c0.5-0.7,0.3-1.6-0.3-2l-0.2-0.2l2-2.7c0,0,0.1-0.1,0.1-0.1c0.3-0.6,0.2-1.5-0.4-1.9l-4.9-3.4
                    C20,0.1,19.7,0,19.3,0L19.3,0z"
        />
      </symbol>

      <symbol id="icon-home" viewBox="0 0 38.4 47.2">
        <polygon
          points="7.6,41.4 5,41.4 5,37.9 3.1,37.9 3.1,47 5,47 5,42.9 7.6,42.9 7.6,47 9.5,47 9.5,37.9 7.6,37.9"
        />
        <path
          d="M15.1,37.7c-0.7,0-1.4,0.2-1.9,0.4c-1.3,0.7-2.1,2.3-2.1,4.3c0,1.3,0.4,2.5,1.1,3.4c0.7,0.9,1.6,1.3,3,1.3
                    c0.8,0,1.3-0.1,1.8-0.4c1.3-0.7,2-2.3,2-4.4C19,39.5,17.5,37.7,15.1,37.7z M15.1,45.8c-0.4,0-0.8-0.1-1.1-0.3
                    c-0.6-0.4-0.8-1.4-0.8-3c0-1.1,0.1-1.9,0.5-2.5c0.3-0.5,0.8-0.8,1.4-0.8c1,0,1.5,0.7,1.6,1.2c0.2,0.5,0.2,1.2,0.2,2.1
                    C16.9,44.8,16.4,45.8,15.1,45.8z"
        />
        <path
          d="M26.7,37.9l-1.2,4.6c-0.1,0.5-0.3,1-0.3,1.5c-0.1-0.5-0.1-0.8-0.3-1.5l-1.2-4.6h-2.4L20.4,47h1.8l0.3-4.8
                    c0-0.6,0.1-1.1,0.1-1.7c0.1,0.5,0.3,1.2,0.4,1.6l1.3,4.8h1.6l1.5-5c0.2-0.6,0.2-0.9,0.3-1.4c0,0.5,0,1,0.1,1.5l0.3,4.9h1.8
                    l-0.8-9.2H26.7z"
        />
        <polygon
          points="33.6,43 36.2,43 36.2,41.5 33.5,41.5 33.5,39.4 36.7,39.4 36.9,37.9 31.7,37.9 31.7,47 37,47 37,45.4
                    33.6,45.4"
        />
        <path
          d="M21.5,23.7v5.6c0,0.8,0.6,1.4,1.4,1.4h8.9c0.8,0,1.4-0.6,1.4-1.4V15.8l-2.8-2.2v14.3h-6.1v-4.2
                    c0-2.8-2.3-5.1-5.2-5.1S14,20.9,14,23.7v4.2H8V13.6l-2.8,2.2v13.5c0,0.8,0.6,1.4,1.4,1.4h8.9c0.8,0,1.4-0.6,1.4-1.4v-5.6
                    c0-1.3,1-2.3,2.3-2.3S21.5,22.4,21.5,23.7z"
        />
        <path
          d="M37.9,14.3l-6.5-5.1V1.4C31.4,0.6,30.7,0,30,0h-3.7c-0.8,0-1.4,0.6-1.4,1.4V4l-4.8-3.7C19.8,0.1,19.5,0,19.2,0
                    c-0.3,0-0.6,0.1-0.9,0.3l-17.8,14c-0.5,0.5-0.8,1.4-0.3,2c0.5,0.6,1.5,0.7,2,0.2L19.2,3.2l16.9,13.3c0.6,0.5,1.6,0.4,2-0.2
                    C38.6,15.7,38.5,14.7,37.9,14.3z M28.6,7l-0.9-0.7V2.8h0.9V7z"
        />
      </symbol>

      <symbol id="icon-home-alt" viewBox="0 0 18 18">
        <polygon
          points="7.2,18 2.7,18 2.7,9 0,9 9,0 18,9 15.3,9 15.3,18 10.8,18 10.8,12.6 7.2,12.6 "
        />
      </symbol>

      <symbol id="icon-close" viewBox="0 0 14.2 14.2">
        <path
          d="M8.8,7.1l5-5c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7l-0.3-0.3c-0.4-0.4-1-0.4-1.4,0l-5,5l-5-5c-0.4-0.4-1-0.4-1.4,0
                    L0.3,0.6C0.1,0.8,0,1.1,0,1.3C0,1.6,0.1,1.9,0.3,2l5,5l-5,5c-0.4,0.4-0.4,1,0,1.4l0.3,0.3c0.4,0.4,1,0.4,1.4,0l5-5l5,5
                    c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l0.3-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7L8.8,7.1z"
        />
      </symbol>

      <symbol id="icon-train" viewBox="0 0 38.8 48.9">
        <path
          d="M38.8,37.2l0-28.7c-0.1-2.3-1.5-4.3-3.5-5.2C30.3,1.1,24.9,0,19.4,0C13.8,0,8.5,1.1,3.4,3.3
                    C1.4,4.2,0,6.2,0,8.5v28.7c0,2,1.7,3.6,3.7,3.6H6l-4.4,5.7c-0.2,0.3-0.3,0.7-0.3,1.1c0.1,0.4,0.3,0.7,0.6,1
                    c0.2,0.1,0.5,0.2,0.8,0.2c0.5,0,0.9-0.2,1.2-0.6l1.9-2.6h26.9l1.9,2.6c0.3,0.4,0.7,0.6,1.2,0.6c0.3,0,0.6-0.1,0.9-0.3
                    c0.3-0.2,0.5-0.6,0.5-1c0.1-0.4,0-0.8-0.2-1l-4.2-5.7h2.3C37.2,40.9,38.8,39.3,38.8,37.2z M29.4,40.4l2.2,2.8H7.2l2.1-2.8H29.4z
                    M36.3,11.3v12.2H20.4V11.3H36.3z M2.5,9.3V8.5c0-1.3,0.7-2.5,1.9-3c4.7-2.1,9.7-3.1,14.9-3.1s10.2,1,14.9,3.1
                    c1.2,0.5,1.9,1.7,1.9,3v0.8H2.5z M2.5,23.5V11.3h15.9v12.2H2.5z M35.1,38.4H3.6c-0.6,0-1.2-0.5-1.2-1.2V25.5h33.9v11.7
                    C36.3,37.9,35.7,38.4,35.1,38.4z"
        />
        <path
          d="M8,27.3c-2.1,0-3.8,1.7-3.8,3.8C4.2,33.3,5.9,35,8,35c2.1,0,3.8-1.7,3.8-3.8C11.8,29,10.1,27.3,8,27.3z
                    M8,29.7c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5S7.2,29.7,8,29.7z"
        />
        <path
          d="M28.9,27.3c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8c2.1,0,3.8-1.7,3.8-3.8C32.7,29,31,27.3,28.9,27.3z
                    M28.9,32.6c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S29.7,32.6,28.9,32.6z"
        />
      </symbol>

      <symbol id="icon-Train" viewBox="0 0 461.4 576.7">
        <path
          d="M421.6,456.6h-383c-7.5,0-14.9-6-14.9-14.4V301.6h411.6v140.6C435.3,450.6,429,456.6,421.6,456.6z M378.1,514.2
                    h-296l26.1-33.6h243.7L378.1,514.2z M242.5,131h192.8v146.6H242.5V131z M24.9,277.5V131h192.8v146.6H24.9z M48.5,61.3
                    C105.7,36,166.6,24,230.1,24s124.3,12,181.6,37.2c14.9,6,23.6,20.4,23.6,36v9.6H24.9v-9.6C24.9,81.7,33.6,67.3,48.5,61.3z
                    M421.6,39.6C360.6,13.2,296,0,230.1,0S99.5,13.2,38.6,39.6C14.9,49.3,0,72.1,0,97.3v344.8c0,20.4,17.4,38.5,39.8,38.5h38.5
                    l-59.7,76.9c-3.7,4.8-2.5,13.2,2.5,16.8c2.5,1.2,5,2.4,7.5,2.4c3.7,0,7.5-1.2,10-4.8l24.9-33.6h332l24.9,33.6
                    c2.5,3.6,6.2,4.8,10,4.8c2.5,0,5-1.2,7.5-2.4c5-3.6,6.2-12,2.5-16.8L383,480.6h38.5c22.4,0,39.8-16.8,39.8-38.5V97.3
                    C460.1,72.1,445.2,49.3,421.6,39.6L421.6,39.6z"
        />
        <path
          d="M346,392.2c-12.7,0-23.1-10.4-23.1-23.1c0-12.7,10.4-23.1,23.1-23.1c12.7,0,23.1,10.4,23.1,23.1
                    C369.1,381.8,358.7,392.2,346,392.2z M346,323c-25.4,0-46.1,20.8-46.1,46.1s20.8,46.1,46.1,46.1s46.1-20.8,46.1-46.1
                    C392.2,343.7,371.4,323,346,323z"
        />
        <path
          d="M92.3,392.2c-12.7,0-23.1-10.4-23.1-23.1c0-12.7,10.4-23.1,23.1-23.1s23.1,10.4,23.1,23.1
                    C115.3,381.8,104.9,392.2,92.3,392.2z M92.3,323c-25.4,0-46.1,20.8-46.1,46.1c0,25.4,20.8,46.1,46.1,46.1s46.1-20.8,46.1-46.1
                    C138.4,343.7,117.6,323,92.3,323z"
        />
      </symbol>

      <symbol id="icon-car" viewBox="0 0 35.9 27.6">
        <g>
          <path
            d="M33.8,10C33.5,7.9,33,5.7,32,3.4c-0.2-0.5-0.5-1-0.8-1.4c-0.5-0.6-1.1-1.1-1.9-1.4C28.6,0.2,27.8,0,26.9,0H9
                        C8.1,0,7.3,0.2,6.6,0.5C5.9,0.9,5.3,1.3,4.8,2C4.4,2.4,4.1,2.8,3.9,3.4c-1,2.4-1.5,4.5-1.8,6.6h0c-0.3,0.1-0.6,0.2-0.9,0.4
                        c-0.5,0.4-0.9,0.9-1.1,1.5c-0.1,0.3-0.2,0.6-0.2,1v7.9c0,0.6,0.4,1,1,1h1v4.9c0,0.3,0.1,0.5,0.3,0.7s0.4,0.3,0.7,0.3h0h4
                        c0.6,0,1-0.4,1-1v-4.9h19.9v4.9c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1v-4.9h1c0.6,0,1-0.4,1-1v-7.9C35.9,11.5,35,10.3,33.8,10z
                        M5.7,4.1C6.3,2.8,7.6,2,9,2h18c1.4,0,2.7,0.8,3.2,2.1h0c0.6,1.5,1.1,3,1.3,4.4c0.1,0.5,0.2,0.9,0.2,1.4h-16
                        c-0.3-1.4-1.2-2.6-2.4-3.3c-0.7-0.4-1.6-0.7-2.5-0.7c-0.9,0-1.7,0.2-2.5,0.7C7.3,7.3,6.4,8.5,6.1,9.9H4.1c0.1-0.5,0.1-0.9,0.2-1.4
                        C4.7,7.1,5.1,5.6,5.7,4.1z M8.1,9.9L8.1,9.9c0.2-0.6,0.6-1.1,1.1-1.4c0.5-0.3,1.1-0.6,1.7-0.6c0.2,0,0.3,0,0.5,0
                        C12.1,8,12.6,8.3,13,8.7c0,0,0,0,0.1,0c0,0,0,0,0,0c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2H8.1z
                        M6,25.6H4v-3.9h2V25.6z M31.9,25.6h-2v-3.9h2V25.6z M33.9,19.7H2v-6.9c0-0.6,0.4-1,1-1h29.9c0.6,0,1,0.4,1,1V19.7z"
          />
          <path
            d="M29.1,12.5c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1s3.1-1.4,3.1-3.1S30.8,12.5,29.1,12.5z M29.1,16.7
                        c-0.6,0-1-0.5-1-1s0.5-1,1-1s1,0.5,1,1S29.7,16.7,29.1,16.7z"
          />
          <path
            d="M6.8,12.5c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1S8.5,12.5,6.8,12.5z M6.8,16.7
                        c-0.6,0-1-0.5-1-1s0.5-1,1-1c0.6,0,1,0.5,1,1S7.4,16.7,6.8,16.7z"
          />
        </g>
      </symbol>

      <symbol id="icon-Train_and_Bus" viewBox="0 0 46 26">
        <path
          d="M41.1,13.3c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2c0-0.6-0.2-1.1-0.6-1.5
                    C42.2,13.5,41.6,13.3,41.1,13.3z M41.7,15.4c0,0.4-0.3,0.7-0.7,0.7s-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7S41.7,15.1,41.7,15.4z
                    M30.9,13.3c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S32,13.3,30.9,13.3z M31.5,15.4c0,0.4-0.3,0.7-0.7,0.7
                    c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7S31.5,15.1,31.5,15.4z M45.8,24.4l-0.9-1.3c0-0.1-0.1-0.2-0.1-0.3l-0.9-1.2l-1-1.5h0.9
                    c1.1,0,1.9-0.8,1.9-1.8v-14c-0.1-1.2-0.8-2.2-1.8-2.6C41.4,0.5,38.8,0,36,0c-2.8,0-5.4,0.5-7.9,1.6c-1.1,0.4-1.8,1.5-1.8,2.6v14
                    c0,1,0.9,1.8,1.9,1.8h0.4l-2.9,4.3c-0.3,0.5-0.2,1.1,0.3,1.4c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.2,0.8-0.4l0.4-0.7h15.8l0.4,0.7
                    c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2C46,25.5,46.1,24.9,45.8,24.4z M44.1,11.3h-7.3V5.9h7.3V11.3z M27.9,4.2
                    c0-0.5,0.3-1,0.8-1.2l0,0C31,2,33.4,1.5,36,1.5S41.1,2,43.3,3c0.5,0.2,0.8,0.7,0.8,1.2v0.1H27.9V4.2z M27.9,5.9h7.3v5.4h-7.3V5.9z
                    M28.2,18.6c-0.2,0-0.3-0.1-0.3-0.3v-5.4h16.3v5.4c0,0.2-0.1,0.3-0.3,0.3H28.2z M30.7,20.7l0.5-0.6h9.4c0,0.1,0.1,0.2,0.1,0.3
                    l0.4,0.5H30.6L30.7,20.7z M28.9,23.3l0.6-0.9h12.5l0.6,0.9H28.9z M17.2,20c1.4,0,2.5-1.1,2.5-2.4c0-0.6-0.3-1.3-0.7-1.7
                    c-0.5-0.5-1.1-0.7-1.8-0.7c-1.4,0-2.5,1.1-2.5,2.4C14.7,18.9,15.8,20,17.2,20z M16.1,17.5c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1
                    c0,0.6-0.5,1.1-1.1,1.1S16.1,18.1,16.1,17.5z M5.2,20c1.4,0,2.5-1.1,2.5-2.4c0-1.3-1.1-2.4-2.5-2.4s-2.5,1.1-2.5,2.4
                    C2.7,18.9,3.9,20,5.2,20z M4.1,17.5c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C4.6,18.6,4.1,18.1,4.1,17.5z
                    M20.5,1.9c-2.9-1.2-6-1.9-9.2-1.9C8.1,0,4.9,0.6,2,1.9C0.8,2.4,0,3.5,0,4.8v15.9c0,1.1,1,2.1,2.2,2.1h0.4V24c0,1,0.8,1.9,1.9,1.9H6
                    c1,0,1.9-0.8,1.9-1.9v-1.1l6.6,0V24c0,1,0.8,1.9,1.9,1.9h1.5c1,0,1.9-0.8,1.9-1.9v-1.1l0.6,0c1.2,0,2.2-0.9,2.2-2.1l0-15.9
                    C22.5,3.5,21.7,2.3,20.5,1.9z M1.8,6.7h18.8v6.2H1.8V6.7z M20.7,20.7c0,0.2-0.1,0.4-0.4,0.4H2.1c-0.2,0-0.4-0.2-0.4-0.4v-6.2h19
                    V20.7z M20.7,5h-19V4.8c0-0.6,0.4-1.2,0.9-1.4l0,0c2.6-1.1,5.5-1.7,8.5-1.7s5.9,0.6,8.5,1.7c0.6,0.2,0.9,0.8,0.9,1.4V5z"
        />
      </symbol>

      <symbol id="icon-City_Skyline" viewBox="0 0 54.4 50.9">
        <path
          d="M13.1,12.1c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8C12.7,11.3,13.1,11.7,13.1,12.1z
                    M12.2,15.3c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C13.1,15.6,12.7,15.3,12.2,15.3z M15.4,15.3
                    c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C16.2,15.6,15.9,15.3,15.4,15.3z M15.4,19.2
                    c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C16.2,19.6,15.9,19.2,15.4,19.2z M12.2,19.2
                    c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8C13.1,19.6,12.7,19.2,12.2,19.2z M31.8,13.9
                    c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8S32.3,13.9,31.8,13.9z M34.2,14.8c0,0.5,0.4,0.8,0.8,0.8
                    c0.5,0,0.8-0.4,0.8-0.8s-0.4-0.8-0.8-0.8C34.5,13.9,34.2,14.3,34.2,14.8z M31.8,17.9c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8
                    c0.5,0,0.8-0.4,0.8-0.8S32.3,17.9,31.8,17.9z M12.2,23.2c-0.5,0-0.8,0.4-0.8,0.8s0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
                    S12.7,23.2,12.2,23.2z M27,19.7v-9v0V4.5V1.2C27,0.6,27.6,0,28.3,0s1.2,0.6,1.2,1.2v2.5L40,9.6c0.1,0,0.1,0.1,0.2,0.1
                    c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.4c0,0,0,0,0,0c0,0.1,0.1,0.3,0.1,0.4c0,0,0,0,0,0v8.8c0,0.7-0.6,1.2-1.2,1.2
                    s-1.2-0.6-1.2-1.2v-7.5h-8.6v7.7c0,0.7-0.6,1.2-1.2,1.2S27,20.4,27,19.7z M34.6,9.4l-5-2.8v2.8H34.6z M8.9,29.1
                    c0.7,0,1.2-0.6,1.2-1.2V9.6H17v2.6c0,0.7,0.6,1.2,1.3,1.2h1.1v10.6c0,0.7,0.6,1.2,1.2,1.2s1.2-0.6,1.2-1.2V12.2
                    c0-0.7-0.6-1.2-1.2-1.2h-1.1V8.4c0-0.7-0.6-1.2-1.2-1.2H8.9c-0.7,0-1.2,0.6-1.2,1.2v19.5C7.7,28.6,8.2,29.1,8.9,29.1z M54.3,33.1
                    c-0.2,0.7-0.9,1-1.6,0.8c-0.8-0.3-1.7-0.6-2.6-1V38h0.1c0.7,0,1.2,0.6,1.2,1.2s-0.6,1.2-1.2,1.2H34.7v1.7h0.4c0.6,0,1.1,0.4,1.2,1
                    l1.2,6.2c0.1,0.7-0.3,1.3-1,1.5c-0.1,0-0.2,0-0.2,0c-0.6,0-1.1-0.4-1.2-1l-1-5.1h-1.5l-1,5.1c-0.1,0.7-0.8,1.1-1.5,1
                    c-0.7-0.1-1.1-0.8-1-1.5l1.2-6.2c0.1-0.6,0.6-1,1.2-1h0.7v-1.7H3.4c-0.7,0-1.2-0.6-1.2-1.2S2.7,38,3.4,38h4.3v-3.8
                    c-2,0.6-4.1,1.2-6.1,1.7c-0.1,0-0.2,0-0.3,0c-0.6,0-1.1-0.4-1.2-0.9c-0.2-0.7,0.2-1.4,0.9-1.5C14,30.1,28,24.6,32.7,21
                    c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.4,0.1
                    c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c8.9,5.9,15.3,9.4,19.3,10.6C54.1,31.8,54.5,32.5,54.3,33.1z M19.3,30.3V38h5.2v-9.9
                    C22.9,28.9,21.2,29.6,19.3,30.3z M10.2,38h6.7v-6.8c-2.1,0.8-4.4,1.5-6.7,2.3V38z M32.2,38V24.3C30.8,25.2,29,26.1,27,27v11H32.2z
                    M34.7,38h5.2V27.5c-1.6-1-3.3-2.1-5.2-3.3V38z M47.6,31.8c-1.6-0.8-3.3-1.7-5.2-2.8v9h5.2V31.8z"
        />
      </symbol>

      <symbol id="icon-Seat" viewBox="0 0 33.3 37.5">
        <path
          d="M32.5,2c-0.7-1.1-1.9-1.8-3.1-1.9c-2.3-0.3-4.4,1.3-4.9,3.8l-0.5,2.4c-0.2,0.8,0,1.5,0.4,2.1
                    c0.1,0.1,0.1,0.2,0.2,0.2c-0.8,0.1-1.5,0.4-2.1,0.8c-1.9,1.1-3.2,3.2-3.8,6l-1.8,8.4c0,0.1-0.1,0.1-0.2,0.1l-7.2-0.7
                    c-3-0.2-5.5,0.6-7.1,2.1C0.8,26.6,0,28.4,0,30.5v0.2c0,1.4,1,2.7,2.5,3.3v0.8c0,1.5,1.3,2.7,2.9,2.7h16.4c1.6,0,3-1.2,3-2.7v-3.4
                    c0.4-0.5,0.7-1.1,0.9-1.8l5.3-17.1c0.2-0.9,0-1.8-0.5-2.5c0.7-0.3,1.3-0.8,1.6-1.5l1.1-2.7l0.1-0.2C33.4,4.4,33.2,3,32.5,2z
                    M5.2,34.2h13.9c1,0,2-0.2,2.9-0.7v1.5c0,0-0.1,0.1-0.2,0.1H5.4c-0.1,0-0.2,0-0.2-0.1V34.2z M27.3,11.3l0.9,0.2
                    c0.1,0,0.2,0.1,0.2,0.1L23,28.7c-0.5,1.6-2.1,2.8-3.9,2.8H3.8c-0.5,0-1.1-0.5-1.1-0.9v-0.2c0-1.3,0.5-2.5,1.4-3.3
                    c1-0.9,2.6-1.4,4.4-1.4l0.6,0l7.2,0.7c1.6,0.2,3-0.8,3.2-2.3l1.8-8.3c0.5-2.2,1.3-3.6,2.5-4.3C24.8,11.1,26,11,27.3,11.3z M29.7,7.5
                    c0,0,0,0.1-0.1,0.1l-2.9-0.7c-0.1,0-0.1,0-0.1-0.1L27,4.4c0.2-1,1-1.7,1.9-1.7l0.2,0c0.5,0.1,1,0.4,1.3,0.8c0.3,0.4,0.4,0.9,0.3,1.5
                    L29.7,7.5z"
        />
      </symbol>

      <symbol id="icon-Underground" viewBox="0 0 43.1 40.3">
        <g>
          <path
            d="M43.1,21.5C43.1,9.7,33.4,0,21.6,0C9.7,0,0,9.7,0,21.5c0,6.6,3.1,12.9,8.3,17c-0.2,0.5,0,1.2,0.5,1.5
                    c0.2,0.1,0.5,0.2,0.7,0.2c0.4,0,0.8-0.2,1.1-0.6l0.6-0.8h20.1l0.6,0.8c0.2,0.4,0.7,0.6,1.1,0.6c0.2,0,0.5-0.1,0.7-0.2
                    c0.4-0.3,0.6-0.7,0.6-1.1C39.8,34.9,43.1,28.4,43.1,21.5z M31.9,14.5v7h-9.4v-7H31.9z M11.2,12.6v-0.2c0-0.7,0.4-1.3,1-1.6l0,0
                    c2.9-1.3,6-1.9,9.3-1.9c3.3,0,6.4,0.6,9.3,1.9c0.6,0.2,1,0.9,1,1.6v0.2H11.2z M11.2,21.5v-7h9.4v7H11.2z M15,33.2l0.4-0.5h12
                    c0,0.1,0.1,0.3,0.2,0.4l0.5,0.7H14.6L15,33.2z M11.6,30.8c-0.2,0-0.4-0.2-0.4-0.4v-7h20.7v7c0,0.2-0.2,0.4-0.4,0.4H11.6z
                    M12.5,36.9l0.8-1.2h15.9l0.8,1.2H12.5z M33,36.7l-0.1-0.1c0-0.1-0.1-0.3-0.2-0.4l-0.8-1.1l-1.6-2.4h1.1c1.4,0,2.4-1,2.4-2.3l0-18
                    c-0.1-1.5-1-2.8-2.3-3.3C28.4,7.7,25,7,21.5,7C18,7,14.6,7.7,11.4,9.1c-1.3,0.5-2.2,1.8-2.2,3.3v17.9c0,1.3,1.1,2.3,2.4,2.3h0.5
                    l-2.4,3.7c-4.5-3.6-7.1-9.1-7.1-14.8c0-10.4,8.5-18.9,19-18.9c10.4,0,18.9,8.5,18.9,18.9C40.5,27.5,37.7,33.1,33,36.7z"
          />
          <path
            d="M28,24c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7c0-0.7-0.3-1.4-0.8-1.9
                    C29.4,24.3,28.7,24,28,24z M28.8,26.8c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
                    C28.4,25.9,28.8,26.3,28.8,26.8z"
          />
          <path
            d="M14.9,24c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7C17.7,25.2,16.5,24,14.9,24z
                    M15.8,26.8c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9S15.8,26.3,15.8,26.8z"
          />
        </g>
      </symbol>

      <symbol id="icon-New_Station" viewBox="0 0 22.5 39.3">
        <g>
          <path
            d="M15.4,15.4c1.1-1.1,1.7-2.5,1.7-4.1c0-3.2-2.6-5.8-5.8-5.8c-3.2,0-5.8,2.6-5.8,5.8c0,3.2,2.6,5.8,5.8,5.8
                        c0,0,0,0,0,0C12.8,17.1,14.3,16.5,15.4,15.4z M14.5,11.3c0,1.7-1.4,3.2-3.2,3.2c-1.8,0-3.2-1.4-3.2-3.2c0-1.7,1.4-3.2,3.2-3.2
                        C13,8.1,14.5,9.6,14.5,11.3z"
          />
          <path
            d="M11.3,34c0.4,0,0.8-0.2,1.1-0.5c0.4-0.5,10.1-12.6,10.1-22.3C22.5,5,17.5,0,11.3,0C5.1,0,0,5,0,11.2
                        C0,20.8,9.7,33,10.1,33.5C10.4,33.8,10.8,34,11.3,34z M2.9,11.2c0-4.6,3.8-8.3,8.4-8.3c4.6,0,8.4,3.7,8.4,8.3
                        c0,6.9-5.9,15.6-8.4,19C8.8,26.8,2.9,18,2.9,11.2z"
          />
          <path
            d="M20.3,36.5H2.2c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4h18.1c0.8,0,1.4-0.6,1.4-1.4S21.1,36.5,20.3,36.5z"
          />
        </g>
      </symbol>

      <symbol id="icon-zoom-out" viewBox="0 0 24 24">
        <title>zoom-out</title>
        <path
          d="M14.343 9.441v0.452c0 0.428-0.347 0.774-0.774 0.774h-7.785c-0.428 0-0.774-0.347-0.774-0.774v-0.452c0-0.428 0.347-0.774 0.774-0.774h7.785c0.428 0 0.774 0.347 0.774 0.774zM9.667 17.333c4.235 0 7.667-3.433 7.667-7.667s-3.432-7.667-7.667-7.667c-4.234 0-7.667 3.433-7.667 7.667s3.433 7.667 7.667 7.667v0zM23.482 23.211l-0.211 0.221c-0.295 0.309-0.785 0.321-1.095 0.026-0.003-0.002-0.005-0.005-0.008-0.007l-6.41-6.278c-1.663 1.351-3.783 2.161-6.092 2.161-5.339 0-9.667-4.328-9.667-9.667s4.328-9.667 9.667-9.667c5.339 0 9.667 4.328 9.667 9.667 0 2.309-0.81 4.429-2.161 6.092l6.299 6.374c0.294 0.298 0.299 0.775 0.010 1.078z"
        ></path>
      </symbol>

      <symbol id="icon-zoom-in" viewBox="0 0 24 24">
        <title>zoom-in</title>
        <path
          d="M10.667 8.667h2.902c0.428 0 0.774 0.347 0.774 0.774v0.452c0 0.428-0.347 0.774-0.774 0.774h-2.902l0.008 2.89c0.001 0.428-0.345 0.775-0.772 0.776-0.001 0-0.001 0-0.002 0h-0.452c-0.427 0-0.773-0.345-0.774-0.772l-0.008-2.895h-2.883c-0.428 0-0.774-0.347-0.774-0.774v-0.452c0-0.428 0.347-0.774 0.774-0.774h2.883l0.008-2.895c0.001-0.427 0.347-0.772 0.774-0.772h0.452c0.428 0 0.774 0.347 0.774 0.774 0 0.001 0 0.001 0 0.002l-0.008 2.89zM9.667 17.333c4.235 0 7.667-3.433 7.667-7.667s-3.432-7.667-7.667-7.667c-4.234 0-7.667 3.433-7.667 7.667s3.433 7.667 7.667 7.667v0zM23.482 23.211l-0.211 0.221c-0.295 0.309-0.785 0.321-1.095 0.026-0.003-0.002-0.005-0.005-0.008-0.007l-6.41-6.278c-1.663 1.351-3.783 2.161-6.092 2.161-5.339 0-9.667-4.328-9.667-9.667s4.328-9.667 9.667-9.667c5.339 0 9.667 4.328 9.667 9.667 0 2.309-0.81 4.429-2.161 6.092l6.299 6.374c0.294 0.298 0.299 0.775 0.010 1.078z"
        ></path>
      </symbol>

      <symbol id="icon-play" viewBox="0 0 32.5 32.5">
        <path
          d="M16.25,32.5C7.29,32.5,0,25.21,0,16.25C0,7.29,7.29,0,16.25,0C25.21,0,32.5,7.29,32.5,16.25
                C32.5,25.21,25.21,32.5,16.25,32.5z M16.25,2.5C8.67,2.5,2.5,8.67,2.5,16.25C2.5,23.83,8.67,30,16.25,30C23.83,30,30,23.83,30,16.25
                C30,8.67,23.83,2.5,16.25,2.5z M13.25,23.66c-0.16,0-0.31-0.02-0.47-0.05c-0.59-0.12-1.09-0.47-1.42-0.98
                C11.13,22.27,11,21.84,11,21.41v-9.31c0-1.24,1.01-2.25,2.25-2.25c0.44,0,0.86,0.13,1.23,0.36l7.17,4.66
                c0.5,0.33,0.85,0.83,0.97,1.42s0.01,1.19-0.31,1.69c-0.17,0.26-0.4,0.49-0.66,0.66l-7.17,4.66C14.11,23.53,13.69,23.66,13.25,23.66z
                M13.5,12.55v8.39l6.46-4.2L13.5,12.55z"
        />
      </symbol>

      <symbol id="icon-pause" viewBox="0 0 32.5 32.5">
        <path
          d="M16.25,32.5C7.29,32.5,0,25.21,0,16.25C0,7.29,7.29,0,16.25,0C25.21,0,32.5,7.29,32.5,16.25
                C32.5,25.21,25.21,32.5,16.25,32.5z M16.25,2.5C8.67,2.5,2.5,8.67,2.5,16.25C2.5,23.83,8.67,30,16.25,30C23.83,30,30,23.83,30,16.25
                C30,8.67,23.83,2.5,16.25,2.5z M14.25,20.99v-9.74c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v9.74c0,0.83,0.67,1.5,1.5,1.5
                S14.25,21.82,14.25,20.99z M21.25,20.99v-9.74c0-0.83-0.67-1.5-1.5-1.5s-1.5,0.67-1.5,1.5v9.74c0,0.83,0.67,1.5,1.5,1.5
                S21.25,21.82,21.25,20.99z"
        />
      </symbol>

      <symbol id="icon-walking" viewBox="0 0 26.52 43.52">
        <path
          d="M18.08,4.84c0-2.67-2.16-4.84-4.82-4.84S8.44,2.17,8.44,4.84s2.16,4.84,4.82,4.84 S18.08,7.5,18.08,4.84z M10.85,4.84c0-1.33,
                    1.08-2.42,2.41-2.42s2.41,1.08,2.41,2.42s-1.08,2.42-2.41,2.42S10.85,6.17,10.85,4.84z"
        />
        <path
          d="M2.6,16.98l-2.41,7.25c-0.63,1.9,0.4,3.95,2.29,4.59c0.92,0.31,1.9,0.24,2.76-0.2c0.86-0.43,1.51-1.18,1.81-2.1
                    l1.94-5.83l0.66-0.33v5.44l-5.7,12.58c-0.82,1.82-0.02,3.97,1.8,4.8c0.47,0.22,0.98,0.33,1.49,0.33c1.42,0,2.71-0.83,3.29-2.13
                    l3.87-8.53l1.73,1.74l2.14,6.44c0.49,1.48,1.87,2.48,3.43,2.48c0,0,0,0,0,0c0.39,0,0.77-0.06,1.14-0.19
                    c0.92-0.31,1.66-0.95,2.09-1.82c0.43-0.87,0.5-1.85,0.2-2.77l-2.41-7.25c-0.18-0.53-0.48-1.02-0.87-1.42l-4.97-4.98v-3.33l3.86,2.9
                    c0.63,0.47,1.38,0.73,2.17,0.73c1.13,0,2.22-0.54,2.89-1.45c1.2-1.6,0.87-3.88-0.72-5.08l-9.64-7.25c-0.07-0.06-0.15-0.1-0.23-0.15
                    c-0.01-0.01-0.03-0.02-0.04-0.03c0,0-0.01,0-0.01-0.01c-0.12-0.08-0.25-0.14-0.38-0.21c-0.03-0.01-0.05-0.02-0.08-0.03
                    c-0.1-0.04-0.21-0.09-0.32-0.12c-0.04-0.01-0.08-0.03-0.12-0.04c-0.1-0.03-0.19-0.05-0.29-0.07c-0.04-0.01-0.08-0.02-0.12-0.03
                    c-0.13-0.02-0.25-0.04-0.38-0.04c-0.06,0-0.13,0-0.19,0c-0.07,0-0.14,0-0.21,0c-0.07,0-0.15,0.01-0.22,0.02
                    c-0.03,0-0.06,0-0.09,0.01c-0.04,0-0.07,0.01-0.1,0.02c-0.06,0.01-0.13,0.02-0.19,0.04c-0.12,0.03-0.23,0.06-0.34,0.1
                    c-0.01,0-0.02,0.01-0.04,0.01c-0.14,0.05-0.28,0.11-0.41,0.17c-0.01,0-0.01,0-0.02,0.01l-7.23,3.63C3.56,15.32,2.9,16.08,2.6,16.98z
                    M16.88,15.71l6.75,5.08c0.53,0.4,0.64,1.16,0.24,1.69c-0.39,0.52-1.17,0.63-1.69,0.24l-5.3-3.99V15.71z M8.33,40.39
                    c-0.27,0.59-1,0.87-1.6,0.6c-0.61-0.28-0.87-0.99-0.6-1.6l4.62-10.18l1.82,1.83L8.33,40.39z M5.49,17.05l7.23-3.63
                    c0.38-0.19,0.81-0.17,1.17,0.05c0.35,0.22,0.57,0.61,0.57,1.03v11.09c0,0.32,0.13,0.63,0.35,0.85l5.32,5.34
                    c0.13,0.13,0.23,0.3,0.29,0.47l2.41,7.25c0.21,0.63-0.13,1.32-0.76,1.53c-0.12,0.04-0.25,0.06-0.38,0.06h0h0
                    c-0.52,0-0.98-0.33-1.14-0.83l-2.23-6.71c-0.06-0.18-0.16-0.34-0.29-0.47l-5.63-5.64c-0.23-0.23-0.35-0.53-0.35-0.85v-8.18
                    c0-0.42-0.22-0.81-0.57-1.03c-0.19-0.12-0.41-0.18-0.63-0.18c-0.18,0-0.37,0.04-0.54,0.13l-2.85,1.43c-0.29,0.14-0.5,0.39-0.6,0.7
                    l-2.1,6.3c-0.1,0.31-0.32,0.55-0.6,0.7c-0.29,0.14-0.62,0.17-0.92,0.07C2.6,26.32,2.26,25.63,2.47,25l2.41-7.25
                    C4.98,17.45,5.2,17.19,5.49,17.05z"
        />
      </symbol>

      <symbol id="icon-hospital" viewBox="0 0 41.58 35.64">
        <path
          d="M15.84,33.66v-6.93c0-0.55,0.44-0.99,0.99-0.99h7.92c0.55,0,0.99,0.44,0.99,0.99v6.93h3.96V5.94
                    H11.88v27.72H15.84z M3.96,33.66V12.87c0-0.55,0.44-0.99,0.99-0.99H9.9V4.95c0-0.55,0.44-0.99,0.99-0.99h2.97V0.99
                    C13.86,0.44,14.3,0,14.85,0h11.88c0.55,0,0.99,0.44,0.99,0.99v2.97h2.97c0.55,0,0.99,0.44,0.99,0.99v6.93h4.95
                    c0.55,0,0.99,0.44,0.99,0.99v20.79h2.97c0.55,0,0.99,0.44,0.99,0.99c0,0.55-0.44,0.99-0.99,0.99H0.99C0.44,35.64,0,35.2,0,34.65
                    c0-0.55,0.44-0.99,0.99-0.99H3.96z M31.68,33.66h3.96v-19.8h-3.96V33.66z M5.94,33.66H9.9v-19.8H5.94V33.66z M15.84,3.96h9.9V1.98
                    h-9.9V3.96z M23.76,11.88h2.97c0.55,0,0.99,0.44,0.99,0.99v3.96c0,0.55-0.44,0.99-0.99,0.99h-2.97v2.97c0,0.55-0.44,0.99-0.99,0.99
                    h-3.96c-0.55,0-0.99-0.44-0.99-0.99v-2.97h-2.97c-0.55,0-0.99-0.44-0.99-0.99v-3.96c0-0.55,0.44-0.99,0.99-0.99h2.97V8.91
                    c0-0.55,0.44-0.99,0.99-0.99h3.96c0.55,0,0.99,0.44,0.99,0.99V11.88z M25.74,13.86h-2.97c-0.55,0-0.99-0.44-0.99-0.99V9.9H19.8v2.97
                    c0,0.55-0.44,0.99-0.99,0.99h-2.97v1.98h2.97c0.55,0,0.99,0.44,0.99,0.99v2.97h1.98v-2.97c0-0.55,0.44-0.99,0.99-0.99h2.97V13.86z
                    M17.82,33.66h5.94v-5.94h-5.94V33.66z"
        />
      </symbol>

      <symbol id="icon-calendar" viewBox="0 0 30.11 34.41">
        <path
          d="M26.88,4.3h-3.23V0.81c0-0.44-0.36-0.81-0.81-0.81h-0.54c-0.44,0-0.81,0.36-0.81,0.81V4.3H8.6V0.81
                    C8.6,0.36,8.24,0,7.8,0H7.26C6.81,0,6.45,0.36,6.45,0.81V4.3H3.23C1.44,4.3,0,5.75,0,7.53v23.65c0,1.78,1.44,3.23,3.23,3.23h23.65
                    c1.78,0,3.23-1.44,3.23-3.23V7.53C30.11,5.75,28.66,4.3,26.88,4.3z M3.23,6.45h23.65c0.59,0,1.08,0.48,1.08,1.08v3.23H2.15V7.53
                    C2.15,6.94,2.63,6.45,3.23,6.45z M26.88,32.26H3.23c-0.59,0-1.08-0.48-1.08-1.08V12.9h25.8v18.28
                    C27.96,31.77,27.47,32.26,26.88,32.26z M13.8,28.72l-5.37-3.5c-0.38-0.24-0.48-0.75-0.24-1.12l0.44-0.68
                    c0.24-0.38,0.75-0.48,1.12-0.24l4.03,2.62l6.11-9.33c0.24-0.37,0.75-0.48,1.12-0.24l0.67,0.44c0.37,0.24,0.48,0.75,0.24,1.12
                    l-7,10.68C14.68,28.86,14.17,28.96,13.8,28.72z"
        />
      </symbol>

      <symbol id="icon-trees" viewBox="0 0 40.61 31.35">
        <path
          d="M26.9,18.44v3.3h4.84c1.73,0,3.3-0.7,4.45-1.82c1.12-1.14,1.82-2.72,1.82-4.45
                    c0-1.51-0.51-2.89-1.41-3.99c-0.9-1.09-2.16-1.9-3.6-2.19l-0.8-0.17c0-0.27,0.02-0.53,0.02-0.8c0-0.07,0,0.05,0-0.12
                    c0-1.73-0.7-3.3-1.85-4.45c-1.14-1.12-2.72-1.82-4.45-1.82s-3.3,0.7-4.45,1.82c-1.14,1.14-1.85,2.72-1.85,4.45l0,0l0.02,0.95
                    l-0.8,0.15c-1.43,0.29-2.7,1.09-3.6,2.19c-0.9,1.09-1.41,2.48-1.41,3.99c0,1.73,0.7,3.3,1.82,4.45c1.14,1.12,2.72,1.82,4.45,1.82
                    h4.84V18.3l-3.11-3.11c-0.36-0.39-0.36-1,0-1.36c0.39-0.39,1-0.39,1.39,0l2.87,2.87l3.5-2.04c0.46-0.27,1.04-0.1,1.31,0.36
                    c0.27,0.46,0.12,1.04-0.34,1.31L26.9,18.44L26.9,18.44z M0.97,31.35C0.44,31.35,0,30.91,0,30.38s0.44-0.97,0.97-0.97h9.48v-3.79
                    H7.07c-1.77,0-3.38-0.73-4.52-1.9c-1.17-1.14-1.9-2.75-1.9-4.52c0-1.53,0.56-2.94,1.46-4.06c0.75-0.92,1.77-1.63,2.94-2.02
                    c0.15-1.51,0.83-2.87,1.85-3.89c1.14-1.17,2.75-1.9,4.52-1.9c0.9,0,1.75,0.19,2.53,0.53c0.51,0.22,1.02,0.51,1.46,0.87
                    c0.7-0.49,1.48-0.87,2.33-1.14c0.15-2.02,1.02-3.84,2.38-5.2C21.6,0.92,23.67,0,25.93,0s4.33,0.92,5.81,2.41
                    c1.36,1.36,2.24,3.18,2.38,5.2c1.58,0.49,2.96,1.43,3.99,2.67c1.14,1.41,1.85,3.23,1.85,5.2c0,2.26-0.92,4.33-2.41,5.81
                    s-3.55,2.41-5.81,2.41H26.9v5.71h12.76c0.53,0,0.95,0.44,0.95,0.97s-0.41,0.97-0.95,0.97H0.97z M12.39,29.4h12.56v-5.71h-4.62
                    c-0.53,0.53-1.17,1-1.87,1.31c-0.83,0.39-1.75,0.61-2.7,0.61h-3.38V29.4L12.39,29.4z M13.83,20.75l-1.43,0.8v2.11h3.38
                    c0.66,0,1.26-0.15,1.82-0.39c-1.26-0.39-2.38-1.09-3.28-1.99C14.14,21.12,13.97,20.92,13.83,20.75z M11.59,19.8l1.17-0.68
                    c-0.53-1.09-0.85-2.33-0.85-3.64c0-1.97,0.7-3.79,1.85-5.2c0.07-0.07,0.12-0.15,0.19-0.22c-0.24-0.17-0.49-0.29-0.75-0.41
                    c-0.56-0.24-1.14-0.36-1.77-0.36c-1.24,0-2.36,0.51-3.16,1.31s-1.31,1.92-1.31,3.16l0,0v0.9L6.17,14.8
                    c-1.02,0.22-1.92,0.78-2.58,1.58c-0.63,0.75-1,1.75-1,2.82c0,1.24,0.51,2.36,1.31,3.16c0.8,0.8,1.92,1.31,3.16,1.31h3.38v-2.26
                    l-2.26-2.26c-0.36-0.36-0.36-1,0-1.36c0.39-0.39,1-0.39,1.39,0L11.59,19.8z"
        />
      </symbol>

      <symbol id="icon-wheelchair" viewBox="0 0 32 41.61">
        <path
          d="M19.13,5.18c0-2.86-2.32-5.18-5.18-5.18S8.77,2.32,8.77,5.18s2.32,5.18,5.18,5.18
                    C16.81,10.35,19.12,8.04,19.13,5.18z M11.29,5.18c0-1.08,0.65-2.04,1.64-2.46s2.14-0.18,2.9,0.58s0.99,1.9,0.58,2.9
                    c-0.41,0.99-1.38,1.64-2.46,1.64C12.48,7.83,11.29,6.64,11.29,5.18L11.29,5.18z M25.4,38.53c0.56,1.11,1.68,1.84,2.92,1.9
                    s2.43-0.54,3.11-1.58c0.68-1.04,0.75-2.37,0.19-3.48L27,26.11c-0.59-1.17-1.79-1.91-3.11-1.91h-5.27v-8.6
                    c-0.01-1.26-0.52-2.47-1.42-3.36c-0.89-0.86-2.08-1.31-3.31-1.26H4.36c-1.67,0-3.03,1.36-3.04,3.03v5.56
                    c-0.04,1.11,0.53,2.16,1.48,2.72s2.15,0.57,3.1,0s1.53-1.61,1.48-2.72v-2.52h2.04v9.46c0,2.58,2.09,4.66,4.66,4.67h7.64L25.4,38.53z
                    M11.94,26.51v-10.7c0-0.7-0.56-1.26-1.26-1.26H6.13c-0.7,0-1.26,0.56-1.26,1.26v3.78c0.02,0.19-0.08,0.38-0.24,0.49
                    c-0.17,0.1-0.38,0.1-0.54,0c-0.17-0.1-0.26-0.29-0.24-0.49v-5.56c0-0.28,0.23-0.51,0.52-0.51h9.34h0.08h0.21
                    c0.54-0.03,1.07,0.16,1.47,0.53c0.41,0.41,0.64,0.97,0.66,1.55v9.86c0,0.7,0.56,1.26,1.26,1.26h6.51c0.35,0,0.68,0.2,0.84,0.52
                    l4.62,9.24l0,0c0.12,0.23,0.14,0.49,0.06,0.74c-0.08,0.24-0.25,0.45-0.48,0.56c-0.47,0.23-1.04,0.04-1.29-0.42l-3.99-8.01
                    c-0.21-0.43-0.65-0.7-1.13-0.7h-8.4c-0.57,0-1.12-0.22-1.53-0.62C12.17,27.63,11.94,27.08,11.94,26.51z M3.65,25.67
                    c-0.95,1.71-1.32,3.69-1.05,5.64c0.59,4.04,3.79,7.2,7.85,7.73c3.54,0.45,7.01-1.24,8.85-4.31c0.23-0.39,0.66-0.62,1.11-0.61
                    c0.45,0.01,0.87,0.26,1.08,0.66c0.22,0.4,0.21,0.88-0.03,1.27c-2.37,3.89-6.79,6.03-11.31,5.49c-3.81-0.47-7.14-2.8-8.89-6.22
                    c-1.75-3.42-1.68-7.48,0.18-10.84c0.34-0.61,1.1-0.83,1.71-0.49s0.83,1.1,0.49,1.71V25.67z"
        />
      </symbol>

      <symbol id="icon-cricket" viewBox="0 0 38.03 38.87">
        <path
          d="M23.33,27.71c1.21-0.72,2.66-0.89,4.03-0.48c1.36,0.41,2.5,1.28,3.22,2.49
                    c1.45,2.56,0.56,5.78-2.01,7.23c-0.8,0.48-1.7,0.72-2.57,0.72c-0.48,0-0.97-0.07-1.45-0.17c-1.36-0.41-2.5-1.28-3.22-2.49
                    c-0.73-1.21-0.89-2.66-0.48-4.01C21.15,29.48,22.05,28.35,23.33,27.71L23.33,27.71z"
        />
        <path
          d="M18.3,10.34c1.37-1.32,3.59-1.32,4.95,0c0.13,0.12,0.35,0.12,0.47,0l7.72-7.45l2.28-2.22
                    c0.44-0.42,1.15-0.42,1.57,0l2.06,1.99c0.44,0.42,0.44,1.11,0,1.51l-10,9.67c-0.13,0.12-0.13,0.33,0,0.46l0.24,0.23
                    c0.64,0.62,0.95,1.37,0.95,2.13c0,0.85-0.31,1.6-0.95,2.13L14.36,31.75l-4.08,3.95c-0.64,0.62-1.42,0.92-2.2,0.92
                    s-1.57-0.3-2.2-0.92L1.3,31.27c-0.64-0.62-0.95-1.37-0.95-2.13c0-0.92,0.31-1.67,0.86-2.27l0.02-0.02L18.3,10.34z M22.56,12.41
                    l-2.84,4.97c-0.16,0.28,0.16,0.6,0.46,0.44l5.14-2.75c0.2-0.11,0.24-0.35,0.07-0.51l-2.29-2.22C22.94,12.18,22.67,12.21,22.56,12.41
                    L22.56,12.41z M35.69,3.19l-0.95-0.92c-0.13-0.12-0.35-0.12-0.47,0l-0.95,0.92c-0.13,0.12-0.13,0.33,0,0.46l0.95,0.92
                    c0.13,0.12,0.35,0.12,0.47,0l0.95-0.92C35.82,3.53,35.82,3.32,35.69,3.19z M33.18,5.62l-0.95-0.92c-0.13-0.12-0.35-0.12-0.47,0
                    l-6.92,6.69c-0.13,0.12-0.13,0.33,0,0.46l0.95,0.92c0.13,0.12,0.35,0.12,0.47,0l6.92-6.69C33.3,5.96,33.3,5.76,33.18,5.62z
                    M26.47,17.81c0.31-0.3,0.47-0.69,0.47-1.07c0-0.12-0.02-0.26-0.07-0.41c-0.05-0.19-0.29-0.3-0.49-0.19L17.4,21
                    c-0.16,0.07-0.24,0.07-0.4,0.07c-0.24,0-0.4-0.07-0.55-0.23c-0.24-0.23-0.31-0.62-0.16-0.92l5.03-8.68c0.11-0.18,0.02-0.41-0.2-0.48
                    c-0.13-0.04-0.27-0.05-0.42-0.05c-0.4,0-0.78,0.16-1.11,0.46L7.15,23.27c-0.13,0.12-0.13,0.33,0,0.46l6.37,6.17
                    c0.13,0.12,0.35,0.12,0.47,0L26.47,17.81z M12.47,30.9l-6.37-6.17c-0.13-0.12-0.35-0.12-0.47,0l-0.71,0.69
                    c-0.13,0.12-0.13,0.33,0,0.46l6.37,6.17c0.13,0.12,0.35,0.12,0.47,0l0.71-0.69C12.6,31.24,12.6,31.04,12.47,30.9z M2.29,30.13
                    c0.02,0.02,0.02,0.04,0.04,0.05l4.55,4.4c0.64,0.62,1.57,0.62,2.2,0l1.09-0.99c0.15-0.12,0.15-0.33,0-0.46L3.8,26.97
                    c-0.13-0.12-0.35-0.12-0.47,0l-1.02,0.99c-0.31,0.3-0.46,0.65-0.47,1.02v0.07C1.92,29.47,2.07,29.83,2.29,30.13L2.29,30.13z"
        />
      </symbol>

      <symbol id="icon-group" viewBox="0 0 46.7 28.12">
        <path
          d="M0.15,20.17v2.25c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.25c0.01-3.69,2.69-6.83,6.33-7.43
                    s7.18,1.53,8.37,5.03c-1.92,1.8-3.01,4.32-3,6.95v2.25c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.25c-0.23-2.83,1.15-5.56,3.57-7.05
                    s5.48-1.49,7.9,0s3.81,4.21,3.57,7.05v2.25c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1v-2.25c0.01-2.63-1.08-5.15-3-6.95
                    c1.23-3.42,4.73-5.48,8.32-4.89c3.59,0.59,6.25,3.65,6.33,7.29v2.25c0,0.55,0.45,1,1,1s1-0.45,1-1v-2.25
                    c-0.02-3.83-2.32-7.27-5.85-8.75c2.16-1.61,3.06-4.42,2.21-6.98c-0.85-2.56-3.24-4.29-5.93-4.29s-5.09,1.73-5.93,4.29
                    s0.04,5.37,2.21,6.98c-2.29,0.97-4.1,2.81-5.05,5.1l-1.15-0.6c2.16-1.61,3.06-4.42,2.21-6.98s-3.24-4.29-5.93-4.29
                    s-5.09,1.73-5.93,4.29s0.04,5.37,2.21,6.98l-1.15,0.6c-0.95-2.29-2.76-4.13-5.05-5.1c2.16-1.61,3.06-4.42,2.21-6.98
                    s-3.24-4.29-5.93-4.29S4.59,1.88,3.74,4.44s0.04,5.37,2.21,6.98C2.44,12.92,0.16,16.36,0.15,20.17z M32.75,6.42
                    c0-2.35,1.9-4.25,4.25-4.25s4.25,1.9,4.25,4.25s-1.9,4.25-4.25,4.25S32.75,8.77,32.75,6.42z M19.1,10.97
                    c-0.02-1.72,1-3.29,2.59-3.96s3.42-0.32,4.65,0.89c1.23,1.21,1.6,3.04,0.94,4.64c-0.65,1.59-2.21,2.64-3.93,2.64
                    C21.02,15.17,19.13,13.3,19.1,10.97L19.1,10.97z M5.45,6.42c0-2.35,1.9-4.25,4.25-4.25s4.25,1.9,4.25,4.25s-1.9,4.25-4.25,4.25
                    S5.45,8.77,5.45,6.42z"
        />
      </symbol>

      <symbol id="icon-distance" viewBox="0 0 9.08 24.77">
        <path
          d="M8.79 20.9c.25-.23.35-.57.27-.9s-.34-.58-.66-.66-.67.02-.9.27l-2.03 2.03V3.13L7.5 5.16c.23.25.57.35.9.27s.58-.34.66-.66-.03-.67-.27-.9L5.19.27a.905.905 0 0 0-1.29 0L.3 3.87c-.25.23-.35.57-.27.89s.34.58.66.66.67-.02.9-.27l2.03-2.03v18.52l-2.03-2.03c-.23-.25-.57-.35-.9-.27s-.58.34-.66.66.02.67.27.9l3.6 3.6c.36.36.94.36 1.29 0l3.6-3.6z"
        />
      </symbol>

      <symbol id="icon-calendar-alt" viewBox="0 0 18.22 20.78">
        <path
          d="M12.15 16.94h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63zM8.31 13.1h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63zM12.15 13.1h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63zM4.47 16.94h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63zM8.31 16.94h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63z"
        />
        <path
          d="M16.15 2.56h-1.77V.63a.64.64 0 0 0-.63-.63h-.32a.64.64 0 0 0-.63.63v1.93H5.42V.63A.64.64 0 0 0 4.79 0h-.32a.64.64 0 0 0-.63.63v1.93H2.07C.93 2.56 0 3.49 0 4.63v14.08c0 1.14.93 2.07 2.07 2.07h14.08c1.14 0 2.07-.93 2.07-2.07V4.63c0-1.14-.93-2.07-2.07-2.07zM1.58 7.98h15.06v10.73c0 .27-.22.49-.49.49H2.07a.49.49 0 0 1-.49-.49V7.98zm0-3.35c0-.27.22-.49.49-.49h14.08c.27 0 .49.22.49.49V6.4H1.58V4.63z"
        />
        <path
          d="M4.47 13.1h1.6c.34 0 .63-.29.63-.63v-1.6a.64.64 0 0 0-.63-.63h-1.6a.64.64 0 0 0-.63.63v1.6c0 .34.29.63.63.63z"
        />
      </symbol>

      <symbol id="icon-concourse" viewBox="0 0 450 450">
        <path
          d="M362.33 107.72h-65.64a29.79 29.79 0 00-21.2 8.78L137.15 254.8a18.35 18.35 0 01-13.06 5.41H87.67a41 41 0 100 82.07h65.64a29.79 29.79 0 0021.2-8.78l138.35-138.3a18.35 18.35 0 0113.05-5.41h36.42a41 41 0 100-82.07zm0 70.55h-36.42a29.8 29.8 0 00-21.2 8.78l-138.34 138.3a18.35 18.35 0 01-13.06 5.41H87.67a29.52 29.52 0 110-59h36.42A29.83 29.83 0 00145.3 263l138.33-138.3a18.35 18.35 0 0113.06-5.41h65.64a29.52 29.52 0 110 59z"
        />
        <path
          d="M110.63 250.65a5.76 5.76 0 005.76-5.77v-50.1a2.9 2.9 0 012.9-2.9h6.31A17.15 17.15 0 01142.73 209v14.85a5.76 5.76 0 1011.52 0V209a28.68 28.68 0 00-28.65-28.65h-6.31a14.44 14.44 0 00-14.42 14.42v50.1a5.76 5.76 0 005.76 5.78zM123.84 173.8a19 19 0 10-19-19 19 19 0 0019 19zm0-26.42a7.45 7.45 0 11-7.45 7.45 7.46 7.46 0 017.45-7.45z"
        />
      </symbol>
      <symbol id="icon-high-elevation" viewBox="0 0 450 450">
        <path
          d="M416.61 114.49H106.49a5.76 5.76 0 100 11.52H256.4v24.4h-20.87a52.44 52.44 0 00-49.19 34.43H85.08a13.87 13.87 0 01-12.56-8.05l-5-10.91a5.75 5.75 0 00-5.23-3.36h-28.9a5.76 5.76 0 00-5.33 7.94l23.1 56.59a5.75 5.75 0 005.33 3.59h11.27a160.54 160.54 0 0138.55 4.7l80.54 19.94a52.43 52.43 0 0048.68 33.13h6.57v33.68c-19-1.39-31.81-3-32-3a5.76 5.76 0 00-1.48 11.42 732.23 732.23 0 0083.11 5.05 659.11 659.11 0 0083.19-5.06 5.76 5.76 0 10-1.63-11.4c-.21 0-12.43 1.74-32 3.15v-33.84h32.39A23.41 23.41 0 00397.05 265a114.06 114.06 0 00-.83-13.78 1.49 1.49 0 000-.21 114.85 114.85 0 00-84.5-96.78l-.3-.08a114.29 114.29 0 00-28.9-3.7h-14.6V126h148.69a5.76 5.76 0 000-11.52zM109.08 224.15a172.27 172.27 0 00-41.32-5h-7.4L42 174h16.58l3.47 7.56a25.43 25.43 0 0023 14.76h98.5a52.15 52.15 0 00-.41 6.41v33.28a52.63 52.63 0 00.43 6.56zm220.7 98.78a693.45 693.45 0 01-38.08 1.06c-13.5 0-26.53-.48-38.08-1.15v-34.43h76.16zm43.91-46H235.53a40.89 40.89 0 01-40.84-40.84v-33.32a40.89 40.89 0 0140.84-40.84h7A101.83 101.83 0 00323.92 251q.31.08.63.12a100.15 100.15 0 0010.26 1.47l50.38 4.43c.21 2.64.34 5.29.34 8a11.86 11.86 0 01-11.84 11.87zm9.94-31.54l-47.8-4.19c-1.86-.17-3.7-.41-5.53-.69l-12.77-72.4a103.4 103.4 0 0166.1 77.24zm-78.43-80.91l12.92 73.26a90.22 90.22 0 01-64-75.81h28.38a103 103 0 0122.7 2.55z"
        />
      </symbol>
      <symbol id="icon-low-elevation" viewBox="0 0 450 450">
        <path
          d="M438.25 115.46H282.09a5.76 5.76 0 100 11.52h72.32v31.82a19.51 19.51 0 00-13.71 18.6v6.48h-39.25a19.49 19.49 0 00-18.28-12.82h-116.5a19.49 19.49 0 00-18.28 12.82H109.3v-6.48a19.51 19.51 0 00-13.71-18.6V127h72.32a5.76 5.76 0 000-11.52H11.75a5.76 5.76 0 100 11.52h72.32v31.8a19.5 19.5 0 00-13.72 18.6v24.48a19.48 19.48 0 0039 0v-6.48h38.53a19.49 19.49 0 0018.79 14.6h8.39v20.2a28.7 28.7 0 00.84 6.92A39.13 39.13 0 00148.75 264l-16.84 59h-11.2a5.76 5.76 0 100 11.52h31.09a5.76 5.76 0 000-11.52h-7.91l15.94-55.87A27.57 27.57 0 01181 247.75a28.94 28.94 0 0023.07 11.46h41.73a29 29 0 0023.07-11.46 27.56 27.56 0 0121.13 19.4L306 323h-7.9a5.76 5.76 0 100 11.52h31.08a5.76 5.76 0 000-11.52h-11.2l-16.84-59a39.13 39.13 0 00-27.15-26.86 28.7 28.7 0 00.84-6.92V210h8.39A19.49 19.49 0 00302 195.4h38.7v6.48a19.48 19.48 0 0038.95 0V177.4a19.5 19.5 0 00-13.72-18.6V127h72.32a5.76 5.76 0 000-11.52zM97.78 201.88a8 8 0 01-15.91 0V177.4a8 8 0 0115.91 0zm165.48 28.33a17.5 17.5 0 01-17.47 17.48h-41.73a17.5 17.5 0 01-17.48-17.48V210h76.68zm19.91-31.72h-116.5a8 8 0 110-15.91h116.5a8 8 0 110 15.91zm85 3.39a8 8 0 01-15.91 0V177.4a8 8 0 0115.91 0z"
        />
      </symbol>
      <symbol id="icon-person" viewBox="0 0 450 450">
        <path
          d="M290.32 135.4A65.32 65.32 0 10225 200.72a65.4 65.4 0 0065.32-65.32zM225 189.2a53.8 53.8 0 1153.79-53.8A53.86 53.86 0 01225 189.2zM172.72 196.8c-30.88 11.06-48.58 38.73-48.58 75.9v101.45a5.76 5.76 0 1011.52 0V272.7c0-32 14.92-55.73 40.94-65a5.76 5.76 0 00-3.88-10.85zM277.28 196.8a5.76 5.76 0 00-3.88 10.85c26 9.32 40.94 33 40.94 65v101.5a5.76 5.76 0 1011.52 0V272.7c0-37.17-17.7-64.84-48.58-75.9z"
        />
      </symbol>
      <symbol id="icon-platform" viewBox="0 0 450 450">
        <path
          d="M341.69 125.82a242.76 242.76 0 00-127.39 0 41 41 0 00-30.13 39.42V298.8a16.49 16.49 0 0016.47 16.47h15.43l-24.39 34.35a5.76 5.76 0 009.39 6.67L211.92 341h132.16l10.85 15.28a5.76 5.76 0 109.39-6.67l-24.4-34.35h15.43a16.48 16.48 0 0016.47-16.47V165.24a41 41 0 00-30.13-39.42zm-146 108.84V176.5h76.54v58.16zm88.06-58.16h76.55v58.16h-76.54zm-66.43-39.56a231.26 231.26 0 01121.34 0 29.39 29.39 0 0121.62 28H195.71a29.39 29.39 0 0121.62-28zM335.9 329.49H220.1l10.11-14.22h95.58zm19.45-25.74H200.64a5 5 0 01-4.94-4.95v-52.61h164.6v52.61a5 5 0 01-4.95 4.95z"
        />
        <path
          d="M225.66 256a19 19 0 1019 19 19 19 0 00-19-19zm0 26.43a7.46 7.46 0 117.45-7.45 7.46 7.46 0 01-7.45 7.44zM330.29 256a19 19 0 1019 19 19 19 0 00-19-19zm0 26.43a7.46 7.46 0 117.46-7.45 7.47 7.47 0 01-7.46 7.44zM144.35 88.81H83.94a5.76 5.76 0 100 11.52h54.65V133H83.94a5.76 5.76 0 00-5.76 5.76v188.47a5.76 5.76 0 005.76 5.77h54.65v22.44a5.76 5.76 0 0011.52 0v-28.2a5.76 5.76 0 00-5.76-5.76H89.7V144.53h54.65a5.76 5.76 0 005.76-5.76v-44.2a5.76 5.76 0 00-5.76-5.76z"
        />
      </symbol>
      <symbol id="icon-station" viewBox="0 0 450 450">
        <path
          d="M420.7 308.8h-39.36v-63a13.83 13.83 0 0113.81-13.8h25.55a4.5 4.5 0 100-9h-13.07v-47.9h6.49a4.49 4.49 0 004.5-4.5v-39.84a4.5 4.5 0 00-4.5-4.5H302.7v-9.71a4.49 4.49 0 00-4.5-4.5H151.12a4.49 4.49 0 00-4.5 4.5v9.71H35.2a4.5 4.5 0 00-4.5 4.5v39.84a4.49 4.49 0 004.5 4.5h6.49v119.75h-13a4.5 4.5 0 100 9h39.8A22.1 22.1 0 0089 317.8h4.27a8.24 8.24 0 1013.55 0h12.89a8.24 8.24 0 1013.55 0h12.9a8.13 8.13 0 00-1.46 4.66 8.23 8.23 0 0016.46 0 8.13 8.13 0 00-1.46-4.66h12.9a8.13 8.13 0 00-1.46 4.66 8.23 8.23 0 0016.46 0 8.13 8.13 0 00-1.46-4.66h61.46a8.24 8.24 0 1013.55 0H274a8.24 8.24 0 1013.55 0h12.9a8.13 8.13 0 00-1.46 4.66 8.23 8.23 0 1016.46 0 8.13 8.13 0 00-1.45-4.66h12.9a8.13 8.13 0 00-1.46 4.66 8.23 8.23 0 0016.46 0 8.13 8.13 0 00-1.46-4.66h17.66a4.5 4.5 0 004.5-4.5v-67.58A22.73 22.73 0 00339.89 223h-218.5A54.5 54.5 0 0067 277.44v17.41H50.69V175.1h347.94V223h-3.48a22.83 22.83 0 00-22.81 22.81v67.49a4.5 4.5 0 004.5 4.5H398a8.24 8.24 0 1013.55 0h9.19a4.5 4.5 0 000-9zM76 277.44A45.48 45.48 0 01121.39 232h218.5a13.73 13.73 0 0113.71 13.71v63.09H89a13.08 13.08 0 01-13-13.07zm79.66-156.39H293.7v23.26H155.62zM46.19 166.1H39.7v-30.84h106.92v13.55a4.49 4.49 0 004.5 4.5H298.2a4.49 4.49 0 004.5-4.5v-13.55h106.92v30.84H46.19zM420.7 336.65h-392a4.5 4.5 0 100 9h392a4.5 4.5 0 000-9z"
        />
        <path
          d="M90.63 285.35h48.11a4.49 4.49 0 004.5-4.5v-35a4.49 4.49 0 00-4.5-4.5h-15.1a37.55 37.55 0 00-37.51 37.5v2a4.5 4.5 0 004.5 4.5zm33-35h10.6v26h-39a28.54 28.54 0 0128.41-25.96zM156.9 285.35H205a4.49 4.49 0 004.5-4.5v-35a4.49 4.49 0 00-4.5-4.5h-48.1a4.49 4.49 0 00-4.5 4.5v35a4.49 4.49 0 004.5 4.5zm4.5-35h39.1v26h-39.1zM223.16 285.35h48.11a4.5 4.5 0 004.5-4.5v-35a4.5 4.5 0 00-4.5-4.5h-48.11a4.49 4.49 0 00-4.5 4.5v35a4.49 4.49 0 004.5 4.5zm4.5-35h39.11v26h-39.11zM289.42 285.35h48.11a4.49 4.49 0 004.5-4.5v-35a4.49 4.49 0 00-4.5-4.5h-48.11a4.5 4.5 0 00-4.5 4.5v35a4.5 4.5 0 004.5 4.5zm4.5-35H333v26h-39.08zM420.7 250.39a4.5 4.5 0 000-9h-26.83a4.49 4.49 0 00-4.5 4.5v35a4.49 4.49 0 004.5 4.5h26.83a4.5 4.5 0 000-9h-22.33v-26z"
        />
      </symbol>

      <symbol id="icon-witchs-hat" viewBox="0 0 21.5 23.6">
        <polygon
          id="Path"
          class="st0"
          points="2.4,21.2 3.9,16.9 17.7,16.9 19.3,21.2 21.5,21.2 21.5,23.6 0,23.6 0,21.2 "
        />
        <polygon
          id="Path_1_"
          class="st0"
          points="15.6,10.6 17,14.6 4.7,14.6 6.2,10.6 "
        />
        <polygon
          id="Path_2_"
          class="st0"
          points="11.8,0 14.8,8.3 7,8.3 9.9,0 "
        />
      </symbol>

      <symbol id="icon-clock" viewBox="0 0 24 24">
        <path
          d="M12 24C5.34375 24 0 18.6562 0 12C0 5.39062 5.34375 0 12 0C18.6094 0 24 5.39062 24 12C24 18.6562 18.6094 24 12 24ZM10.875 12C10.875 12.375 11.0625 12.75 11.3438 12.9375L15.8438 15.9375C16.3594 16.3125 17.0625 16.1719 17.3906 15.6562C17.7656 15.1406 17.625 14.4375 17.1094 14.0625L13.125 11.4375V5.625C13.125 5.01562 12.6094 4.5 11.9531 4.5C11.3438 4.5 10.8281 5.01562 10.8281 5.625L10.875 12Z"
        />
      </symbol>

      <symbol id="icon-progress-train" viewBox="0 0 24 24">
        <path
          d="M17 0C19.4844 0 21.5 2.01562 21.5 4.5V16.5C21.5 18.75 19.8125 20.625 17.6562 20.9531L19.8125 23.1094C20.1406 23.4375 19.9062 24 19.4375 24H17.6094C17.1875 24 16.8125 23.8594 16.5312 23.5781L14 21H8L5.42188 23.5781C5.14062 23.8594 4.76562 24 4.34375 24H2.51562C2.04688 24 1.8125 23.4375 2.14062 23.1094L4.29688 20.9531C2.14062 20.625 0.5 18.75 0.5 16.5V4.5C0.5 2.01562 2.46875 0 5 0H17ZM3.5 9C3.5 9.84375 4.15625 10.5 5 10.5H17C17.7969 10.5 18.5 9.84375 18.5 9V4.5C18.5 3.70312 17.7969 3 17 3H5C4.15625 3 3.5 3.70312 3.5 4.5V9ZM11 18C12.2188 18 13.25 17.0156 13.25 15.75C13.25 14.5312 12.2188 13.5 11 13.5C9.73438 13.5 8.75 14.5312 8.75 15.75C8.75 17.0156 9.73438 18 11 18Z"
        />
      </symbol>

      <symbol id="icon-viewer" viewBox="0 0 36 36">
        <path
          d="M30 9C31.6406 9 33 10.3594 33 12V24C33 25.6875 31.6406 27 30 27H23.9531C22.7812 27 21.7031 26.3438 21.2344 25.2656L19.9219 22.3594C19.5469 21.5625 18.7969 21 18 21C17.1562 21 16.4062 21.5625 16.0312 22.3594L14.7188 25.2656C14.25 26.3438 13.1719 27 12 27H6C4.3125 27 3 25.6875 3 24V12C3 10.3594 4.3125 9 6 9H30ZM10.5 20.25C12.1406 20.25 13.5 18.8906 13.5 17.25C13.5 15.6562 12.1406 14.2969 10.5 14.2969C8.8125 14.2969 7.5 15.6094 7.5 17.25C7.5 18.9375 8.8125 20.25 10.5 20.25ZM25.5 20.25C27.1406 20.25 28.5 18.8906 28.5 17.25C28.5 15.6562 27.1406 14.2969 25.5 14.2969C23.8125 14.2969 22.5 15.6094 22.5 17.25C22.5 18.9375 23.8125 20.25 25.5 20.25Z"
        />
      </symbol>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>
