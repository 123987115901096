<template>
  <div>
    <div class="home">
      <div class="bg-image">
        <img :src="homeimg" />
      </div>

      <div class="button-wrapper">
        <router-link
          to="/taking-shape/stations"
          class="ts-button taking-shape-button"
        >
          <h1>STATIONS<br />TAKING SHAPE</h1>
          <h2><img :src="playimg" /> See our stations come to life</h2>
        </router-link>

        <button @click="gotoBenefits()" class="ts-button benefits-button">
          <h1>FUTURE<br />BENEFITS</h1>
          <h2><img :src="playimg" /> Find out what it means to you</h2>
        </button>
      </div>

      <div class="game-wrapper">
        <router-link to="/game?kiosk&embed" class="game-button">
          <img :src="gameimg" />
          <span>PLAY GAME</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import homeimg from "@/assets/images/taking-shape/home-bg.jpg";
import gameimg from "@/assets/images/taking-shape/home-game-button.png";
import playimg from "@/assets/images/taking-shape/home-play-icon.svg";

export default {
  data() {
    return {
      homeimg,
      gameimg,
      playimg
    };
  },
  methods: {
    gotoBenefits() {
      localStorage.returnpath = "/taking-shape";
      this.$router.push({ path: "/benefits/select" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

.home {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.bg-image {
  position: relative;

  img {
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    background: linear-gradient(
        200deg,
        rgba(247, 148, 42, 0.5) 0%,
        rgba(247, 148, 42, 0) 50%,
        rgba(208, 66, 206, 0) 51%,
        rgba(208, 66, 206, 0.5) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    background-position: center top;
    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
  }
}

.button-wrapper {
  position: absolute;
  top: 602px;
  left: 0;
  width: 100%;

  .ts-button {
    display: inline-block;
    width: 720px;
    padding: 64px 56px;
    h1 {
      color: #fff;
      font-size: 96px;
      font-style: italic;
      font-weight: 900;
      line-height: 0.9;
      letter-spacing: -2.4px;
    }
    h2 {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
      img {
        width: 56px;
        height: 56px;
        vertical-align: middle;
        margin-right: 0.5em;
      }
    }
  }

  .taking-shape-button {
    position: relative;
    background: #f7942a;
    z-index: 2;
    h1,
    h2 {
      text-align: right;
    }
    &::before {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 50%;
      top: 0;
      right: 0;
      background: #f7942a;
      transform-origin: right bottom;
      transform: skew(-15deg);
      z-index: -1;
    }
  }

  .benefits-button {
    position: absolute;
    top: 372px;
    right: 0;
    background: #a54399;
    z-index: 2;

    &::before {
      content: "";
      position: absolute;
      display: block;
      height: 100%;
      width: 50%;
      top: 0;
      left: 0;
      background: #a54399;
      transform-origin: left bottom;
      transform: skew(15deg);
      z-index: -1;
    }
  }
}

.game-wrapper {
  position: absolute;
  top: 1427px;
  left: 55px;
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    top: 26px;
    left: 17px;
    display: block;
    background: #f7942a;
    width: 223px;
    height: 223px;
    border-radius: 50%;
    z-index: -1;
  }

  span {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 32px;
    font-style: italic;
    font-weight: 900;
    margin-top: 48px;
  }
}
</style>
