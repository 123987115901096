<template>
  <div>
    <div class="station">
      <div class="media-screen view360-screen">
        <Three60Environment :panos="panoData" heading="360 Panorama" copy="" />
      </div>
    </div>
    <StationNav :station="stationData" />
  </div>
</template>

<script>
import playimg from "@/assets/images/taking-shape/home-play-icon.svg";
import StationNav from "@/pages/progress/StationNav";
import Three60Environment from "@/components/Three60Environment";

export default {
  props: ["slug"],
  data() {
    return {
      playimg
    };
  },
  computed: {
    stationData() {
      const { brisbane, goldCoast } = this.$store.getters.pageData("progress");
      return [...brisbane, ...goldCoast].find(item => item.slug === this.slug);
    },
    panoData() {
      return [
        {
          asset: this.stationData.view360.panorama,
          hotspots: []
        }
      ];
    }
  },
  components: {
    Three60Environment,
    StationNav
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
@import "~scss/includes/progress";

.station {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.bg-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: $deep-purple;
  z-index: -1;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .station-img {
    opacity: 0.66;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba($black, 0) 0%,
      rgba($black, 0.75) 100%
    );
  }
}

.media-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: $nav-height;
}
</style>
<style lang="scss">
@import "~scss/includes/vars";
@import "~scss/includes/progress";
.view360-screen .three60environment {
  .intro-overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.75) 100%
    );
    .intro-card {
      background: none;
      box-shadow: none;
      border: none;
      h3 {
        @include typeface-header;
        font-size: 96px;
        font-style: normal;
        font-weight: 700;
        color: $white;
        margin-bottom: 120px;
      }
      .btn {
        color: $white;
      }
    }
  }
}
</style>
