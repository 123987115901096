<template>
  <nav class="screen-navigation">
    <router-link
      class="screen-navigation-link"
      :to="`${base}/${route.path}`"
      v-for="route in routes"
      :key="route.name"
    >
      <div class="navigation-marker">
        <div class="navigation-marker-circle"></div>
        <span class="navigation-marker-label">{{
          route.meta.label || route.name
        }}</span>
      </div>
    </router-link>
  </nav>
</template>

<script>
export default {
  props: ["routes", "base"]
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";

$space_lg: 80px;
$space_sm: 40px;

$circle_lg: 52px;
$circle_sm: 26px;

.screen-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $grey;
  padding: 0 110px 0 90px;
  display: flex;
  height: 190px;
  &,
  .screen-navigation-link,
  .screen-navigation-link::before,
  .screen-navigation-link::after,
  .navigation-marker-circle,
  .navigation-marker,
  .navigation-marker-label {
    transition: all 0.6s 0s;
  }
  .navigation-marker-circle::after {
    transition: all 0.3s 0s;
  }
  .navigation-marker-circle {
    height: $circle_lg;
    width: $circle_lg;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid white;
    margin-bottom: 1em;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    &::after {
      content: "";
      height: $circle_lg - 18px;
      width: $circle_lg - 18px;
      background: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
  &-link {
    display: flex;
    align-items: center;
  }
  .screen-navigation-link {
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    display: inline-flex;
    text-align: center;
    padding-left: $space_lg;
    padding-right: $space_lg;
    letter-spacing: 0.1em;
    font-weight: bold;
    position: relative;
    .navigation-marker {
      width: $circle_lg;
      height: $circle_lg * 1.75;
      position: relative;
    }
    .navigation-marker-label {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      font-weight: bold;
      font-size: 25px;
      bottom: 0;
      white-space: nowrap;
    }
    &::before,
    &::after {
      content: "";
      height: 2px;
      width: $space_lg;
      right: 100%;
      top: 41%;
      position: absolute;
      background: white;
      transform: translate(0, -50%);
    }
    &::after {
      right: auto;
      left: 100%;
      transform: translate(0, -50%);
    }
    &:first-child {
      padding-left: 0;
      &::before {
        display: none;
      }
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
  .child & {
    bottom: 20px;
    background-color: transparentize($grey, 0.2);
    font-size: 14px;
    height: 110px;
    .screen-navigation-link {
      padding-left: $space_sm;
      padding-right: $space_sm;
      &::before,
      &::after {
        width: $space_sm;
      }
      &:first-child {
        padding-left: 0;
        &::before {
          display: none;
        }
      }
      &:last-child {
        padding-right: 0;
        &::after {
          display: none;
        }
      }
    }
    .navigation-marker {
      width: $circle_sm;
      height: $circle_sm * 1.75;
    }
    .navigation-marker-circle {
      height: $circle_sm;
      width: $circle_sm;
      &::after {
        width: $circle_sm - 9px;
        height: $circle_sm - 9px;
        background: transparent;
      }
    }

    .navigation-marker-label {
      font-size: 12px;
    }
  }
  .router-link-exact-active .navigation-marker-circle::after {
    background: $orange;
  }
}
</style>
