<template>
  <div class="home">
    <BgImageSlides :images="pageData.heroImages" />
    <div v-if="pageData.workNotice" class="home-notice">
      {{ pageData.workNotice }}
    </div>
    <div class="home-wrapper">
      <h1>Exhibition Station</h1>
      <div class="button-wrapper">
        <HomeButton v-for="btn in buttons" :key="btn.link" v-bind="btn" />
      </div>
    </div>
  </div>
</template>

<script>
import BgImageSlides from "@/components/BgImageSlides";
import HomeButton from "@/components/HomeButton";

const slug = "explore-exhibition";
export default {
  data() {
    return {
      buttons: [
        {
          link: "/exhibition/intro",
          label: "Explore Exhibition Station"
        },
        {
          link: "/stations",
          label: "Making a Station",
          color: "orange"
        }
      ]
    };
  },
  components: {
    BgImageSlides,
    HomeButton
  },
  computed: {
    pageData() {
      return this.$store.getters.pageData(slug);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
.home {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  .home-wrapper {
    z-index: 10;
  }

  .home-notice {
    position: absolute;
    top: 0;
    right: 0;
    color: $orange;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 50px 100px 16px 0;
    background: url(~@/assets/images/underline.png) left bottom repeat-x;
  }

  h1 {
    color: $white;
    text-align: center;
    margin-bottom: 40px;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    width: 1380px;

    > *:not(:last-child) {
      margin-right: 52px;
    }
  }

  .extras-wrapper {
    display: flex;
    position: fixed;
    bottom: 60px;
    right: 80px;

    > *:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>
