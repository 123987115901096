<template>
  <router-link
    v-if="homePath"
    :class="['station-home-link', { open: showStationHomeLink }]"
    :to="homePath"
    ><Icon icon="home-alt" /> Home
  </router-link>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  components: {
    Icon
  },
  computed: {
    homePath() {
      return this.$root.rootPath;
    },
    showStationHomeLink() {
      return (
        this.homePath && this.$route.path.replace(/\/$/, "") !== this.homePath
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~scss/includes/vars";
//@import "~scss/includes/util";
.station-home-link {
  position: fixed;
  top: 0;
  right: 0;
  display: inline-block;
  padding: 0.7em 1.5em 0.5em;
  font-size: 22px;
  appearance: none;
  background: $orange;
  color: $white;
  font-weight: 700;
  transition: all 0.2s;
  margin-bottom: 1rem;
  z-index: 11;
  transform: translateX(100%);
  transition: 0.3s;

  &.open {
    transform: translateX(0);
  }

  .icon {
    height: 1.25em;
    width: 1.25em;
    vertical-align: middle;
    margin-right: 0.5em;
    margin-bottom: 0.1em;
    fill: currentColor;
  }
}
</style>
