<template>
  <div id="app" :class="classes">
    <Icons />
    <router-view />
    <CRRLogo class="crr-logo" />
    <portal-target class="modals-portal" name="modals"></portal-target>
    <portal-target class="richtext-portal" name="richtext"></portal-target>
  </div>
</template>

<script>
import CRRLogo from "@/components/CRRLogo";
import Icons from "@/components/Icons";
const noCursor = false;
// const noCursor = process.env.NODE_ENV === 'production'

const HOME = "/";
const toClassName = routeName => routeName.toLowerCase().replace(/ /g, "-");

export default {
  name: "discovery-table",
  computed: {
    classes() {
      const { name, meta } = this.$route;
      const classes = [];

      if (name) classes.push(toClassName(name));
      if (meta && meta.label) classes.push(toClassName(meta.label));
      classes.push({ child: Boolean(meta && meta.child) });
      classes.push({ noCursor });

      return classes;
    }
  },
  mounted() {
    // Allow escape key to exit child experiences
    window.addEventListener("keyup", e => {
      if (e.keyCode === 27 && this.$route.path !== HOME) {
        this.$router.push(HOME);
      }
    });
  },
  components: {
    CRRLogo,
    Icons
  }
};
</script>

<style lang="scss">
@import "./scss/global.scss";
#app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.crr-logo {
  position: fixed;
  left: 20px;
  z-index: 10;
  width: 260px;
  height: auto;
  top: 20px;
  transition: all 0.8s, opacity 0.25s;

  .home & {
    width: 675px;
    top: 60px;
  }
  .introduction &,
  .tunnel-home &,
  .stations-home & {
    width: 411px;
    top: 90px;
  }
  .my-future-journey & {
    top: 70px;
  }
  .bye-bye-transit-centre &,
  .station-art & {
    opacity: 0;
  }

  .benefits-home &,
  .benefits-select &,
  .benefits-slides & {
    display: none;
  }

  // Taking Shape

  .taking-shape-home & {
    //left: 490px;
    //top: 1780px;
    //width: 536px;
    //height: auto;
  }

  .taking-shape-stations & {
    left: 48px;
    top: 132px;
    width: 336px;
    height: auto;
  }

  // Progress

  .progress-screens & {
    transition: none;
  }

  .progress-home & {
    left: calc(50% + 100px);
    top: 150px;
    width: 336px;
    height: auto;
  }

  .progress-brisbane &,
  .progress-gold-coast & {
    left: 84px;
    top: 98px;
    width: 336px;
    height: auto;
  }

  .new-upgraded-stations &,
  .station-progress &,
  .station-time-hop &,
  .station-360-panarama &,
  .station-construction & {
    left: 1564px;
    top: 1005px;
    width: 272px;
    height: auto;
  }

  .station-time-hop &,
  .station-360-panarama &,
  .station-construction & {
    display: none;
  }
}
.noCursor ::v-deep * {
  cursor: none;
}

.richtext-portal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}
</style>
